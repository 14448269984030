import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import i18next from "i18next";
import axios from "../../api/axios";
import { setCarSelectionData } from "../car/carSlice";
import { setOfferSelectionData } from "../offer/offerSlice";

const initialState = {
    language: localStorage.getItem('language') || 'en',
    isMobile: window.innerWidth < 740,
    isLoading: false,
    error: null,
    wilayas: [],
    dairas: [],
    communes: [],
    locations: [],
    isLocationsLoading: true,
};

const localeSlice = createSlice({
    name: 'locale',
    initialState,
    reducers: {
        setLanguage: (state, action) => 
        {
            state.language = action.payload;
            i18next.changeLanguage(action.payload).then(() => {
                if (action.payload === 'ar') {
                    document.body.style.direction = 'rtl';
                    document.body.style.setProperty('--normal-text-size', '18px');
                } else {
                    document.body.style.direction = 'ltr';
                    document.body.style.setProperty('--normal-text-size', '16px');
                }
            });
            localStorage.setItem('language', action.payload);
        },
        setIsMobile : (state, action) => {
            state.isMobile = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLocations.pending, (state) => {
                state.isLocationsLoading = true;
            })
            .addCase(getLocations.fulfilled, (state, action) => {
                state.locations = action.payload;
                state.isLocationsLoading = false;
            })
            .addCase(getLocations.rejected, (state, action) => {
                state.error = action.payload;
                state.isLocationsLoading = false;
            })
            .addCase(getCommon.pending, (state) => {
                state.isLocationsLoading = true;
            })
            .addCase(getCommon.fulfilled, (state) => {
                state.isLocationsLoading = false;
            })
            .addCase(getCommon.rejected, (state, action) => {
                state.isLocationsLoading = false;
                state.error = action.payload;
            });
    }
});

export const getCommon = createAsyncThunk(
    'getCommon',
    // eslint-disable-next-line no-empty-pattern
    async (_, { dispatch }) => {
        const response = await axios.get('/api/v1/common').then((res) => {
            dispatch(setCarSelectionData({
                carsTypes: res.data.cars_types,
                carSeats: res.data.car_seats_numbers,
                carsModels: res.data.cars_models,
                carsManufact: res.data.cars_manufacturers,
                carsEnergy: res.data.cars_energy_sources,
            }));
            dispatch(setOfferSelectionData({
                propertiesTypes: res.data.property_types,
                rentTypes: res.data.rents_types
            }))

            return res;
        });

        return response;
    }
);

export const getLocations = createAsyncThunk(
    'getLocations',
    async (_, { rejectWithValue }) => {
        const locationsInStorage = localStorage.getItem('searchableLocations');

        if (locationsInStorage) {
            const currentLocations = JSON.parse(locationsInStorage);

            return currentLocations;
        }

        const response = await axios.get('/api/v1/common/communes?include=wilaya,daira').then((response) => {
            console.log(response);

            const parsedData = response.data.map((commune) => {
                return {
                    id: commune.id,
                    wilaya_id: commune.wilaya.id,
                    daira_id: commune.daira_id,
                    name_en: `${commune.wilaya.name_en}, ${commune.name_en}`,
                    name_ar: `${commune.wilaya.name_ar}, ${commune.name_ar}`,
                    lat: commune.latitude,
                    lng: commune.longitude,
                };
            }
            );

            const array = parsedData.flat(1);
            localStorage.setItem('searchableLocations', JSON.stringify(array));

            return array;
            }).catch((err) => {
                if (!err?.response) {
                    return rejectWithValue('No Server Response');
                } else if (err?.response?.data) {
                    return rejectWithValue(err.response.data);
                }
        });

        return response;
    }
);

export const { setLanguage, setIsMobile } = localeSlice.actions;

export default localeSlice.reducer;
  