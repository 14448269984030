/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import HomepageLogo from '../../assets/HomepageLogo.webp';
import HomepageLogoAR from '../../assets/logo-baytup-AR.webp';
import MenuIcon from '../../icons/MenuIcon';
import NavbarLinks from './NavbarLinks';
import { Link, useNavigate } from 'react-router-dom';
import { Translator } from '../../i18n';
import { GrLanguage } from "react-icons/gr";
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from '../../state/locale/localeSlice';
import { logout } from '../../state/auth/authSlice';
import { Popover } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import Popup from '../Popup';

const Content = () => {
    const dispatch = useDispatch();
    const { first_name, last_name } = useSelector((state) => state.auth.user) || {};
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const messagesVisible = true;
    const rows = [
        {
            label: 'Messages',
            value: '/dashboard/messages',
        },
        {
            label: 'Offers',
            value: '/dashboard/offers',
        },
        {
            label: 'Cars',
            value: '/dashboard/cars',
        },
        {
            label: 'Profile',
            value: '/dashboard/welcome',
        },
        {
            label: 'Assistance',
            value: '/assistance',
        },
        {
            label: 'Log Out',
        }
    ];

    const content = (
        <div>
            { rows.map((link, index) => {
                if (link.label === 'Log Out') {
                    return (
                        <div role='button' className='hover-text-blue' key={ index } onClick={ () => dispatch(logout()) }>
                            <p><Translator text={ link.label } /></p>
                        </div>
                    );
                }

                return (
                    <Link to={ link.value } key={ index } >
                        <p><Translator text={ link.label } /></p>
                    </Link>
                );
            }) }
        </div>
    );

    return (
        <Popover overlayStyle={ { zIndex: 1100 } } content={ content } className='Avatar' title={ <Translator text='Account' /> } trigger="hover" onOpenChange={ (visible) => visible !== isDropdownVisible && setIsDropdownVisible(visible) }>
            { `${(first_name || '.').charAt(0)}${(last_name || '.').charAt(0)}` }
            { messagesVisible && (
                <svg xmlns="http://www.w3.org/2000/svg" className='BellCircle' width="5" height="5">
                    <circle cx="6" cy="6" r="6" fill="orange" />
                </svg>
            ) }
        </Popover>
    );
};

export default function Navbar() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const language = useSelector((state) => state.locale.language);
    const isMobile = useSelector((state) => state.locale.isMobile);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false)

    const languages = {
        en: { name: 'English', label: 'EN' },
        ar: { name: 'العربية', label: 'ع' }
    };

    const handleRedirect = () => {
        navigate('/login');
    };

    const toggleMobileMenu = () => {
        if (!isMobileMenuOpen) {
            document.body.classList.add('No-Scroll');
        } else {
            document.body.classList.remove('No-Scroll');
        }

        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const changeLanguage = (key) => {
        setIsDropdownVisible(false);

        if (languages[key]) {
            dispatch(setLanguage(key));
        }
    };

    // TODO: refactor this code to not repeat it
    if (isMobile) {
        return (
            <div className="Navbar">
                <Popup />
                <div onClick={ toggleMobileMenu }>
                    <MenuIcon/>
                </div>
                <div
                  className={ `SlideMenu ${
                        isMobileMenuOpen ? 'open' : 'close'
                    }` }
                >
                    <NavbarLinks isMobile SlideMenuClose={ toggleMobileMenu }/>
                </div>
                <div
                  className={ `SlideMenu-Extra ${
                        isMobileMenuOpen ? 'open' : 'close'
                    }` }
                  onClick={ toggleMobileMenu }
                />
                <Link className="Navbar-Logo" to="/">
                    <img src={ language === 'ar' ? HomepageLogoAR : HomepageLogo }/>
                </Link>
                <div className="Navbar-Tabs">
                    { /* TODO: make button for mobile */ }
                    { !isAuthenticated
                        ? (
                            <button className={ "Navbar-SignIn" } style={ { maxWidth: 30, maxHeight: 30 } } onClick={ handleRedirect }>
                                <UserOutlined />
                            </button>
                        )
                        : (
                            <Content />
                        )
                    }
                    <div className="Navbar-Languages">
                        <button
                          className="Navbar-Language"
                          onClick={ () => setIsDropdownVisible((prevState) => !prevState) }
                        >
                            <div className="Active">{ languages[language].label }</div>
                            <GrLanguage />
                        </button>
                        { isDropdownVisible && (
                            <div className="Dropdown" style={ { insetInlineStart: -30 } }>
                                { Object.entries(languages).map(([key, { name }]) => (
                                    <div
                                      style={ key === language ? { color: 'green' } : {} }
                                      key={ key }
                                      onClick={ () => changeLanguage(key) }
                                    >
                                        { name }

                                    </div>
                                )) }
                            </div>
                        ) }
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="Navbar" onMouseLeave={ () => setIsDropdownVisible(false) }>
            <Link className="Navbar-Logo" to="/">
                <img src={ language === 'ar' ? HomepageLogoAR : HomepageLogo }/>
            </Link>
            <div className="Navbar-Tabs">
                <NavbarLinks/>
                <div>
                    { !isAuthenticated
                        ? (
                            <button className={ "Navbar-SignIn" } onClick={ handleRedirect }>
                                <Translator text="Sign In" />
                            </button>
                        )
                        : (
                            <Content />
                        )
                    }
                </div>
                <div className="Navbar-Languages">
                    <button
                      className="Navbar-Language"
                      onClick={ () => setIsDropdownVisible((prevState) => !prevState) }
                    >
                        <div className="Active">{ languages[language].label }</div>
                        <GrLanguage />
                    </button>
                    { isDropdownVisible && (
                        <div className="Dropdown">
                            { Object.entries(languages).map(([key, { name }]) => (
                                <div
                                  style={ key === language ? { color: 'green' } : {} }
                                  key={ key }
                                  onClick={ () => changeLanguage(key) }
                                >
                                    { name }
                                </div>
                            )) }
                        </div>
                    ) }
                </div>
            </div>
        </div>
    );
}