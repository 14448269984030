
import '../../styles/Card.css'
import { Button, Tabs, Tag } from "antd";
import React, { useState,useEffect,useRef } from "react";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Translator } from "../../i18n";
import { EnvironmentTwoTone, PhoneOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaAngleRight } from "react-icons/fa";
import { FaAngleLeft } from "react-icons/fa";



function Description ({ description, isMap }){
    const [readMore, setReadMore] = useState(false);
 
    return (
        <div className="Description" style={ isMap ? { marginBlock: 0 } : {} }>
            { description?.length < (isMap ? 30 : 50) ? description
                : <>
                    { readMore ? description : description.slice(0, 80) }
                    { readMore ?
                        <span className="SeeLess" onClick={ () => setReadMore(false) }> <Translator text="See less" /></span>
                    :
                        <span className="SeeMore" onClick={ () => setReadMore(true) }>...<Translator text="See more" /></span>
                    }
                </> }
        </div>
    );
}

// const EquipmentsCarousel = ({ items }) => {
//     return (
//         <Swiper
//           modules={ [Navigation] }
//           slidesPerView="auto"
//           navigation={ true }
//           className="CustomCarousel"
//         >
//             { items?.map(([, value], index) => value && (
//                 <SwiperSlide key={ index } className="Item">
//                     <Tag color="#F0F0F0" className="SpecificationTag">{ value }</Tag>
//                 </SwiperSlide>
//             )) }
//         </Swiper>
//     );
// };

const HouseRulesCarousel = ({ items }) => {
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    useEffect(() => {
        const swiper = document.querySelector('.CustomCarousel').swiper;
        swiper.params.navigation.prevEl = prevRef.current;
        swiper.params.navigation.nextEl = nextRef.current;
        swiper.navigation.init();
        swiper.navigation.update();
    }, []);
    return (
        <>
            <div className="flex justify-center items-center">
                <div ref={ prevRef } className="custom-prev-button bg-slate-100 h-5 w-5 rounded-full flex items-center justify-center mr-1"><FaAngleLeft className="text-orange-500 text-center"/></div>
                <Swiper
                  modules={ [Navigation] }
                  slidesPerView="auto"
                  navigation={ { prevEl: prevRef.current, nextEl: nextRef.current } }
                  className="CustomCarousel"
        >
                    { items?.map((value, index) =>
                        <SwiperSlide key={ index } className="Item">
                            <Tag color="#F0F0F0" className="SpecificationTag">{ value }</Tag>
                        </SwiperSlide>
            ) }
                </Swiper>
                <div ref={ nextRef } className="custom-next-button bg-slate-100 h-5 w-5 rounded-full flex items-center justify-center  ml-1"><FaAngleRight className="text-orange-500 text-center"/></div>
            </div>
        </>
     
    )
};

const RoomsAndOthers = ({ items }) => {
    
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    useEffect(() => {
        const swiper = document.querySelector('.CustomCarousel').swiper;
        swiper.params.navigation.prevEl = prevRef.current;
        swiper.params.navigation.nextEl = nextRef.current;
        swiper.navigation.init();
        swiper.navigation.update();
    }, []);
    return (
        <>
            <div className="flex justify-center items-center">
                <div ref={ prevRef } className="custom-prev-button bg-slate-100 h-5 w-5 rounded-full flex items-center justify-center mr-1"><FaAngleLeft className="text-orange-500 text-center"/></div>
                <Swiper
                  modules={ [Navigation] }
                  slidesPerView="auto"
                  navigation={ { prevEl: prevRef.current, nextEl: nextRef.current } }
                  className="CustomCarousel"
        >
                    { Object.entries(items).map(([key, value], index) =>
                        <SwiperSlide key={ index } className="Item">
                            <Tag color="#F0F0F0" className="SpecificationTag">{ key }: { value }</Tag>
                        </SwiperSlide>
            ) }
                </Swiper>
                <div ref={ nextRef } className="custom-next-button bg-slate-100 h-5 w-5 rounded-full flex items-center justify-center  ml-1"><FaAngleRight className="text-orange-500 text-center"/></div>
            </div>
        </>
        
    )
};

const ExtrasCarousel = ({ items }) => {
    return (
        <Swiper
          modules={ [Pagination] }
          slidesPerView="auto"
          pagination={ true }
          className="CustomCarousel"
        >
            { items?.map(([, value], index) => (
                <SwiperSlide key={ index } className="Item">
                    <Tag color="#F0F0F0" className="SpecificationTag">{ value }</Tag>
                </SwiperSlide>
            )) }
        </Swiper>
    )
};

const ImagesCarousel = ({ items }) => {
    return (
        <Swiper
          modules={ [Pagination] }
          slidesPerView={ 1 }
          pagination={ { clickable: true } }
        >
            { Array.isArray(items) && items.map((value, index) => (
                <SwiperSlide key={ index } className="Item">
                    <img src={ value.path } />
                </SwiperSlide>
            )) }
        </Swiper>
    )
};

// TODO: remove equipments and keep only extras
// const RenderTabs = ({ equipments, rules, extras, rooms }) => {
const RenderTabs = ({ rules, extras, rooms }) => {
    const items = [];
    const { t } = useTranslation();

    // equipments && equipments !== '[]' && items.push({
    //     key: 0,
    //     label: t('Specifications'),
    //     children: (<EquipmentsCarousel items={ equipments } />)
    // });
    rooms && items.push({
        key: 1,
        label: t('Rooms and others'),
        children: (<RoomsAndOthers items={ rooms } />)
    });
    rules && rules !== '[]' && items.push({
        key: 2,
        label: t('House rules'),
        children: (<HouseRulesCarousel items={ rules } />)
    });
    extras && extras !== '[]' && items.push({
        key: 3,
        label: t('Extra'),
        children: (<ExtrasCarousel items={ extras } />)
    });

    return (
        <div className="Specifics">
            <Tabs rootClassName="Tabs" defaultActiveKey="1" items={ items } />
        </div>
    );
}

export default function Card({
    id,
    title,
    address,
    price,
    description,
    images,
    total_beds_number,
    rooms_number,
    bathrooms_number,
    equipments = '[]',
    rooms = {},
    rules = '[]',
    extras = '[]',
    isMap = false,
}) {
    const isRtl = useSelector((state) => state.locale.language) === 'ar';
    const isMobile = useSelector((state) => state.locale.isMobile);
    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate(`/offer?id=${id}`);
    }
    

    return (
        <div className="Card" data-is-map={ isMap }>
            <div className="Image" style={ !isMobile && isMap ? { width: '40%', maxHeight: 228 } : {} }>
                <ImagesCarousel items={ images }/>
            </div>
            <div className="Details" style={ isMobile ? { width: '100%' } : {} }>
                <div className="General">
                    <div className="Info" style={ isMap ? { gap: 8 } : {} }>
                        <div className="Name cursor-pointer hover:text-orange-500 active:text-orange-500" onClick={ handleRedirect }>{ title }</div>
                        <div className="Location">
                            <EnvironmentTwoTone twoToneColor="#B88923" className="LocationIcon"/>
                            { isRtl
                                ? `${address?.wilaya?.name_ar }, ${address?.commune?.name_ar}`
                                : `${address?.wilaya?.name_en }, ${address?.commune?.name_en}`
                            }
                        </div>
                        { isMap && <div className="MapPrice">{ price } <Translator text="DZD/Night"/></div> }
                        { !isMap && isMobile && <div className="Price">{ price } <Translator text="DZD/Night"/></div> }
                        <Description description={ description } isMap={ isMap } />
                        { isMap && (
                            <div className="Hotel-Benefits">
                                <div className="Hotel-Beds">
                                    <div>{ total_beds_number }</div>
                                    <div><Translator text={ total_beds_number > 1 ? 'Beds' : 'Bed' } /></div>
                                </div>
                                { bathrooms_number >= 0 && (
                                    <div className="Hotel-Baths">
                                        <div>{ bathrooms_number }</div>
                                        <div><Translator text={ bathrooms_number > 1 ? 'Baths' : 'Bath' } /></div>
                                    </div>
                                ) }
                                <div className="Hotel-Rooms">
                                    <div>{ rooms_number }</div>
                                    <div><Translator text={ rooms_number > 1 ? 'Rooms' : 'Room' } /></div>
                                </div>
                            </div>
                        ) }
                    </div>
                    { !isMap && !isMobile && <div className="Price">{ price } <Translator text="DZD/Night"/></div> }
                </div>
                { !isMap && (<>
                    <RenderTabs equipments={ equipments } rules={ rules } extras={ extras } rooms={ rooms } />
                    <div className="BookNow">
                        <Button onClick={ handleRedirect } size="large" style={ { display: 'flex', alignItems: 'center', gap: '10px', margin: 0 } }>
                            <PhoneOutlined style={ { transform: 'rotateY(180deg)' } } />
                            <Translator text="Book Now"/>
                        </Button>
                    </div>
                </>) }
            </div>
        </div>
    );
}