import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Translator } from "../../i18n";
import { setOptions } from "../../state/car/carSlice";
import fireCustomEvent from "../../util/fireCustomEvent";
import { Button } from "antd";

export const carOptionsArray = ['air conditioner', 'speed regulator', 'GPS', 'baby seat', 'roof box',
'bike rack', 'snow tires', 'chains', 'ski rack', 'audio input/ipod', 'bluetooth audio', 'hitch',
'wheelchair accessible', 'apple carplay', 'android auto', 'dash cam', 'four wheel drive'];

export default function CarOptions({ finish }) {
    const dispatch = useDispatch();
    const stateOptions = useSelector((state) => state.car.inputs.options);
    const optionsError = (useSelector((state) => state.car.inputsErrors?.options) || [])[0];
    const [data, setData] = useState(stateOptions || []);

    const handleSave = (e) => {
        e.preventDefault();

        if (data.length > 0) {
            dispatch(setOptions(data));
            finish();
        } else {
            fireCustomEvent('showNotification', 'warning', 'Please choose at least one option');
        }
    };

    const handleClick = (value) => {
        setData((prevState) => (prevState.includes(value)
            ? prevState.filter((val) => val !== value)
            : [...prevState, value])
        );
    };

    return (
        <div className="CarouselStepWrapper">
            <div className="CarouselStep">
                <div className="StepHeader">
                    <Translator text="Earn money by renting your car" />
                    <span>
                        <Translator text="Register your car" />
                    </span>
                </div>
                <div className="OfferFormWrapper">
                    <form className="OfferForm" onSubmit={ handleSave }>
                        <div className="CardsSection">
                            <span><Translator text="Options of the Car" /></span>
                            <div className="Cards Options">
                                { carOptionsArray.map((value, index)=> {
                                    const existsInData = data.includes(value);

                                    return (
                                        <div key={ index } className="AccommodationCard" onClick={ () => handleClick(value) } data-is-selected={ existsInData }>
                                            <Translator text={ value } />
                                        </div>
                                    );
                                } ) }
                            </div>
                        </div>
                        <Button
                          htmlType="submit"
                          className="SaveButton"
                        >
                            <Translator text="Next" />
                        </Button>
                        { (optionsError) &&
                        <div className="SaveButton Error">
                            <span><Translator text={ optionsError } /></span>
                        </div> }
                    </form>
                </div>
            </div>
        </div>
    );
}