/* eslint-disable react/no-unknown-property */
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input,Select } from 'antd';
import { useSelector } from 'react-redux';
import axios from '../../../api/axios';
import { Languages, USER_API } from '../../../config';

export default function ProfilePage() {
    const { t } = useTranslation();
    const { Option } = Select;
    const {
        first_name,
        last_name,
        user_name,
        language,
        email,
        email_verified_at,
        phone_number_1
    } = useSelector((state) => state.auth.user);
    const form = useRef(null);

    const handleSubmit = async (values) => {
        console.log(values);
        await axios.put(
            `${USER_API}`,
            {
                language: values.language
            },
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true,
            }
        );
    };


    return (
        <div className='FirstPage'>
            <Form className='ProfileForm' onFinish={ handleSubmit } ref={ form }>
                <Form.Item
                  label={ t('First Name') }
                  name="first_name"
                  initialValue={ first_name }
                  className="custom-label-above-input"
                >
                    <Input placeholder={ t('First Name') } style={ { width: '100%' } } />
                </Form.Item>
                <Form.Item
                  label={ t('Last Name') }
                  name="last_name"
                  initialValue={ last_name }
                  className="custom-label-above-input"
                >
                    <Input placeholder={ t('Last Name') } style={ { width: '100%' } } />
                </Form.Item>
                <Form.Item
                  label={ t('Username') }
                  name="user_name"
                  initialValue={ user_name }
                  className="custom-label-above-input"
                >
                    <Input placeholder={ t('Username') } style={ { width: '100%' } } />
                </Form.Item>
                <Form.Item
                  label={ 
                    (<div style={ { display: 'flex' } }>
                        { t('Email') }
                        { t(email_verified_at ? ' (verified)' : ' (not verified)') }
                        { email_verified_at
                            ? <svg style={ { marginInlineStart: 5, width: 20 } } viewBox="0 -3 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="#000000" stroke="#000000"><g title="Email Verified" id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"> <g id="Icon-Set-Filled" transform="translate(-518.000000, -1039.000000)" fill="#19d726"> <path title="Email Verified" d="M548.783,1040.2 C547.188,1038.57 544.603,1038.57 543.008,1040.2 L528.569,1054.92 L524.96,1051.24 C523.365,1049.62 520.779,1049.62 519.185,1051.24 C517.59,1052.87 517.59,1055.51 519.185,1057.13 L525.682,1063.76 C527.277,1065.39 529.862,1065.39 531.457,1063.76 L548.783,1046.09 C550.378,1044.46 550.378,1041.82 548.783,1040.2" id="checkmark"> </path> </g> </g> </g></svg>
                            : <svg style={ { marginInlineStart: 5, width: 20 } } viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" fill="#000000"><g id="SVGRepo_bgCarrier"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path fill="#FF473E" d="M330.443 256l136.765-136.765c14.058-14.058 14.058-36.85 0-50.908l-23.535-23.535c-14.058-14.058-36.85-14.058-50.908 0L256 181.557L119.235 44.792c-14.058-14.058-36.85-14.058-50.908 0L44.792 68.327c-14.058 14.058-14.058 36.85 0 50.908L181.557 256L44.792 392.765c-14.058 14.058-14.058 36.85 0 50.908l23.535 23.535c14.058 14.058 36.85 14.058 50.908 0L256 330.443l136.765 136.765c14.058 14.058 36.85 14.058 50.908 0l23.535-23.535c14.058-14.058 14.058-36.85 0-50.908L330.443 256z"></path></g></svg>
                        }
                    </div>)
                    }
                  name="email"
                  initialValue={ email }
                  className="custom-label-above-input"
                  rules={ [
                      { type: 'email' },
                  ] }
                >
                    <Input placeholder={ t('Email') } style={ { width: '100%' } } />
                </Form.Item>
                <Form.Item
                  label={ t('Phone Number') }
                  name="phone_number_1"
                  initialValue={ phone_number_1 }
                  className="custom-label-above-input"
                >
                    <Input placeholder={ t('Name') } style={ { width: '100%' } } />
                </Form.Item>
                <Form.Item
                  label={ t('Language') }
                  name="language"
                  initialValue={ language }
                  className="custom-label-above-input"
                >
                    <Select style={ { width: '100%' } } className='h-12'>
                        { 
                            Languages.map((elem, ind)=>
                                <Option value={ elem.key } key={ "lng_" + ind }>{ t(elem.val) }</Option>)
                        }
                        
                    </Select>
                </Form.Item>
            </Form>
            <Button type="primary" htmlType="button" onClick={ () => { form.current.submit(); } } style={ { width: 120, marginTop: 20, } }>
                { t('Save') }
            </Button>
        </div>
    );
}