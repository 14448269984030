import React, { useContext, useRef, useState } from 'react';
import { Button as AButton, Col, InputNumber, Row, Select, Slider } from 'antd';
import { AppstoreAddOutlined, DatabaseOutlined, EnvironmentOutlined, OrderedListOutlined, } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { OfferFiltersContext } from '../../context/OfferFiltersProvider';
import { offerOptionsEN } from '../OfferForm/OfferExtras';

export default function SearchFilters({ filters, onHandleFiltersChange, setShowModal }) {
    const { t } = useTranslation();
    const {
        parameters,
        handleInputChange,
        handleSelect,
        clearFilters,
        searchOffers
    } = useContext(OfferFiltersContext);

    const [showMore, setShowMore] = useState(null);
    const select = useRef(null);
    const isRtl = useSelector((state) => state.locale.language) === 'ar';
    const locations = useSelector((state) => state.locale.locations);
    const isMobile = useSelector((state) => state.locale.isMobile);
    const propertiesTypes = (useSelector((state) => state.offer.propertiesTypes) || []).map(({ id, name_ar, name_en }) => ({ label: isRtl ? name_ar : name_en, value: id }));
    const rentTypes = useSelector((state) => state.offer.rentTypes);

    const options = offerOptionsEN.map(
        (item) => ({
            label: item,
            value: item,
        })
    );
    const marks = {
        0: `0 ${isRtl ? 'كم' : 'Km'}`,
        100: {
            style: {
                color: '#f50',
            },
            label: <strong>100 { isRtl ? 'كم' : 'Km' }</strong>,
        },
    };
    const orderByFilters = [
        { label: t('Newest'), value: '-newest' },
        { label: t('Oldest'), value: 'newest' },
        { label: t('High price first'), value: '-price' },
        { label: t('Low price first'), value: 'price' },
        { label: t('Closest'), value: 'closest' },
        { label: t('Farthest'), value: '-closest' },
    ];
    const [selectedAmenities, setSelectedAmenities] = useState(parameters.amenities ?? []);

    const handleChange = (value) => {
      setSelectedAmenities(value);
      handleInputChange('amenities', value);
    };

    return (
        <>
            <div style={ { display: 'flex', justifyContent: 'space-between', flexDirection: 'column', flex: 1 } }>
                <Row gutter={ 16 } justify="center" className="FirstFilters">
                    <Col className="gutter-row" xs={ 22 } sm={ 11 } md={ 11 } lg={ 8 }>
                        <Select
                          placeholder={ t("Yearly") }
                          size="medium"
                          style={ { width: '100%' } }
                          allowClear
                          value={ parameters.yearly ?? null }
                          onChange={ (value) => handleInputChange('yearly', value) }
                          options={ rentTypes }
                        />
                    </Col>
                    { isMobile && (
                        <Col className="gutter-row" xs={ 22 } sm={ 22 } md={ 22 } lg={ 22 } style={ { marginBlockStart: 5 } }>
                            <h2>{ t("Property location") }</h2>
                        </Col>
                    ) }
                    <Col className="gutter-row" xs={ 22 } sm={ 11 } md={ 11 } lg={ 8 }>
                        <Select
                          id="city"
                          ref={ select }
                          showSearch
                          style={ { width: '100%' } }
                          placeholder={ t("Select a City") }
                          optionFilterProp="children"
                          value={ parameters.address?.index ?? null }
                          onChange={ handleSelect }
                          onSelect={ () => select?.current?.blur() }
                          filterOption={ (input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          allowClear
                          onClear={ () => handleInputChange('address', null) }
                        >
                            { locations.map(({ name_ar, name_en }, index) => (
                                <Select.Option key={ index } value={ index }>
                                    { isRtl ? name_ar : name_en?.replace('\\&amp;#039;', "'") }
                                </Select.Option>
                            )) }
                        </Select>
                    </Col>
                    <Col className="gutter-row" xs={ 20 } sm={ 11 } md={ 11 } lg={ 8 }>
                        <Slider
                          className='Antd-Slider'
                          styles= { {
                                track: {
                                    backgroundColor: 'var(--baytup-orange)'
                                }
                            } }
                          style={ { width: '95%', marginBlock: 0, height: '32px' } }
                          marks={ marks }
                          defaultValue={ parameters.range ?? 50 }
                          onAfterChange={ (value) => handleInputChange('range', value) }
                          reverse={ isRtl }
                          tooltip={ { open: true, formatter: (value) => `${value} ${isRtl ? 'كم' : 'Km'}`, builtinPlacements: { top: { points: ['b', 't'], offset: [0, -10] } } } }
                          step={ 5 }
                        />
                    </Col>
                    { isMobile && (
                        <Col className="gutter-row" xs={ 22 } sm={ 22 } md={ 22 } lg={ 22 }>
                            <h2>{ t("Property Type") }</h2>
                        </Col>
                    ) }
                    <Col className="gutter-row" xs={ 22 } sm={ 11 } md={ 11 } lg={ 6 }>
                        <Select
                          placeholder={ t("Type") }
                          size="medium"
                          value={ parameters.rent_type ?? null }
                          onChange={ (value) => handleInputChange('rent_type', value) }
                          style={ { width: '100%' } }
                          allowClear
                          options={ propertiesTypes }
                        />
                    </Col>
                    { isMobile && (
                        <Col className="gutter-row" xs={ 22 } sm={ 22 } md={ 22 } lg={ 22 }>
                            <h2>{ t("Prices") }</h2>
                        </Col>
                    ) }
                    <Col className="gutter-row" xs={ 22 } sm={ 11 } md={ 11 } lg={ 6 }>
                        <InputNumber
                          placeholder={ t("Min Price") }
                          type="number"
                          min={ 0 }
                          value={ parameters.min_price ?? null }
                          style={ { width: '100%' } }
                          onChange={ (value) => handleInputChange('min_price', value) }
                          addonAfter={ t("DZD") }
                        />
                    </Col>
                    <Col className="gutter-row" xs={ 22 } sm={ 11 } md={ 11 } lg={ 6 }>
                        <InputNumber
                          placeholder={ t("Max Price") }
                          type="number"
                          min={ 0 }
                          style={ { width: '100%' } }
                          value={ parameters.max_price ?? null }
                          onChange={ (value) => handleInputChange('max_price', value) }
                          onBlur={ () => handleInputChange('max_price', parameters.max_price >= parameters.min_price ? parameters.max_price : null) }
                          addonAfter={ t("DZD") }
                        />
                    </Col>
                    { !isMobile && (
                        <Col className="gutter-row" xs={ 22 } sm={ 16 } md={ 11 } lg={ 6 }>
                            <button
                              type="submit"
                              className="Search-Buttons-Submit"
                              onClick={ searchOffers }
                            >
                                { t("Filter") }
                            </button>
                        </Col>
                    ) }
                    { isMobile && (
                        <Col className="gutter-row" xs={ 22 } sm={ 22 } md={ 22 } lg={ 22 }>
                            <h2>{ t("Guests and Rooms") }</h2>
                        </Col>
                    ) }
                    { showMore !== null && (<>
                        <Col
                          className={ `gutter-row ${!isMobile ? 'ShowMore' : ''}${showMore ? ' open' : ' close' }` }
                          style={ { display: 'flex', gap: 5 } }
                          xs={ 22 }
                          sm={ 16 }
                          md={ 11 }
                          lg={ 7 }
                        >
                            <InputNumber
                              placeholder={ t("Guests") }
                              type="number"
                              min={ 1 }
                              value={ parameters.guests ?? null }
                              onChange={ (value) => handleInputChange('guests', value) }
                              style={ { flex: 1 } }
                            />
                            <InputNumber
                              placeholder={ t("Rooms") }
                              type="number"
                              min={ 1 }
                              value={ parameters.rooms ?? null }
                              onChange={ (value) => handleInputChange('rooms', value) }
                              style={ { flex: 1 } }
                            />
                        </Col>
                        { isMobile && (
                            <Col className="gutter-row" xs={ 22 } sm={ 22 } md={ 22 } lg={ 22 }>
                                <h2>{ t("Amenities") }</h2>
                            </Col>
                        ) }
                        <Col className={ `gutter-row ${!isMobile ? 'ShowMore' : ''}${showMore ? ' open' : ' close' }` } xs={ 22 } sm={ 18 } md={ 18 } lg={ 11 }>
                            <Select
                              mode="multiple"
                              allowClear
                              placeholder={ t("Please select Amenities") }
                              maxTagCount={ 3 }
                              maxTagPlaceholder={ (omittedValues) => `+${omittedValues.length}` }
                              value={ selectedAmenities }
                              onChange={ handleChange }
                              options={ options }
                              style={ { height: 'fit-content', width: '100%' } }
                                />
                        </Col>
                        { !isMobile && (
                            <Col className={ `gutter-row ${!isMobile ? 'ShowMore' : ''}${showMore ? ' open' : ' close' }` } xs={ 22 } sm={ 11 } md={ 11 } lg={ 6 } >
                                <button
                                  type="submit"
                                  className="Search-Buttons-Submit"
                                  onClick={ clearFilters }
                                >
                                    { t("Clear filters") }
                                </button>
                            </Col>
                        ) }
                    </>) }
                    { !isMobile && (
                        <Col className="gutter-row" xs={ 24 } sm={ 24 } md={ 24 } lg={ 24 } onClick={ () => setShowMore(!showMore) } style={ { display: 'flex', alignItems: 'center', flexDirection: 'column', cursor: 'pointer', userSelect: 'none', marginBlockEnd: 0 } }>
                            <div className='HorizontalDivider' />
                            <svg className={ `ShowMore-Button${ showMore || isMobile ? ' open' : ' close' }` } fill="#000000" height="20px" width="20px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" xmlSpace="preserve">
                                <path id="XMLID_197_" d="M304.394,139.394l-139.39,139.393L25.607,139.393c-5.857-5.857-15.355-5.858-21.213,0.001 c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393s7.794-1.581,10.606-4.394l149.996-150 c5.858-5.858,5.858-15.355,0-21.213C319.749,133.536,310.251,133.535,304.394,139.394z" />
                                <path id="XMLID_221_" d="M154.398,190.607c2.813,2.813,6.628,4.393,10.606,4.393s7.794-1.581,10.606-4.394l149.996-150 c5.858-5.858,5.858-15.355,0-21.213c-5.857-5.858-15.355-5.858-21.213,0l-139.39,139.393L25.607,19.393 c-5.857-5.858-15.355-5.858-21.213,0c-5.858,5.858-5.858,15.355,0,21.213L154.398,190.607z" />
                            </svg>
                        </Col>
                    ) }
                </Row>
                { isMobile && (<Row gutter={ 16 } justify="center" className="FirstFilters" style={ { marginTop: 20 } }>
                    <Col className="gutter-row" xs={ 22 } sm={ 16 } md={ 11 } lg={ 6 }>
                        <button
                          type="submit"
                          className="Search-Buttons-Submit"
                          onClick={ () => { setShowModal(false); searchOffers(); } }
                        >
                            { t("Filter") }
                        </button>
                    </Col>
                    <Col className={ `gutter-row ${ showMore || isMobile ? ' open' : ' close' }` } xs={ 22 } sm={ 11 } md={ 11 } lg={ 6 } >
                        <button
                          type="submit"
                          className="Search-Buttons-Submit"
                          onClick={ clearFilters }
                        >
                            { t("Clear filters") }
                        </button>
                    </Col>
                </Row>) }
            </div>
            { !isMobile && (
                <div className="Filters">
                    <div className="DisplayFitlers">
                        <Select
                          size="large"
                          value={ parameters.sort ?? "-newest" }
                          style={ { width: 145 } }
                          onChange={ (value) => handleInputChange('sort', value) }
                          options={ orderByFilters }
                        />
                        <AButton
                          type={ filters.grid ? 'primary' : 'default' }
                          icon={ <DatabaseOutlined/> }
                          size="large"
                          onClick={ () => onHandleFiltersChange('grid', false) }
                            />
                        <AButton
                          type={ !filters.grid ? 'primary' : 'default' }
                          icon={ <AppstoreAddOutlined/> }
                          size="large"
                          onClick={ () => onHandleFiltersChange('grid', true) }
                            />
                        <AButton
                          type={ filters.map ? 'primary' : 'default' }
                          icon={ <OrderedListOutlined/> }
                          size="large"
                          onClick={ () => onHandleFiltersChange('map', false) }
                            />
                        <AButton
                          type={ !filters.map ? 'primary' : 'default' }
                          icon={ <EnvironmentOutlined/> }
                          size="large"
                          onClick={ () => onHandleFiltersChange('map', true) }
                            />
                    </div>
                </div>
            ) }
        </>
    );
}