import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../api/axios";
import { OFFER_CREATION_REQUEST, PHONE_VERIFICATION_REQUEST, USER_API, USER_FAVORITES_REQUEST, VERIFICATION_CODE_REQUEST } from "../../config";
import { LOGIN_URL } from "../../components/Authentication/LoginForm";

const initialState = {
    user: {},
    favorites: [],
    messages: null,
    isLoading: true,
    error: null,
    isAuthenticated: false,
    codeStatus: null,
    verificationStatus: null,
    uploadStatus: null,
    deleteStatus: null,
    favoritesLoading: false,
    toggleFavoriteStatus: null,
    csrfStatus: null
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginSuccess: (state, action) => {
            state.user = action.payload;
            state.isLoading = false;
            state.error = null;
            state.isAuthenticated = true;
        },
        loginFailure: (state, action) => {
            state.user = action.payload;
            state.isLoading = false;
            state.error = action.payload;
            state.isAuthenticated = false;
        },
        resetErrors: (state) => {
            state.error = null;
        },
        resetRequests: (state) => {
            state.codeStatus = null;
            state.verificationStatus = null;
            state.uploadStatus = null;
            state.deleteStatus = null;
            state.favoritesLoading = false;
            state.toggleFavoriteStatus = null;
        }
    },
    extraReducers: (builder) => {
        builder
            // getFavorites cases
            .addCase(getFavorites.pending, (state) => {
                state.favoritesLoading = true;
            })
            .addCase(getFavorites.fulfilled, (state, action) => {
                state.favorites = action.payload.data.data;
                state.favoritesLoading = false;
            })
            .addCase(getFavorites.rejected, (state) => {
                state.favorites = [];
                state.favoritesLoading = false;
            })
            // toggleFavorite cases
            .addCase(toggleFavorite.pending, (state) => {
                state.toggleFavoriteStatus = 'pending';
            })
            .addCase(toggleFavorite.fulfilled, (state) => {
                state.toggleFavoriteStatus = 'success';
            })
            .addCase(toggleFavorite.rejected, (state) => {
                state.toggleFavoriteStatus = 'error';
            })
            // requestCode cases
            .addCase(requestCode.pending, (state) => {
                state.codeStatus = 'pending';
                state.error = null;
            })
            .addCase(requestCode.fulfilled, (state) => {
                state.codeStatus = 'success';
            })
            .addCase(requestCode.rejected, (state, action) => {
                state.codeStatus = 'error';
                state.error = action.error;
            })
            // verifyPhone cases
            .addCase(verifyPhone.pending, (state) => {
                state.verificationStatus = 'pending';
                state.isLoading = true;
                state.error = null;
            })
            .addCase(verifyPhone.fulfilled, (state) => {
                state.verificationStatus = 'success';
                state.isLoading = false;
            })
            .addCase(verifyPhone.rejected, (state, action) => {
                state.verificationStatus = 'error';
                state.isLoading = false;
                state.error = action.error;
            })
            // uploadIdentificationFiles cases
            .addCase(uploadIdentificationFiles.pending, (state) => {
                state.deleteStatus = null;
                state.uploadStatus = 'pending';
                state.error = null;
            })
            .addCase(uploadIdentificationFiles.fulfilled, (state) => {
                state.uploadStatus = 'success';
            })
            .addCase(uploadIdentificationFiles.rejected, (state, action) => {
                state.uploadStatus = 'error';
                state.error = action.payload;
            })
            // deleteIdentificationFiles cases
            .addCase(deleteIdentificationFiles.pending, (state) => {
                state.uploadStatus = null;
                state.deleteStatus = 'pending';
                state.error = null;
            })
            .addCase(deleteIdentificationFiles.fulfilled, (state) => {
                state.deleteStatus = 'success';
            })
            .addCase(deleteIdentificationFiles.rejected, (state, action) => {
                state.deleteStatus = 'error';
                state.error = action.payload;
            })
            .addCase(loginAsync.pending, (state) => {
                state.error = null;
            })
            .addCase(loginAsync.fulfilled, (state, action) => {
                state.user = action.payload.payload;
                state.isAuthenticated = true;
            })
            .addCase(loginAsync.rejected, (state, action) => {
                state.error = action.error;
            })
            .addCase(checkUser.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(checkUser.fulfilled, (state, action) => {
                state.user = action.payload.data;
                state.isAuthenticated = true;
                state.isLoading = false;
            })
            .addCase(checkUser.rejected, (state, action) => {
                state.user = {};
                state.isAuthenticated = false;
                state.error = action.payload?.data?.message ?? action.payload?.data;
                state.isLoading = false;
            })
            .addCase(checkUserNoLoading.fulfilled, (state, action) => {
                state.user = action.payload.data;
                state.isAuthenticated = true;
                state.error = null;
            })
            .addCase(checkUserNoLoading.rejected, (state, action) => {
                state.user = {};
                state.error = action.payload?.data?.message ?? action.payload?.data;
                state.isAuthenticated = false;
            })
            .addCase(getCSRF.fulfilled, (state) => {
                state.csrfStatus = 'fulfilled';
            })
            .addCase(getCSRF.rejected, (state) => {
                state.csrfStatus = 'rejected';
            })
            .addCase(getMessages.fulfilled, (state, action) => {
                state.messages = action.payload.data;
            })
            .addCase(getMessages.rejected, (state) => {
                state.messages = null;
            })
            .addCase(logout.fulfilled, (state) => {
                state.user = {};
                state.isLoading = false;
                state.isAuthenticated = false;
            });
    }
});

export const getFavorites = createAsyncThunk(
    'getFavorites',
    async () => {
        const response = await axios.get(
            `${USER_FAVORITES_REQUEST}`,
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true,
            }
        );

        return response;
    }
);

export const toggleFavorite = createAsyncThunk(
    'toggleFavorite',
    async ({ id }, { dispatch }) => {
        const response = await axios.put(
            `${OFFER_CREATION_REQUEST}/${id}/favorite`,
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true,
            }
        ).then((res) => {
            dispatch(getFavorites());
            return res;
        });

        return response;
    }
);

export const requestCode = createAsyncThunk(
    'requestCode',
    async ({ phone }) => {
        const response = await axios.post(
            VERIFICATION_CODE_REQUEST,
            { phone: phone?.replaceAll(' ', '') },
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true,
            }
        );

        return response;
    }
);

export const verifyPhone = createAsyncThunk(
    'verifyPhone',
    async ({ code }) => {
        const response = await axios.post(
            `${PHONE_VERIFICATION_REQUEST}`,
            { code },
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true,
            }
        );

        return response;
    }
);

export const uploadIdentificationFiles = createAsyncThunk(
    'uploadIdentificationFiles',
    async ({ files }, { rejectWithValue, dispatch }) => {
        const response = await axios.post(
            `api/v1/owner/properties/identity_documents`,
            {
                ...files
            },
            {
                headers: { 'Content-Type': 'multipart/form-data' },
                withCredentials: true,
            }
        ).catch((err) => {
            if (!err?.response) {
                return rejectWithValue('No Server Response');
            }

            return rejectWithValue(err.response?.data?.message);
        });
        dispatch(checkUserNoLoading());

        return response;
    }
);

export const deleteIdentificationFiles = createAsyncThunk(
    'deleteIdentificationFiles',
    async (_, { rejectWithValue }) => {
        const response = await axios.delete(
            `api/v1/owner/properties/identity_documents`,
            {
                withCredentials: true,
            }
        ).catch((err) => {
            if (!err?.response) {
                return rejectWithValue('No Server Response');
            }

            return rejectWithValue(err.response?.data?.message);
        });

        return response;
    }
);

export const getMessages = createAsyncThunk(
    'getMessages',
    async (_, { rejectWithValue }) => {
        const response = await axios.get(
            '/api/v1/client/reservation_question',
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true,
            }
        ).catch((err) => {
            if (!err?.response) {
                return rejectWithValue('No Server Response');
            }

            return rejectWithValue(err.response?.data?.message);
        });

        return response.data;
    }
);

export const loginAsync = createAsyncThunk(
    'loginAsync',
    async ({ email, password }) => {
        await axios.post(
            `${LOGIN_URL}`,
            {
                email,
                password
            },
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true,
            }
        );
        const { data } = await axios.get(USER_API);

        return data;
    }
);

export const checkUser = createAsyncThunk(
    'checkUser',
    async (_, { rejectWithValue, dispatch }) => {
        const response = await axios.get(USER_API).catch((err) => {
            if (!err?.response) {
                return rejectWithValue('No Server Response');
            }

            if (err?.response?.data?.message === 'Your email address is not verified.') {
                // added this because unverified users are considered logged in users with no data
                // they return unauthenticated when requesting their data so we have to log them out
                // because they stay logged in and you can't login with another user
                dispatch(logout());
            }

            return rejectWithValue(err.response);
        });

        return response;
    }
);

export const checkUserNoLoading = createAsyncThunk(
    'checkUserNoLoading',
    async (_, { rejectWithValue, dispatch }) => {
        const response = await axios.get(USER_API).catch((err) => {
            if (!err?.response) {
                return rejectWithValue('No Server Response');
            }

            if (err?.response?.data?.message === 'Your email address is not verified.') {
                // added this because unverified users are considered logged in users with no data
                // they return unauthenticated when requesting their data so we have to log them out
                // because they stay logged in and you can't login with another user
                dispatch(logout());
            }

            return rejectWithValue(err.response);
        });

        return response;
    }
);

export const logout = createAsyncThunk(
    'logout',
    async () => {
        const response = await axios.post('/logout');

        return response;
    }
);

export const getCSRF = createAsyncThunk(
    'getCSRF',
    async () => {
        const response = await axios.get('/sanctum/csrf-cookie');

        return response;
    }
);

export const {
    loginSuccess,
    loginFailure,
    resetErrors,
    resetRequests
 } = authSlice.actions;

export default authSlice.reducer;
  