import React, { useEffect, useMemo, useState } from 'react';
import OffersTable from './OffersTable';
import { useDispatch, useSelector } from 'react-redux';
import { getOwnerOffers } from '../../../state/offer/offerSlice';
import { Translator } from '../../../i18n';
import dayjs from 'dayjs';

export default function OffersPage() {
    const dispatch = useDispatch();
    const [searchFilters, setSearchFilters] = useState({
        text: '',
        status: [],
        rooms: [],
        extras: [],
    });
    const deleteOfferStatus = useSelector((state) => state.offer.deleteOfferStatus);
    const updateOfferStatus = useSelector((state) => state.offer.updateOfferStatus);
    const stateOffers = useSelector((state) => state.offer.userOffers).map((offer, index) => {
        return {
            key: offer.id,
            offer: { index, image: offer.images.length ? offer.images[0].path : null, name: offer.title, type: { name_en: offer.type?.name_en, name_ar: offer.type?.name_ar } },
            status: { name: offer.status?.name, id: offer.id, index },
            visibility: offer.is_visible,
            type: offer.type?.name_en,
            address: { name_en: offer.address?.commune?.name_en, name_ar: offer.address?.commune?.name_ar },
            rooms: offer.rooms_number,
            beds: offer.total_beds_number,
            bathrooms: offer.bathrooms_number,
            tags: [(offer.extras || []), ...(offer.equipments || [])],
            updated_at: dayjs(offer.updated_at).format('DD-MM-YYYY'),
        };
    });
    const offers = useMemo(() => 
        stateOffers
        ? stateOffers.filter((item) =>
            (searchFilters.text === '' || item.offer.name?.includes(searchFilters.text?.toLowerCase())) &&
            (searchFilters.status.length === 0 || searchFilters.status?.some(element => item.status.name === element)) &&
            (!searchFilters.rooms || Number(item.rooms) >= Number(searchFilters.rooms)) &&
            (searchFilters.extras.length === 0 || searchFilters.extras?.some(element => item.tags?.includes(element))))
        : stateOffers
    ,[stateOffers, searchFilters]);

    useEffect(() => {
        deleteOfferStatus !== 'pending' && updateOfferStatus !== 'pending' && dispatch(getOwnerOffers());
    }, [deleteOfferStatus, updateOfferStatus]);

    return (
        <div className='FirstPage'>
            <h1><Translator text="  " /></h1>
            <h2>{ offers.length } <Translator text="offers" /></h2>
            <OffersTable offers={ offers } isEquipmentSelected={ searchFilters.extras.length > 0 } setSearchFilters={ setSearchFilters } />
        </div>
    );
}