import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import fireCustomEvent from '../util/fireCustomEvent';
import Loading from '../components/Loading';

// for any authenticated user
export default function ProtectedRoute() {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const first_name = useSelector((state) => state.auth.user?.first_name);
    const isLoading = useSelector((state) => state.auth.isLoading);

    if (isLoading) {
        return <Loading />;
    }

    if (!isAuthenticated) {
        return <Navigate to='/login' />;
    }

    if (!first_name) {
        return <Navigate to='/complete_registration' />;
    }

    return (
        <Outlet />
    );
}

export function AdminRoute() {
    const type = useSelector((state) => state.auth.user?.type);

    if (type !== 3) {
        fireCustomEvent('showNotification', 'warning', 'You don\'t have access to those pages.');
        return <Navigate to='/' />;
    }

    return (
        <Outlet />
    );
}

export function OwnerRoute() {
    const type = useSelector((state) => state.auth.user?.type);

    if (type !== 2) {
        fireCustomEvent('showNotification', 'warning', 'You don\'t have access to those pages.');
        return <Navigate to='/' />;
    }

    return (
        <Outlet />
    );
}

export function CompleteRegistrationRoute() {
    const isLoading = useSelector((state) => state.auth.isLoading);
    const first_name = useSelector((state) => state.auth.user?.first_name);

    if (isLoading) {
        return <Loading />;
    }

    if (first_name) {
        fireCustomEvent('showNotification', 'success', 'You completed your registration.');
        return <Navigate to='/' />;
    }

    return (
        <Outlet />
    );
}