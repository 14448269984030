/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Space, Table } from 'antd';
import { HourglassFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getFavorites, resetRequests, toggleFavorite } from '../state/auth/authSlice';
import dayjs from 'dayjs';
import { Translator } from '../i18n';

const iconsMap = {
    draft: <HourglassFilled />,
};

function OffersTable({ offers, setSearchFilters, isLoading }) {
    const favoritesLoading = useSelector((state) => state.auth.favoritesLoading);
    const toggleFavoriteStatus = useSelector((state) => state.auth.toggleFavoriteStatus);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isRtl = useSelector((state) => state.locale.language) === 'ar';

    const columns = [
        {
            title: t('ANNOUNCEMENT'),
            dataIndex: 'offer',
            key: 'offer',
            render: (offer) => <div className='Name' onClick={ () => navigate(`/offer?id=${ offer.key }`) }>{ offer.image && <img src={ offer.image } alt="Offer" style={ { width: '50px' } } /> }<a>{ offer.name }</a></div>,
            sorter: (a, b) => a.offer.name.localeCompare(b.offer.name),
            sortDirections: ['ascend', 'descend'],
            ellipsis: true,
        },
        {
            title: t('ACTION'),
            dataIndex: 'key',
            key: 'key',
            render: (key) => (
                <Space size="middle">
                    <Button className='Delete' onClick={ () => handleDelete(key) }>{ t("Remove") }</Button>
                </Space>
            ),
        },
        {
            title: t('ROOMS'),
            dataIndex: 'rooms',
            key: 'rooms',
            sorter: (a, b) => a.rooms < b.rooms,
            sortDirections: ['ascend', 'descend'],
            ellipsis: true,
            width: '13%',
        },
        {
            title: t('BEDS'),
            dataIndex: 'beds',
            key: 'beds',
            sorter: (a, b) => a.beds < b.beds,
            sortDirections: ['ascend', 'descend'],
            ellipsis: true,
            width: '9%',
        },
        {
            title: t('BATHROOMS'),
            dataIndex: 'bathrooms',
            key: 'bathrooms',
            sorter: (a, b) => a.bathrooms < b.bathrooms,
            sortDirections: ['ascend', 'descend'],
            ellipsis: true,
            width: '17%',
        },
        {
            title: t('ADDRESSE'),
            dataIndex: 'address',
            key: 'address',
            render: (address) => isRtl ? address.name_ar : address.name_en,
            sorter: (a, b) => a.address.localeCompare(b.address),
            sortDirections: ['ascend', 'descend'],
            ellipsis: true,
        },
        {
            title: t('UPDATED AT'),
            dataIndex: 'updated_at',
            key: 'updated_at',
            sorter: (a, b) => a.address.localeCompare(b.address),
            sortDirections: ['ascend', 'descend'],
            ellipsis: true,
        },
    ];

    const handleDelete = (key) => {
        dispatch(toggleFavorite({ id: key }));
        dispatch(resetRequests());
    };

    useEffect(() => {
        return () => {
            dispatch(resetRequests());
        }
    }, [dispatch]);

    return (
        <div className='Table'>
            { /* <Input
              placeholder={ t("Search") }
              onChange={ (e) => setSearchFilters((prev) => ({ ...prev, text: e.target.value || '' })) }
              allowClear
              style={ {
                marginBottom: 8,
                marginInlineEnd: 8,
                maxWidth: 200
              } }
              prefix={ <SearchOutlined /> }
            />
            <Select
              mode='multiple'
              placeholder={ t("Choose Rooms") }
              style={ {
                marginBottom: 8,
                marginInlineEnd: 8,
                minWidth: 150
              } }
              onChange={ (value) => setSearchFilters((prev) => ({ ...prev, rooms: value })) }
            >
                <Select.Option value="1">1</Select.Option>
                <Select.Option value="2">2</Select.Option>
                <Select.Option value="3">3</Select.Option>
                <Select.Option value="4">4</Select.Option>
                <Select.Option value="5">5</Select.Option>
                <Select.Option value="6">6</Select.Option>
                <Select.Option value="7">7</Select.Option>
                <Select.Option value="8">8</Select.Option>
                <Select.Option value="9">9</Select.Option>
            </Select>
            <Select
              mode='multiple'
              placeholder={ t("Equipments") }
              maxTagCount={ 0 }
              maxTagPlaceholder={ () => 'Selected' }
              style={ {
                marginBottom: 8,
                marginInlineEnd: 8,
                minWidth: 150
              } }
              onChange={ (value) => setSearchFilters((prev) => ({ ...prev, extras: value })) }
            >
                { offerOptionsEN.map((item) => (
                    <Select.Option key={ item } value={ item }>
                        <Translator text={ item } />
                    </Select.Option>
                )) }
            </Select>
            <Select
              mode='multiple'
              placeholder={ t("Status") }
              style={ {
                marginBottom: 8,
                marginInlineEnd: 8,
                minWidth: 150
              } }
              onChange={ (value) => setSearchFilters((prev) => ({ ...prev, status: value })) }
            >
                <Select.Option value="draft">{ t("draft") }</Select.Option>
                <Select.Option value="approved">{ t("approved") }</Select.Option>
            </Select> */ }
            <Table
              className={ isRtl ? 'ShadowFix' : '' }
              columns={ columns }
              dataSource={ offers }
              pagination={ { pageSize: 20 } }
              scroll={ { x: 'max-content' } }
              loading={ toggleFavoriteStatus === 'pending' || favoritesLoading }
            />
        </div>
    );
}


export default function SavedPage() {
    const [searchFilters, setSearchFilters] = useState({
        text: '',
        status: [],
        rooms: [],
        extras: [],
    });
    const stateOffers = useSelector((state) => state.auth.favorites).map((offer) => {
        return {
            key: offer.id,
            offer: { key: offer.id, image: offer.images?.length ? offer.images[0].path : null, name: offer.title },
            type: offer.type?.name_en,
            address: { name_en: offer.address?.commune?.name_en, name_ar: offer.address?.commune?.name_ar },
            rooms: offer.rooms_number,
            beds: offer.total_beds_number,
            bathrooms: offer.bathrooms_number,
            tags: [...(offer.extras || []), ...(offer.equipments || [])],
            updated_at: dayjs(offer.updated_at).format('DD-MM-YYYY')
        };
    });
    const offers = useMemo(() => 
        stateOffers ? stateOffers.filter((item) =>
            item.offer.name.includes(searchFilters.text) &&
            (searchFilters.rooms?.length === 0 || searchFilters.rooms.some(element => item.rooms === Number(element) || item.beds === Number(element) || item.bathrooms === Number(element))) &&
            (searchFilters.extras?.length === 0 || searchFilters.extras.some(element => item.tags?.includes(element)))
        ) : stateOffers
    , [stateOffers, searchFilters]);

    return (
        <>
            <h1 style={ { fontSize: '2.25rem', fontWeight: 600 } }><Translator text="Saved houses list" />:</h1>
            <OffersTable offers={ offers } setSearchFilters={ setSearchFilters } />
        </>
    );
}
