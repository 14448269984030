import { CopyOutlined, DeleteOutlined, EditOutlined, SendOutlined } from '@ant-design/icons';
import { message as antdMessage } from 'antd';
import { Input } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import axios from '../../../../api/axios';
import { useSelector } from 'react-redux';

export default function MessagesList({ id, data }) {
    const containerRef = useRef(null);
    const user = useSelector((state) => state.auth.user);
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState(data || []);
    const dateOptions = { 
        day: '2-digit', 
        month: 'short', 
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    };

    const handleKeyPress = (e) => {
        e.preventDefault();

        if (e.key === 'Enter' && message) {
            handleMessage(message);
            setMessages(prev => [...prev, { name: user?.first_name + ' ' + user?.last_name, message, icon: null, date: (new Date()).toLocaleString('en-UK', dateOptions).replaceAll(' ', '.').replace(',.', ' ') }]);
            setMessage('');
        }
    };

    const handleMessage = (msg) => {
        if (`${user.first_name} ${user.last_name}` !== data[0]?.name) {
            axios.post(
                '/api/v1/owner/reservation_question_reply/'+data[0]?.id,
                { reply: msg },
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true,
                }
            );
        }
    };

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    }, [messages.length]);

    useEffect(() => setMessages(data), [data]);

    if (messages.length === 0) {
        return null;
    }

    return (
        <>
            <div className='MessagesList' ref={ containerRef }>
                { messages.map(({ name, message, icon, date }, index) => (
                    <React.Fragment key={ id+'_'+index }>
                        { index > 0 && messages[index - 1].date.split(' ')[0] !== date.split(' ')[0] && <div className='Day'>{ date.split(' ')[0] }</div> }
                        <div className='Message'>
                            <div className='MessageWrapper'>
                                { icon ? <img src={ icon } alt="User" /> : <div className='Avatar'>{ `${(name.split(' ')[0] || '.').charAt(0)}${(name.split(' ')[1] || '.').charAt(0)}` }</div> }
                                <div className='Content'>
                                    <div className='Info'>
                                        <div className='Name'>{ name }<span>{ date.split(' ')[1] }</span></div>
                                        <div className='Options'>
                                            { name === user?.first_name + ' ' + user?.last_name &&
                                                <DeleteOutlined
                                                  onClick={ () => {
                                                      setMessages(prev => prev.filter((_, i) => i !== index));
                                                      antdMessage.success('Message deleted!');
                                                  } }
                                                /> }
                                            { name === user?.first_name + ' ' + user?.last_name && <EditOutlined /> }
                                            <CopyOutlined
                                              onClick={ () => {
                                                  navigator.clipboard.writeText(message);
                                                  antdMessage.success('Copied to clipboard!');
                                              } }
                                            />
                                        </div>
                                    </div>
                                    <div className='Text'>{ message }</div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )) }
            </div>
            <Input
              className='Input'
              placeholder='Write a message...'
              value={ message }
              onChange={ ({ target }) => setMessage(target.value) }
              onKeyUp={ handleKeyPress }
              suffix={
                  <SendOutlined onClick={ () => {
                      handleMessage(message);
                      setMessages(prev => [...prev, { name: user?.first_name + ' ' + user?.last_name, message, icon: null, date: (new Date()).toLocaleString('en-UK', dateOptions).replaceAll(' ', '.').replace(',.', ' ') }]);
                      setMessage('');
                    } }
                  />
              }
            />
        </>
    );
}