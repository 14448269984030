import React, { createContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getOffers, setOffersFilters, } from '../state/offer/offerSlice';
import { useDispatch, useSelector } from 'react-redux';

export const OfferFiltersContext = createContext();

export function OfferFiltersProvider({ children: elements }) {
    const dispatch = useDispatch();
    const locations = useSelector((state) => state.locale.locations);
    const offersSearchFilters = useSelector((state) => state.offer.offersFilters);

    // ****** START: fetching data from url after homepage redirects *******
    const [queryParameters] = useSearchParams();
    const location = Number(queryParameters.get('location') ?? -1);
    const rent_type = queryParameters.get('rent_type');
    const guests = queryParameters.get('guests');
    const rooms = queryParameters.get('rooms');
    const min_price = queryParameters.get('min_price');
    const max_price = queryParameters.get('max_price');
    const amenities = queryParameters.get('amenities');
    const range = queryParameters.get('range');
    const startDate = queryParameters.get('startDate');
    const urlParameters = {
        yearly: null,
        sort: '-newest',
        rent_type: rent_type ? parseInt(rent_type) : null,
        amenities: JSON.parse(amenities || '[]'),
        min_price: min_price ? parseInt(min_price) : null,
        max_price: max_price ? parseInt(max_price) : null,
        range: range ? parseInt(range) : 50,
        rooms: rooms ? parseInt(rooms) : null,
        guests: guests ? parseInt(guests) : null,
        address: location >= 0 ? { ...locations[location], index: location } : null,
    };

    const [parameters, setParameters] = useState(urlParameters);
    // ****** END: fetching data from url after homepage redirects *******

    const handleInputChange = (name, value) => {
        dispatch(setOffersFilters({ ...offersSearchFilters, [name]: value || value >= 0 ? value : null }));

        if(name === 'sort') {
            searchOffers();
        }
    };

    const handleSelect = (value) => {
        dispatch(setOffersFilters({ ...offersSearchFilters, address: { ...locations[value], index: value } }));
    };

    const searchOffers = () => {
        const path = Object.entries(parameters).reduce((acc, [key, value]) => {
            if (value) {
                if (key === 'address' && value.index >= 0) {
                    acc += `${acc === '?' ? '' : '&'}location=${value.index}`;
                } else if (key === 'amenities' && value?.length > 0) {
                    acc += `${acc === '?' ? '' : '&'}amenities=${JSON.stringify(value)}`
                } else if (key !== 'address') {
                    acc += `${acc === '?' ? '' : '&'}${key}=${value}`;
                }
            }

            return acc;
        }, '?');

        window.history.replaceState(null, "", "/search/offer" + path);
        dispatch(getOffers());
    };

    const clearFilters = () => {
        const filters = {
            startDate: startDate ?? null,
            yearly: null,
            sort: '-newest',
            rent_type: null,
            amenities: null,
            min_price: null,
            max_price: null,
            range: null,
            rooms: null,
            guests: null,
            address: null,
        };

        setParameters(filters);
        dispatch(setOffersFilters(filters));
        window.history.replaceState(null, "", "/search/offer?sort=-newest");
        dispatch(getOffers());
    };

    const contextValue = {
        parameters,
        setParameters,
        clearFilters,
        handleInputChange,
        handleSelect,
        searchOffers,
    };

    useEffect(() => {
        setParameters({ ...offersSearchFilters });
    }, [offersSearchFilters]);

    useEffect(() => {
        const path = Object.entries(parameters).reduce((acc, [key, value]) => {
            if (value) {
                if (key === 'address' && value.index >= 0) {
                    acc += `${acc === '?' ? '' : '&'}location=${value.index}`;
                } else if (key === 'amenities' && value?.length > 0) {
                    acc += `${acc === '?' ? '' : '&'}amenities=${JSON.stringify(value)}`
                } else if (key !== 'address') {
                    acc += `${acc === '?' ? '' : '&'}${key}=${value}`;
                }
            }

            return acc;
        }, '?');
        const statePath = Object.entries(offersSearchFilters).reduce((acc, [key, value]) => {
            if (value) {
                if (key === 'address' && value.index >= 0) {
                    acc += `${acc === '?' ? '' : '&'}location=${value.index}`;
                } else if (key === 'amenities' && value?.length > 0) {
                    acc += `${acc === '?' ? '' : '&'}amenities=${JSON.stringify(value)}`
                } else if (key !== 'address') {
                    acc += `${acc === '?' ? '' : '&'}${key}=${value}`;
                }
            }

            return acc;
        }, '?');

        if (statePath !== path) {
            dispatch(setOffersFilters({ ...parameters }));
            dispatch(getOffers());
        }
    }, []);

    return (
        <OfferFiltersContext.Provider value={ contextValue }>
            { elements }
        </OfferFiltersContext.Provider>
    );
};