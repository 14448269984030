import axios from "./api/axios";
import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Notifications from './components/Notification/Notifications';
import Loading from './components/Loading';
import ProtectedRoute, { AdminRoute, CompleteRegistrationRoute, OwnerRoute } from './routes/Protected';
import { useDispatch, useSelector } from 'react-redux';
import Popup from "./components/Popup";
import CarPage from "./routes/CarPage";
import { getCommon, getLocations } from "./state/locale/localeSlice";
import Container from "./components/Container/Container";
import OwnerDahboard from "./routes/OwnerDahboard";
import { checkUser, getFavorites } from "./state/auth/authSlice";
import Test from "./routes/Test";
import SavedPage from "./routes/SavedPage";
import CarsProductListPage from "./routes/CarsProductListPage";
import i18next from "i18next";
import { ConfigProvider } from "antd";
const HomePage = lazy(() => import('./routes/HomePage'));
const ContactUs = lazy(() => import('./routes/ContactUs'));
const Authentication = lazy(() => import('./routes/Authentication'));
const ProductListPage = lazy(() => import('./routes/ProductListPage'));
const Dashboard = lazy(() => import('./routes/Dashboard'));
const NotFound = lazy(() => import('./routes/NotFound'));
const OfferPage = lazy(() => import('./routes/OfferPage'));
const Account = lazy(() => import('./routes/Account'));
const CreateCar = lazy(() => import('./routes/CreateCar'));
const PaymentSuccessPage = lazy(()=>import('./routes/PaymentSuccessPage'));
const PaymentFailPage = lazy(()=>import('./routes/PaymentFailPage'));

export const OWNER_DASHBOARD_URL = '/dashboard';

axios.defaults.withCredentials = true;

function App() {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const csrfStatus = useSelector((state) => state.auth.csrfStatus);

    useEffect(() => {
        if (csrfStatus) {
            if (!isAuthenticated) {
                dispatch(getCommon());
                dispatch(getLocations());
                dispatch(getFavorites());
                dispatch(checkUser());
            }
        }
    }, [isAuthenticated, csrfStatus])

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('language'));
        document.body.style.direction = localStorage.getItem('language') === 'ar' ? 'rtl' : 'ltr';
        document.body.style.setProperty('--normal-text-size', localStorage.getItem('language') === 'ar' ? '18px' : '16px');
    }, []);

    return (
        <Router>
            <Popup />
            <div className="PageContainer">
                <ConfigProvider
                  theme={ {
                        token: {
                            colorPrimary: '#f2622a',
                            borderRadius: 2,
                            colorBgContainer: '#fffffe',
                        },
                    } }
                >
                    <Navbar />
                    <Notifications />
                    <Container>
                        { !csrfStatus ? <Loading /> : (
                            <Suspense fallback={ <Loading /> }>
                                <Routes>
                                    <Route exact path="/" element={ <HomePage /> } />

                                    <Route exact path="/login" element={ <Authentication /> } />
                                    <Route exact path="/signup" element={ <Authentication type={ 1 } /> } />
                                    <Route exact path="/forgot-password" element={ <Authentication type={ 2 } /> } />
                                    <Route exact path="/password-reset" element={ <Authentication type={ 3 } /> } />
                                    <Route path="/" element={ <CompleteRegistrationRoute /> }>
                                        <Route exact path="/complete_registration" element={ <Authentication type={ 4 } /> } />
                                    </Route>
                                    <Route exact path="/admin/*" element={ <Authentication type={ 5 } /> } />

                                    <Route exact path="/testt" element={ <Test /> } />
                                    <Route exact path="/test" element={ <Dashboard /> } />
                                    <Route exact path="/offer" element={ <OfferPage /> } />
                                    <Route path="/paymentSuccessPage" element={ <PaymentSuccessPage /> } />
                                    <Route path="/paymentFailPage" element={ <PaymentFailPage /> } />
                                    <Route exact path="/test-loading" element={ <Loading /> } />

                                    <Route exact path="/car" element={ <CarPage /> } />
                                    <Route exact path="/search/offer" element={ <ProductListPage /> } />
                                    <Route exact path="/search/car" element={ <CarsProductListPage /> } />
                                    <Route path="/" element={ <ProtectedRoute /> }>
                                        <Route path="/" element={ <AdminRoute /> }>
                                            { /* <Route exact path="/dashboard" element={ <Dashboard /> } /> */ }
                                        </Route>
                                        <Route path="/" element={ <OwnerRoute /> }>
                                            <Route exact path="/create/offer" element={ <Account /> } />
                                            <Route exact path="/create/car" element={ <CreateCar /> } />
                                        </Route>
                                        <Route exact path={ OWNER_DASHBOARD_URL + "/*" } element={ <OwnerDahboard /> } />
                                        <Route exact path="/saved" element={ <SavedPage /> } />
                                    </Route>
                                    <Route path="/destinations" element={ <HomePage /> } />
                                    <Route path="/cars" element={ <HomePage /> } />
                                    <Route path="/favourites" element={ <HomePage /> } />
                                    <Route path="/messages" element={ <HomePage /> } />
                                    <Route path="/contact-us" element={ <ContactUs /> } />
                                    <Route path="*" element={ <NotFound /> } />
                                </Routes>
                            </Suspense>
                        ) }
                    </Container>
                    <Footer />
                </ConfigProvider>
            </div>
        </Router>

    );
}

export default App;
