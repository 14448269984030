/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../api/axios";
import { OFFER_CREATION_REQUEST, OFFER_FETCH_REQUEST, PROPERTY_TYPES_REQUEST, RENT_TYPES_REQUEST } from "../../config";
import removeNullProperties from "../../util/removeNullProperties";

const initialInputs = {
    title: null,
    description: null,
    price_per_night: null,
    accepts_10_percent_discount_after_7_days_of_stay: false,
    rooms_number: null,
    separate_beds_number: null,
    double_beds_number: null,
    bathrooms_number: null,
    maximum_guests: null,
    type: null,
    wilaya: null,
    daira: null,
    commune: null,
    latitude: null,
    longitude: null,
    address: null,
    phone: null,
    images: null,
    rules: null
};

const initialInputsErrors = {
    address: null,
    images: null,
    title: null,
    type: null,
    details: null,
    rules: null,
    price: null
};

const initialState = {
    offer: {},
    offers: [],
    userOffers: [],
    offersFilters: {},
    offersPagination: {},
    propertiesTypes: [],
    rentTypes: [],
    inputs: { ...initialInputs },
    isValidated: false,
    getOfferStatus: null,
    requestStatus: null,
    requestPendingStatus: null,
    deleteOfferStatus: null,
    uploadStatus: null,
    switchStatus: null,
    isLoading: false,
    error: null,
    requestPendingError: null,
    updateOfferStatus: null,
    inputsErrors: { ...initialInputsErrors }
};

const OfferSlice = createSlice({
    name: 'Offer',
    initialState,
    reducers: {
        setOffersFilters: (state, action) => {
            state.offersFilters = action.payload;
        },
        setOffersPagination: (state, action) => {
            state.offersPagination = action.payload;
        },
        setType: (state, action) => {
            state.inputs.type = action.payload;
            state.inputsErrors.type = null;
        },
        setExtras: (state, action) => {
            state.inputs.extras = action.payload;
            state.inputsErrors.extras = null;
        },
        setAddress: (state, action) => {
            state.inputs.address = action.payload.address;
            state.inputs.wilaya = action.payload.wilaya;
            state.inputs.daira = action.payload.daira;
            state.inputs.commune = action.payload.commune;
            state.inputs.latitude = action.payload.lat?.toString();
            state.inputs.longitude = action.payload.lng?.toString();
            state.inputsErrors.address = null;
        },
        setTitle: (state, action) => {
            state.inputs.title = action.payload.title;
            state.inputs.description = action.payload.description;
            state.inputsErrors.title = null;
        },
        setImages: (state, action) => {
            state.inputs.images = action.payload;
            state.inputsErrors.images = null;
        },
        setLocalOfferImages: (state, action) => {
            state.offer.images = [...action.payload];
        },
        setRules: (state, action) => {
            state.inputs.rules = [...action.payload];
            state.inputsErrors.rules = null;
        },
        setDetails: (state, action) => {
            state.inputs.rooms_number = action.payload.bedrooms;
            state.inputs.separate_beds_number = action.payload.singleBeds;
            state.inputs.double_beds_number = action.payload.doubleBeds;
            state.inputs.bathrooms_number = action.payload.bathrooms;
            state.inputs.maximum_guests = action.payload.travelers;
            state.inputsErrors.details = null;
        },
        setOfferPrice: (state, action) => {
            state.inputs.phone = action.payload.phone;
            state.inputs.price_per_night = action.payload.price;
            state.inputs.accepts_10_percent_discount_after_7_days_of_stay = action.payload.sale;
            state.inputsErrors.price = null;
        },
        setOffers: (state, action) => {
            state.offers = action.payload.data;
        },
        setOfferEdit: (state, action) => {
            state.offer = action.payload;
            state.inputs.type = action.payload.type?.id; 
            state.inputs.address = action.payload.address?.address;
            state.inputs.wilaya = action.payload.address?.wilaya_id;
            state.inputs.daira = action.payload.address?.daira_id;
            state.inputs.commune = action.payload.address?.commune_id;
            state.inputs.latitude = action.payload.address?.latitude;
            state.inputs.longitude = action.payload.address?.longitude;
            state.inputs.images = action.payload.images;
            state.inputs.title = action.payload.title;
            state.inputs.description = action.payload.description;
            state.inputs.rooms_number = action.payload.rooms_number;
            state.inputs.separate_beds_number = action.payload.separate_beds_number;
            state.inputs.double_beds_number = action.payload.double_beds_number;
            state.inputs.bathrooms_number = action.payload.bathrooms_number;
            state.inputs.maximum_guests = action.payload.maximum_guests;
            state.inputs.details = action.payload.details;
            state.inputs.rules = action.payload.rules || [];
            state.inputs.extras = action.payload.extras || [];
            state.inputs.price_per_night = action.payload.price;
        },
        resetStatus: (state) => {
            state.updateOfferStatus = null;
        },
        resetOfferRequest: (state) => {
            state.isLoading = false;
            state.requestStatus = null;
            state.requestPendingStatus = null;
            state.uploadStatus = null;
            state.isValidated = false;
            state.offer = {};
            state.error = null;
            state.inputs = { ...initialInputs };
            state.inputsErrors = { ...initialInputsErrors };
        },
        setOfferSelectionData: (state, action) => {
            state.propertiesTypes = [...action.payload.propertiesTypes];
            state.rentTypes = [...action.payload.rentTypes];
        },
        validateInputs: (state) => {
            // mimicking the backend error to follow the same structure
            const errorMsg = ['Please fill all the fields.'];
            const inputsErrors = {};
            inputsErrors.type = state.inputs.type ? null : errorMsg;
            inputsErrors.address = state.inputs.address || state.inputs.wilaya || state.inputs.commune || state.inputs.daira || state.inputs.latitude || state.inputs.longitude ? null : errorMsg;
            inputsErrors.images = state.offer?.images?.length >= 3 && state.offer?.images?.length <= 14 ? null : ['Please choose at least 3 images, and no more than 14 images.'];
            inputsErrors.title = state.inputs.title || state.inputs.description ? null : errorMsg;
            inputsErrors.details = state.inputs.rooms_number || state.inputs.separate_beds_number || state.inputs.double_beds_number || state.inputs.bathrooms_number || state.inputs.maximum_guests ? null : errorMsg;
            inputsErrors.rules = state.inputs.rules ? null : ['Please choose at least 1 option.'];
            inputsErrors.price = state.inputs.price_per_night || state.inputs.accepts_10_percent_discount_after_7_days_of_stay ? null : errorMsg;

            if (Object.values(inputsErrors).filter((value) => value !== null).length === 0) {
                state.inputsErrors = { ...initialInputsErrors };
                state.isValidated = true;
            } else {
                state.inputsErrors = { ...inputsErrors };
            }

            state.requestStatus = null;
            state.requestPendingStatus = null;
        },
    },
    extraReducers: (builder) => {
        builder
            // requestOfferCreation cases
            .addCase(requestOfferCreation.pending, (state) => {
                state.requestStatus = 'pending';
                state.error = null;
                state.isValidated = false;
            })
            .addCase(requestOfferCreation.fulfilled, (state, action) => {
                state.isValidated = false,
                state.isLoading = false;
                state.error = null;
                state.requestStatus = 'success';

                if (!state.offer?.id) {
                    state.offer = action.payload.data.data;
                }
            })
            .addCase(requestOfferCreation.rejected, (state, action) => {
                state.requestStatus = 'error';
                state.error = action.payload?.errors ? null : "Something went wrong. Please try again later.";
                state.inputsErrors = { ...state.inputsErrors, ...(action.payload?.errors || {}) };
            })
            // requestOfferPending cases
            .addCase(requestOfferPending.pending, (state) => {
                state.requestPendingStatus = 'pending';
                state.requestPendingError = null;
            })
            .addCase(requestOfferPending.fulfilled, (state) => {
                state.requestPendingStatus = 'success';
                state.inputsErrors = { ...initialInputsErrors };
            })
            .addCase(requestOfferPending.rejected, (state, action) => {
                state.requestPendingStatus = 'error';
                state.requestPendingError = action.payload?.data?.errors ? null : "Something went wrong. Please try again later.";
                state.inputsErrors = action.payload?.data?.errors || { ...initialInputsErrors };
            })
            // uploadImages cases
            .addCase(uploadImages.pending, (state) => {
                state.uploadStatus = 'pending';
                state.error = null;
            })
            .addCase(uploadImages.fulfilled, (state, action) => {
                state.uploadStatus = 'success';
                state.offer.images = state.offer.images?.length > 0 ? [...state.offer.images, ...action.payload.data.data] : action.payload.data.data;
                state.inputsErrors.images = null;
            })
            .addCase(uploadImages.rejected, (state) => {
                state.uploadStatus = 'error';
                // using an array to simulate the errors we receive from backend
                state.inputsErrors.images = ['Images are required, make sure to provide minimum of 3 and maximum of 14 images'];
                state.error = "Something went wrong. Please try again later.";
            })
            // switchImages cases
            .addCase(switchImages.pending, (state) => {
                state.switchStatus = 'pending';
                state.error = null;
            })
            .addCase(switchImages.fulfilled, (state) => {
                state.switchStatus = 'success';
            })
            .addCase(switchImages.rejected, (state) => {
                state.switchStatus = 'error';
                state.error = "Something went wrong. Please try again later.";
            })
            // removeUploadedImage cases
            .addCase(removeUploadedImage.pending, (state) => {
                state.requestStatus = 'pending';
                state.error = null;
            })
            .addCase(removeUploadedImage.fulfilled, (state) => {
                state.requestStatus = 'success';
            })
            .addCase(removeUploadedImage.rejected, (state, action) => {
                state.requestStatus = 'error';
                state.error = "Something went wrong. Please try again later.";
            })
            // getOffer cases
            .addCase(getOffer.pending, (state) => {
                state.getOfferStatus = 'pending';
                state.error = null;
            })
            .addCase(getOffer.fulfilled, (state, action) => {
                state.getOfferStatus = null;
                state.offer = action.payload.data.data;
            })
            .addCase(getOffer.rejected, (state) => {
                state.getOfferStatus = null;
                state.error = "Something went wrong. Please try again later.";
            })
            //getOffersCases
            .addCase(getOffers.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getOffers.fulfilled, (state, action) => {
                state.offers = [...action.payload.data.data];
                state.offersPagination = { ...action.payload.meta };
                state.isLoading = false;
            })
            .addCase(getOffers.rejected, (state, action) => {
                state.error = "Something went wrong. Please try again later.";
                state.isLoading = false;
            })
            //getOffersCases
            .addCase(getOwnerOffers.pending, (state) => {
                state.ownerOffersStatus = 'pending';
            })
            .addCase(getOwnerOffers.fulfilled, (state, action) => {
                state.userOffers = [...action.payload.data.data];
                state.ownerOffersStatus = 'success';
                state.error = null;
            })
            .addCase(getOwnerOffers.rejected, (state, action) => {
                state.userOffers = [];
                state.ownerOffersStatus = 'error';
                state.error = "Something went wrong. Please try again later.";
            })
            //updateStatus
            .addCase(updateStatus.pending, (state) => {
                state.updateOfferStatus = 'pending';
            })
            .addCase(updateStatus.fulfilled, (state) => {
                state.updateOfferStatus = 'success';
            })
            .addCase(updateStatus.rejected, (state) => {
                state.updateOfferStatus = 'error';
            })
            .addCase(getPropertyTypes.fulfilled, (state, action) => {
                state.propertiesTypes = [...action.payload.data.data];
            })
            .addCase(getRentTypes.fulfilled, (state, action) => {
                state.rentTypes = [...action.payload.data.data];
            })
            .addCase(deleteOffer.pending, (state) => {
                state.deleteOfferStatus = 'pending';
            })
            .addCase(deleteOffer.fulfilled, (state, action) => {
                state.deleteOfferStatus = 'success';
            })
            .addCase(deleteOffer.rejected, (state) => {
                state.deleteOfferStatus = 'error';  
            });
    }
});

export const requestOfferCreation = createAsyncThunk(
    'requestOfferCreation',
    async ({ withPending = false }, { getState, rejectWithValue, dispatch }) => {
        // eslint-disable-next-line no-unused-vars
        const { images, extras, rules, ...currentState } = getState().offer.inputs;
        const props = removeNullProperties({
            extras: extras ?? [],
            user_rules: rules ?? [],
            ...currentState
        });
        const response = getState().offer.offer?.id
            ? await axios.put(
                `${OFFER_CREATION_REQUEST}/${getState().offer.offer.id}`,
                props,
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true,
                }
            ).then((res) => {
                if(withPending) {
                    dispatch(requestOfferPending({ id: getState().offer.offer?.id }));
                }

                return res;
            }).catch((err) => {
                if (!err?.response) {
                    return rejectWithValue('No Server Response');
                } else if (err?.response?.data) {
                    return rejectWithValue(err.response.data);
                }
            })
            : await axios.post(
                `${OFFER_CREATION_REQUEST}`,
                props,
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true,
                }
            ).then((res) => {
                if(withPending) {
                    dispatch(requestOfferPending({ id: res.data.data.id }));
                }

                return res;
            }).catch((err) => {
                if (!err?.response) {
                    return rejectWithValue('No Server Response');
                } else if (err?.response?.data) {
                    return rejectWithValue(err.response.data);
                }
            });

        return response;
    }
);

export const requestOfferPending = createAsyncThunk(
    'requestOfferPending',
    async ({ id }, { rejectWithValue }) => {
        const response = await axios.put(
            `${OFFER_CREATION_REQUEST}/${id}/mark_as_pending`,
            { withCredentials: true }
        ).catch((err) => {
            if (!err?.response) {
                return rejectWithValue('No Server Response');
            } else if (err?.response?.data) {
                return rejectWithValue(err.response.data);
            }
        });

        return response;
    }
);

export const uploadImages = createAsyncThunk(
    'uploadImages',
    async ({ selectedImages, id }, { getState, rejectWithValue }) => {
        const offerId = id >= 0 ? id : getState().offer.offer.id;
        const images = selectedImages ?? getState().offer.inputs.images;
        const response = await axios.post(
            `${OFFER_CREATION_REQUEST}/${offerId}/images`,
            {
                images
            },
            {
                headers: { 'Content-Type': 'multipart/form-data' },
                withCredentials: true,
            }
        ).catch((err) => {
            if (!err?.response) {
                return rejectWithValue('No Server Response');
            }

            return rejectWithValue(err.response?.message);
        });

        return response;
    }
);

export const switchImages = createAsyncThunk(
    'switchImages',
    async ({ ids }, { getState, rejectWithValue, dispatch }) => {
        const images = getState().offer.offer?.images;

        if (images?.length > 1) {
            const index0 = images.findIndex((image) => image.id === ids[0]);
            const index1 = images.findIndex((image) => image.id === ids[1]);
            const temp = images[index0];
            images[index0] = images[index1];
            images[index1] = temp;
            dispatch(setLocalOfferImages(images));
        }

        const response = await axios.put(
            `${OFFER_CREATION_REQUEST}/images/${ids[0]}/${ids[1]}`,
            {
                withCredentials: true,
            }
        ).catch((err) => {
            if (images?.length > 1) {
                const index0 = images.findIndex((image) => image.id === ids[0]);
                const index1 = images.findIndex((image) => image.id === ids[1]);
                const temp = images[index0];
                images[index0] = images[index1];
                images[index1] = temp;
                dispatch(setLocalOfferImages(images));
            }

            if (!err?.response) {
                return rejectWithValue('No Server Response');
            }

            return rejectWithValue(err.response?.message);
        });

        return response;
    }
);

export const orderImages = createAsyncThunk(
    'orderImages',
    async (_, { getState }) => {
        const images = getState().offer.offer?.images.map((image, index) => {
            return {
                id: image.id,
                order: index + 1
            };
        });

        const response = await axios.put(
            `api/v1/owner/properties/reorder_images`,
            {
                images
            },
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true,
            }
        );

        return response;
    }
);

export const removeUploadedImage = createAsyncThunk(
    'removeUploadedImage',
    async ({ id }, { getState, dispatch }) => {
        const response = await axios.delete(
            `api/v1/owner/properties/images/${id}`,
            {
                headers: { 'Content-Type': 'multipart/form-data' },
                withCredentials: true,
            }
        ).then((res) => {
            const images = getState().offer.offer?.images;

            if (images?.length > 1) {
                dispatch(setLocalOfferImages((images || []).filter((image) => image.id !== id)));
            }

            return res;
        });

        return response;
    }
);

export const getOffer = createAsyncThunk(
    'getOffer',
    async ({ id, withEdit = false }, { dispatch }) => {
        const response = await axios.get(
            `${OFFER_FETCH_REQUEST}/${id}`,
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true,
            }
        ).then((res) => {
            if (withEdit) {
                dispatch(setOfferEdit(res.data.data));
            }

            return res;
        });

        return response;
    }
);

export const updateStatus = createAsyncThunk(
    'updateStatus',
    async ({ id }) => {
        const response = await axios.put(
            `/api/v1/owner/properties/${id}/toggle_visibility`,
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true,
            }
        );

        return response;
    }
);

export const getOwnerOffers = createAsyncThunk(
    'getOwnerOffers',
    async () => {
        const url = `${OFFER_CREATION_REQUEST}?include=address.commune,address.wilaya,images`;

        const response = await axios.get(url, {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
        });

        return response;
    }
);

export const getOffers = createAsyncThunk(
    'getOffers',
    async (_, { getState }) => {
        const { min_price, max_price, type, address, range, rent_type, sort, guests, rooms, amenities } = getState().offer.offersFilters;
        const { per_page, page } = getState().offer.offersPagination;
        const perPageParam = per_page ? `per_page=${per_page}` : '';
        const currentPageParam = page ? `page=${page}` : '';
        const sortBy = sort ? `&sort=${sort}` : '';
        const minPrice = min_price ? `&filter[min_price]=${min_price}` : '';
        const maxPrice = max_price ? `&filter[max_price]=${max_price}` : '';
        const typeFilter = type ? `&filter[type]=${type}` : '';
        const lat = address?.lat ? `&filter[lat]=${address.lat}` : '';
        const lon = address?.lng ? `&filter[lon]=${address.lng}` : '';
        const rangeFilter = range >= 0 ? `&filter[range]=${range}` : '';
        const rentType = rent_type ? `&filter[type]=${rent_type}` : '';
        const guestsCount = guests ? `&filter[guests]=${guests}` : '';
        const roomsCount = rooms ? `&filter[rooms]=${rooms}` : '';
        const amenitiesArray = amenities && amenities.length ? `&filter[amenities]=${amenities}` : '';

        let queryParams = [perPageParam, currentPageParam].filter(Boolean).join('&');

        queryParams = queryParams ? `${queryParams}&` : '';

        const url = `${OFFER_FETCH_REQUEST}?${queryParams}include=address.commune,address.wilaya,images${minPrice}${maxPrice}${typeFilter}${lat}${lon}${rangeFilter}${rentType}${sortBy}${guestsCount}${roomsCount}${amenitiesArray}`;

        const response = await axios.get(url, {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
        });

        return response;
    }
);

export const getPropertyTypes = createAsyncThunk(
    'getPropertyTypes',
    async () => {
        const response = await axios.get(`${PROPERTY_TYPES_REQUEST}`, {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
        });

        return response;
    }
);

export const getRentTypes = createAsyncThunk(
    'getRentTypes',
    async () => {
        const response = await axios.get(`${RENT_TYPES_REQUEST}`, {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
        });

        return response;
    }
);

export const deleteOffer = createAsyncThunk(
    'deleteOffer',
    async ({ id }) => {
        const response = await axios.delete(`${OFFER_CREATION_REQUEST}/${id}`, {
            withCredentials: true,
        });

        return response;
    }
);

export const {
    setType,
    setExtras,
    setAddress,
    setTitle,
    setImages,
    setRules,
    setDetails,
    setOfferPrice,
    setOfferEdit,
    resetStatus,
    resetOfferRequest,
    validateInputs,
    setOffersFilters,
    setOffersPagination,
    setLocalOfferImages,
    setOfferSelectionData
} = OfferSlice.actions;

export default OfferSlice.reducer;