import React, { useEffect, useState } from "react";
import Notification from "./Notification";
import { useDispatch } from "react-redux";
import { resetOfferRequest } from "../../state/offer/offerSlice";
import { resetCarRequest } from "../../state/car/carSlice";

export default function Notifications() {
    const dispatch = useDispatch();
    const [ data, setData ] = useState([]);

    const handleNotification = ({ detail }) => {
        setData((prevState) => [ ...prevState, { ...detail, id: Date.now() } ]);

        if (detail.status === 'success' && detail.type === 'car') {
            dispatch(resetCarRequest());
        } else if (detail.status === 'success' && detail.type === 'offer') {
            dispatch(resetOfferRequest());
        }
    };

    const handleRemove = (id) => {
        setData((prevNotifications) => prevNotifications.filter((n) => n.id !== id));
    };

    useEffect(() => {
        setData([]);
        document.addEventListener('showNotification', handleNotification);

        return () => {
            setData([]);
            document.removeEventListener('showNotification', handleNotification);
        };
    }, []);

    return (
        <div className={ `Notifications${data.length ? ' Visible' : ' Hidden'}` }>
            { data.map((props, index) => (<Notification key={ index } { ...props } remove={ () => handleRemove(props.id) } />)) }
        </div>
    );
}