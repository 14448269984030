import { setIsMobile } from "../state/locale/localeSlice";
import debounce from 'lodash.debounce';

let prevIsMobile = false;

export const updateIsMobile = () => (dispatch) => {
    const checkIsMobile = () => {
        const isMobile = window.innerWidth < 768;

        if (isMobile !== prevIsMobile) {
            dispatch(setIsMobile(isMobile));
            prevIsMobile = isMobile;
        }
    };

    const debouncedResize = debounce(checkIsMobile, 250);

    debouncedResize();
    window.addEventListener("resize", debouncedResize);
};
