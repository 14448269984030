const gtUrl = () => {
    const host = window.location.hostname;

    return host.includes('local') ? 'http://localhost:8000' : 'https://backend.baytup.com';
};

export const BASE_REQUEST_URL = gtUrl();
export const REQUEST_TIMEOUT = 15000;
export const USER_API = '/api/v1/common/user';
export const GOOGLE_LOGIN_URL = '/auth/google/redirect';
export const COMPLETE_REGISTRATION = '/api/v1/common/complete_registration';
export const USER_FAVORITES_REQUEST = '/api/v1/owner/properties/favorites';
export const OFFER_CREATION_REQUEST = '/api/v1/owner/properties';
export const OFFER_FETCH_REQUEST = '/api/v1/common/properties';
export const CAR_FETCH_REQUEST = '/api/v1/common/cars';
export const PROPERTY_TYPES_REQUEST = '/api/v1/common/properties_types';
export const RENT_TYPES_REQUEST = '/api/v1/common/rent_types';
export const CAR_CREATION_REQUEST = '/api/v1/owner/cars';
export const VERIFICATION_CODE_REQUEST = '/api/v1/owner/phone-number-verification-notification';
export const PHONE_VERIFICATION_REQUEST = '/api/v1/owner/phone-number-code-confirmation';
export const CARS_TYPES_REQUEST = '/api/v1/common/cars_type';
export const CAR_SEATS_NUMBER_REQUEST = '/api/v1/common/car_seats_number';
export const CARS_MODELS_REQUEST = '/api/v1/common/cars_models';
export const CARS_MANUFACTURERS_REQUEST = '/api/v1/common/cars_manufacturers';
export const CARS_ENERGY_SOURCES_REQUEST = '/api/v1/common/cars_energy_sources';
export const Languages = [{
    key: "en", val: "English"
},
{ key: "ar", val: "Arabic" },
{ key: "fr", val: "French" },
{ key: "tz", val: "Amazigh" }]
