import React from 'react';

const Grid = ({ children, style, columns }) => {
    return (
        <div
          style={ {
            display: 'grid',
            gridTemplateColumns: `repeat(${columns}, 1fr)`,
            gridGap: 10,
            maxWidth: '800px',
            margin: '20px auto',
            ...style
          } }
        >
            { children }
        </div>
    );
};

export default Grid;
