import React, { useContext } from "react";
import GridList from "./GridList";
import { Empty, Pagination } from "antd";
import { Translator } from "../../i18n";
import Loading from "../Loading";
import { CarFiltersContext } from "../../context/CarFiltersProvider";
import CarCard from "./CarCard";

export default function CarsResultList({ grid, isMap = false, setHoveredId = () => {} }) {
    const {
        cars,
        carsPagination,
        setSearchData,
        isLoading
    } = useContext(CarFiltersContext);

    const defaultCurrentPage = 1;

    const onChange = (page, pageSize) => {
        setSearchData((prev) => ({ ...prev, carsPagination: { per_page: pageSize, page: page } }))
    };

    return (
        <div className="ResultsWrapper">
            { !isLoading
                ? (
                    cars?.length ? (
                        <>
                            { grid ?
                                <GridList items={ cars } setHoveredId={ setHoveredId } />
                                :
                                cars.map((props, index) => (
                                    <CarCard key={ index } { ...props } isMap={ isMap } />
                                ))
                            }
                            <div style={ { margin: 20 } }>
                                <Pagination
                                  current={ carsPagination?.current_page ?? defaultCurrentPage }
                                  defaultPageSize={ carsPagination?.per_page }
                                  showSizeChanger={ true }
                                  total={ carsPagination?.total }
                                  showTotal={ (total) => `Total ${total} items` }
                                  onChange={ onChange }
                                />
                            </div>
                        </>
                    ) : (
                        <div style={ { 'height': '30vh', marginBottom: '20px' } }>
                            <Empty
                              description={
                                  <span>
                                      <Translator text="No results found for your search"/>
                                  </span>
                              }
                            >
                            </Empty>
                        </div>
                    )
                ) : (
                    <Loading />
                )
            }
        </div>
    );
}