import { React, useEffect, useRef, useState } from 'react';
import Logo from '../../assets/HomepageLogo.webp';
import LogoAR from '../../assets/logo-baytup-AR.webp';
import axios from '../../api/axios';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Translator } from '../../i18n';
import { GOOGLE_LOGIN_URL, USER_API } from '../../config';
import GoogleSignInButton from '../GoogleSignInButton';
import { useDispatch, useSelector } from 'react-redux';
import { loginSuccess, logout } from '../../state/auth/authSlice';
import { Checkbox } from 'antd';
import { hidePopup } from '../../state/popup/popupSlice';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PasswordEye from '../../icons/PasswordEye';
import Loading from '../Loading';
export const LOGIN_URL = '/login';

export default function LoginForm({ isPopup, isRedirect = true }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const language = useSelector((state) => state.locale.language);
    const error = useSelector((state) => state.auth.error);
    const [queryParameters] = useSearchParams();
    const isAuthFailed = Boolean(queryParameters.get('auth')) || false;
    const registeredEmail = localStorage.getItem('UserEmail') || '';
    const inputsDataPlaceholder = { email: registeredEmail, password: '', remember: false };
    const emailRef = useRef(null);
    const errRef = useRef(null);
    const navigate = useNavigate();

    // tracking the changes of inputs and the errors, errMsg for server errors, and errors for inputs errors
    const [formData, setFormData] = useState(inputsDataPlaceholder);
    const [isVerified, setIsVerified] = useState(Boolean(queryParameters.get('verified')) || false);
    const [errMsg, setErrMsg] = useState(isAuthFailed ? 'An error has occurred, please try again later' : '');
    const [errors, setErrors] = useState({});
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        if (emailRef && emailRef.current) {
            emailRef.current.focus();
        }
    }, [emailRef]);

    useEffect(() => {
        if (isAuthenticated) {
            // added this condition so that if the user goes to another page, the redirection doesn't occur
            if (window.location.pathname.includes('login')) {
                navigate('/');
            } else if (isPopup) {
                if (isRedirect) {
                    navigate('/create/offer');
                }

                dispatch(hidePopup());
            }
        }
    }, [isAuthenticated]);

    const handleInputChange = (event) => {
        if (errMsg || Object.entries(errors).length) {
            setErrMsg('');
            setErrors({});
        }

        if (isVerified) {
            setIsVerified(false);
        }

        const { name, value, checked } = event.target;

        setFormData({
            ...formData,
            [name]: name === 'remember' ? checked : value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)

        const { email, password, remember } = formData;

        try {
            await axios.get('/sanctum/csrf-cookie');
            await axios.post(
                `${LOGIN_URL}`,
                {
                    email: email,
                    password: password
                },
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true,
                }
            );

            await axios.get(USER_API).then(({ data }) => {
                dispatch(loginSuccess(data));
            });

            if (remember === true) {
                localStorage.setItem('UserEmail', email);
            }
        } catch (err) {
            // added this because unverified users are considered logged in users with no data
            // they return unauthenticated when requesting their data so we have to log them out
            // because they stay logged in and you can't login with another user
            if (err?.response?.data?.message === 'Your email address is not verified.') {
                dispatch(logout());
            }

            const localErrors = {};
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else if (err.response?.status === 409) {
                setErrMsg('Your email address is not verified.');
            } else if (err.response?.status === 422) {
                Object.entries(err.response?.data?.errors).map(
                    ([key, value]) => {
                        localErrors[key] = Array.isArray(value)
                            ? value[0]
                            : value;
                    }
                );
            } else {
                setErrMsg('Login Failed');
            }

            setErrors(localErrors);
            if (emailRef && emailRef.current) {
                emailRef.current.focus();
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="LoginPage">
            { loading ? (
                <Loading />
            ) : (
                <>
                    <div className="Header">
                        { !isPopup ? (
                            <h1 className="Header-Text"><Translator text='Login' /></h1>
                        ) : (
                            <>
                                <h3 className="Header-Text"><Translator text='Login or Register' /></h3>
                                <CloseOutlined onClick={ () => dispatch(hidePopup()) } />
                            </>
                        ) }
                    </div>
                    <div className="Content" data-is-auth={ isAuthenticated }>
                        { isAuthenticated ? (
                            <div className="Success">
                                <h1><Translator text='Successfully Logged in' /></h1>
                                <span><Translator text={ isPopup ? 'redirecting to offer creation' : 'redirecting to dashboard' } /></span>
                            </div>
                        ) : (
                            <>
                                { isPopup && (
                                    <h3 style={ { color: 'white', paddingBlockEnd: 10 } }>
                                        <div className="Logo">
                                            <img src={ language === 'ar' ? LogoAR : Logo } />
                                        </div>
                                        <Translator text='Welcome to Baytup' />
                                    </h3>
                                ) }
                                <div className="FormWrapper">
                                    <form className="Form" onSubmit={ handleSubmit }>
                                        { isVerified && (
                                            <div style={ { color: 'green', textAlign: 'center', paddingBlockEnd: 10 } }>
                                                Email is Successfully verified
                                            </div>
                                        ) }
                                        <div className="Form-Error" ref={ errRef }>
                                            { errMsg ?? error }
                                        </div>
                                        <div className="InputsWrapper">
                                            <div className='InputWrapper'>
                                                <div className="InputWrapper-Error">{ errors.email }</div>
                                                <label htmlFor="email"><Translator text='Email' /></label>
                                                <input
                                                  autoComplete='off'
                                                  type="email"
                                                  id="email"
                                                  name="email"
                                                  placeholder={ t('Enter your email address') }
                                                  ref={ emailRef }
                                                  value={ formData.email }
                                                  onChange={ handleInputChange }
                                                />
                                            </div>
                                            <div className="InputWrapper">
                                                <label htmlFor="password"><Translator text='Password' /></label>
                                                <input
                                                  type={
                                                        passwordVisibility
                                                            ? 'text'
                                                            : 'password'
                                                    }
                                                  id="password"
                                                  name="password"
                                                  placeholder={ t('Enter password') }
                                                  value={ formData.password }
                                                  onChange={ handleInputChange }
                                                />
                                                <PasswordEye
                                                  onClick={ () => setPasswordVisibility((prevState) => !prevState) }
                                                  shown={ passwordVisibility }
                                                />
                                            </div>
                                        </div>
                                        <div className="Additional">
                                            <div className="Remember">
                                                <Checkbox
                                                  id="remember"
                                                  name="remember"
                                                  checked={ formData.remember }
                                                  onChange={ handleInputChange }
                                                  className='Antd-Checkbox'
                                                >
                                                    <Translator text='Remember me' />
                                                </Checkbox>
                                            </div>
                                            <Link to='/forgot-password' className="ForgotPassword" onClick={ () => dispatch(hidePopup()) }>
                                                <Translator text='Forgot Password?' />
                                            </Link>
                                        </div>
                                        <button type="submit"><Translator text='Login' /></button>
                                        <p>
                                            <Translator text="Don't have an account? " />
                                            <Link className='QuestionLink' to="/signup" onClick={ () => dispatch(hidePopup()) }>
                                                <Translator text='Sign Up' />
                                            </Link>
                                        </p>
                                    </form>
                                    <div className='DividerWrapper'>
                                        <div className='Divider' />
                                        <div className='Text'><Translator text='or continue with' /></div>
                                        <div className='Divider' />
                                    </div>
                                    <div className='AlternativeLogins'>
                                        <GoogleSignInButton href={ `https://backend.baytup.com/${GOOGLE_LOGIN_URL}/login` } />
                                    </div>
                                </div>
                            </>
                        ) }
                    </div>
                </>
            ) }
        </div>
    );
}