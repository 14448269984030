import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { useSelector } from 'react-redux';
import Tabs from '../Search/Tabs';
import SearchFilters from './SearchFilters';
import { Translator } from '../../i18n';
import CarSearchFilters from './CarSearchFilters';

export default function SearchArea({ filters, onHandleFiltersChange, isCar = false }) {
    const [showModal, setShowModal] = useState(false);
    const isMobile = useSelector((state) => state.locale.isMobile);

    return (
        <div className="Search-Area">
            <Tabs isSearchArea isCarArea={ isCar } />
            { isMobile ? (
                <>
                    <Button className='Filters-Modal-Button' onClick={ () => setShowModal(!showModal) }><Translator text="Filters" /></Button>
                    <Modal
                      width={ '100%' }
                      style={ { margin: 0, padding: 0, maxWidth: '100%', minHeight: '100vh', maxHeight: '100%', top: 0, left: 0, right: 0, bottom: 0 } }
                      styles={ {
                        content: {
                            display: 'flex',
                            flexDirection: 'column',
                            minHeight: 'inherit',
                        },
                        body: {
                            display: 'flex',
                            marginBlockStart: 30,
                            flex: 1,
                        }
                      } }
                      open={ showModal }
                      title={ <Translator text="Filters" /> }
                      footer={ null }
                      onCancel={ () => setShowModal(!showModal) }
                    >
                        { isCar
                            ? <CarSearchFilters filters={ filters } onHandleFiltersChange={ onHandleFiltersChange } />
                            : <SearchFilters filters={ filters } onHandleFiltersChange={ onHandleFiltersChange } setShowModal={ setShowModal } />
                        }
                    </Modal>
                </>
            ) : (
                isCar
                    ? <CarSearchFilters filters={ filters } onHandleFiltersChange={ onHandleFiltersChange } />
                    : <SearchFilters filters={ filters } onHandleFiltersChange={ onHandleFiltersChange } setShowModal={ setShowModal } />
            ) }
        </div>
    );
}