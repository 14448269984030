import React, { useContext, useRef } from 'react';
import { Button as AButton, Col, InputNumber, Row, Select, Slider } from 'antd';
import { AppstoreAddOutlined, DatabaseOutlined, EnvironmentOutlined, OrderedListOutlined, } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CarFiltersContext } from '../../context/CarFiltersProvider';
import { carOptionsArray } from '../CarForm/CarOptions';

export default function CarSearchFilters({ filters, onHandleFiltersChange, setShowModal }) {
    const { t } = useTranslation();
    const {
        filters: parameters,
        handleInputChange,
        handleSelect,
        clearFilters,
        searchCars
    } = useContext(CarFiltersContext);

    const select = useRef(null);
    const isRtl = useSelector((state) => state.locale.language) === 'ar';
    const locations = useSelector((state) => state.locale.locations);
    const isMobile = useSelector((state) => state.locale.isMobile);
    const types = useSelector((state) => state.car.carSelectionData.carsTypes)?.map((element) => ({ label: element.name, value: element.id }));
    const seats = useSelector((state) => state.car.carSelectionData.carSeats)?.map((element) => ({ label: element.number, value: element.id }));

    const options = carOptionsArray.map(
        (item) => ({
            label: item,
            value: item,
        })
    );
    const marks = {
        0: `0 ${isRtl ? 'كم' : 'Km'}`,
        100: {
            style: {
                color: '#f50',
            },
            label: <strong>100 { isRtl ? 'كم' : 'Km' }</strong>,
        },
    };
    const orderByFilters = [
        { label: t('Newest'), value: '-newest' },
        { label: t('Oldest'), value: 'newest' },
        { label: t('High price first'), value: '-price' },
        { label: t('Low price first'), value: 'price' },
        { label: t('Closest'), value: 'closest' },
        { label: t('Farthest'), value: '-closest' },
    ];

    return (
        <>
            <div style={ { display: 'flex', justifyContent: 'space-between', flexDirection: 'column', flex: 1 } }>
                <Row gutter={ 16 } justify="center" className="FirstFilters">
                    { isMobile && (
                        <Col className="gutter-row" xs={ 22 } sm={ 22 } md={ 22 } lg={ 22 }>
                            <h2>{ t("Property Type") }</h2>
                        </Col>
                    ) }
                    <Col className="gutter-row" xs={ 22 } sm={ 11 } md={ 11 } lg={ 8 }>
                        <Select
                          placeholder={ t("Type") }
                          size="medium"
                          value={ parameters.type ?? null }
                          onChange={ (value) => handleInputChange('type', value) }
                          style={ { width: '100%' } }
                          allowClear
                          options={ types }
                        />
                    </Col>
                    <Col className="gutter-row" xs={ 22 } sm={ 11 } md={ 11 } lg={ 8 }>
                        <Select
                          id="city"
                          ref={ select }
                          showSearch
                          style={ { width: '100%' } }
                          placeholder={ t("Select a City") }
                          optionFilterProp="children"
                          value={ parameters.address?.index ?? null }
                          onChange={ handleSelect }
                          onSelect={ () => select?.current?.blur() }
                          filterOption={ (input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          allowClear
                          onClear={ () => handleInputChange('address', null) }
                        >
                            { locations.map(({ name_ar, name_en }, index) => (
                                <Select.Option key={ index } value={ index }>
                                    { isRtl ? name_ar : name_en?.replace('\\&amp;#039;', "'") }
                                </Select.Option>
                            )) }
                        </Select>
                    </Col>
                    { isMobile && (
                        <Col className="gutter-row" xs={ 22 } sm={ 22 } md={ 22 } lg={ 22 } style={ { marginBlockStart: 5 } }>
                            <h2>{ t("Property location") }</h2>
                        </Col>
                    ) }
                    <Col className="gutter-row" xs={ 22 } sm={ 11 } md={ 11 } lg={ 8 }>
                        <Slider
                          className='Antd-Slider'
                          styles= { {
                                track: {
                                    backgroundColor: 'var(--baytup-orange)'
                                }
                            } }
                          style={ { width: '95%', marginBlock: 0, height: '32px' } }
                          marks={ marks }
                          defaultValue={ parameters.range ?? 50 }
                          onAfterChange={ (value) => handleInputChange('range', value) }
                          reverse={ isRtl }
                          tooltip={ { open: true, formatter: (value) => `${value} ${isRtl ? 'كم' : 'Km'}`, builtinPlacements: { top: { points: ['b', 't'], offset: [0, -10] } } } }
                          step={ 5 }
                        />
                    </Col>
                    <Col className="gutter-row" xs={ 20 } sm={ 11 } md={ 11 } lg={ 8 }>
                        <Select
                          placeholder={ t("Seats") }
                          size="medium"
                          value={ parameters.seats ?? null }
                          onChange={ (value) => handleInputChange('seats', value) }
                          style={ { width: '100%' } }
                          allowClear
                          options={ seats }
                        />
                    </Col>
                    { isMobile && (
                        <Col className="gutter-row" xs={ 22 } sm={ 22 } md={ 22 } lg={ 22 }>
                            <h2>{ t("Prices") }</h2>
                        </Col>
                    ) }
                    <Col className="gutter-row" xs={ 22 } sm={ 11 } md={ 11 } lg={ 8 }>
                        <InputNumber
                          placeholder={ t("Min Price") }
                          type="number"
                          min={ 0 }
                          value={ parameters.min_price ?? null }
                          style={ { width: '100%' } }
                          onChange={ (value) => handleInputChange('min_price', value) }
                          addonAfter={ t("DZD") }
                        />
                    </Col>
                    <Col className="gutter-row" xs={ 22 } sm={ 11 } md={ 11 } lg={ 8 }>
                        <InputNumber
                          placeholder={ t("Max Price") }
                          type="number"
                          min={ 0 }
                          style={ { width: '100%' } }
                          value={ parameters.max_price ?? null }
                          onChange={ (value) => handleInputChange('max_price', value) }
                          onBlur={ () => handleInputChange('max_price', parameters.max_price >= parameters.min_price ? parameters.max_price : null) }
                          addonAfter={ t("DZD") }
                        />
                    </Col>
                    { !isMobile && (
                        <Col className="gutter-row" xs={ 22 } sm={ 16 } md={ 11 } lg={ 6 }>
                            <button
                              type="submit"
                              className="Search-Buttons-Submit"
                              onClick={ searchCars }
                            >
                                { t("Filter") }
                            </button>
                        </Col>
                    ) }
                    { isMobile && (
                        <Col className="gutter-row" xs={ 22 } sm={ 22 } md={ 22 } lg={ 22 }>
                            <h2>{ t("Seats") }</h2>
                        </Col>
                    ) }
                    { isMobile && (
                        <Col className="gutter-row" xs={ 22 } sm={ 22 } md={ 22 } lg={ 22 }>
                            <h2>{ t("Options") }</h2>
                        </Col>
                    ) }
                    <Col className="gutter-row" xs={ 22 } sm={ 18 } md={ 18 } lg={ 11 }>
                        <Select
                          mode="multiple"
                          allowClear
                          placeholder={ t("Please select Options") }
                          maxTagCount={ 0 }
                          maxTagPlaceholder={ () => 'Selected' }
                          value={ parameters.options ?? [] }
                          onChange={ (value) => handleInputChange('options', value) }
                          options={ options }
                          style={ { height: 'fit-content', width: '100%' } }
                        />
                    </Col>
                    { !isMobile && (
                        <Col className="gutter-row" xs={ 22 } sm={ 11 } md={ 11 } lg={ 6 } >
                            <button
                              type="submit"
                              className="Search-Buttons-Submit"
                              onClick={ clearFilters }
                            >
                                { t("Clear filters") }
                            </button>
                        </Col>
                    ) }
                </Row>
                <Col className="gutter-row" xs={ 24 } sm={ 24 } md={ 24 } lg={ 24 }>
                    <div className='HorizontalDivider' />
                </Col>
                { isMobile && (<Row gutter={ 16 } justify="center" className="FirstFilters" style={ { marginTop: 20 } }>
                    <Col className="gutter-row" xs={ 22 } sm={ 16 } md={ 11 } lg={ 6 }>
                        <button
                          className="Search-Buttons-Submit"
                          onClick={ () => { setShowModal(false); searchCars(); } }
                        >
                            { t("Filter") }
                        </button>
                    </Col>
                    <Col className="gutter-row" xs={ 22 } sm={ 11 } md={ 11 } lg={ 6 } >
                        <button
                          className="Search-Buttons-Submit"
                          onClick={ clearFilters }
                        >
                            { t("Clear filters") }
                        </button>
                    </Col>
                </Row>) }
            </div>
            { !isMobile && (
                <div className="Filters">
                    <div className="DisplayFitlers">
                        <Select
                          size="large"
                          value={ parameters.sort ?? "-newest" }
                          style={ { width: 145 } }
                          onChange={ (value) => handleInputChange('sort', value) }
                          options={ orderByFilters }
                        />
                        <AButton
                          type={ filters.grid ? 'primary' : 'default' }
                          icon={ <DatabaseOutlined/> }
                          size="large"
                          onClick={ () => onHandleFiltersChange('grid', false) }
                        />
                        <AButton
                          type={ !filters.grid ? 'primary' : 'default' }
                          icon={ <AppstoreAddOutlined/> }
                          size="large"
                          onClick={ () => onHandleFiltersChange('grid', true) }
                        />
                        <AButton
                          type={ filters.map ? 'primary' : 'default' }
                          icon={ <OrderedListOutlined/> }
                          size="large"
                          onClick={ () => onHandleFiltersChange('map', false) }
                        />
                        <AButton
                          type={ !filters.map ? 'primary' : 'default' }
                          icon={ <EnvironmentOutlined/> }
                          size="large"
                          onClick={ () => onHandleFiltersChange('map', true) }
                        />
                    </div>
                </div>
            ) }
        </>
    );
}