import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CAR_CREATION_REQUEST } from '../config';
import axios from '../api/axios';
import { useSelector } from 'react-redux';
import ImagesUpload from '../components/ImagesUpload';

export default function CarPage() {
    const [ queryParameters ] = useSearchParams();
    const uploadStatus = useSelector((state) => state.car.uploadStatus);
    const [ car, setCar ] = useState({ error: '' });
    const { id: stateId, images, error } = car;

    const id = queryParameters.get('id');

    const getCar = async () => {
        try {
            const response = await axios.get(
                `${CAR_CREATION_REQUEST}/${id}`,
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true,
                }
            );

            setCar((prevState) => ({ ...prevState, ...response.data.data, error: '', isLoading: false }));
        } catch (err) {
            if (err?.response?.data) {
                setCar((prevState) => ({ ...prevState, error: err.response.data.message, isLoading: false }));
            } else {
                setCar((prevState) => ({ ...prevState, error: 'failed', isLoading: false }));
            }
        }
    };

    useEffect(() => {
        getCar();
    }, [uploadStatus]);

    if (error || !stateId) {
        return (
            <div>
                { !error ? 'please wait' : error }
            </div>
        );
    }

    return (
        <div>
            <div>this is only to view images, do not upload</div>
            <ImagesUpload id={ id } images={ images?.map((image) => image.path) } />
        </div>
    );
}
