import { React } from "react";
import HouseCard from "../Exclusives/HouseCard";


export default function GridList({ items, setHoveredId = () => {} }) {
    return (
        <div className="GridList">
            {
                items?.map((props, index) => (
                    <HouseCard key={ index } id={ props.id } setHoveredId={ setHoveredId } price={ props.price } location={ props.title } beds={ props.total_beds_number } baths={ props.baths } rooms={ props.bathrooms_number } image={ props.images[0]?.path } />
                ))
            }
        </div>
    );
}