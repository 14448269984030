import React, { useEffect, useRef, useState } from 'react';
import MessagesList from './MessagesList';
import HotelImage1 from '../../../../assets/hotel1.jpg';
import { Button, Switch } from 'antd';
import { BulbOutlined, DatabaseOutlined, EllipsisOutlined, HomeOutlined, LikeOutlined, StarFilled } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getOffers } from '../../../../state/offer/offerSlice';
import { useTranslation } from 'react-i18next';
import MessagesMobile from './MessagesMobile';
import { getMessages } from '../../../../state/auth/authSlice';
import { useNavigate } from 'react-router-dom';

export default function MessagesPage() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isMobile = useSelector((state) => state.locale.isMobile);
    const messagesData = useSelector((state) => state.auth.messages);
    const people = Object.values((messagesData || [])?.reduce((acc, element) => {
        const { id, property, user, message, created_at } = element;
  
        const options = { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false };
        const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(new Date(created_at));
      
        const dayMonthYear = formattedDate.split(' ').slice(0, 3).join('.');
        const hourMinute = formattedDate.split(' ').slice(3).join(':');
      
        const finalFormattedDate = `${dayMonthYear.replace(',', '')} ${hourMinute}`;

        if (!acc[property.id]) {
            acc[property.id] = {
                id: property.id,
                property: property,
                name: user.full_name,
                messages: [],
                age: 30
            };
        }

        acc[property.id].messages.push({ name: user.full_name, message, icon: null, date: finalFormattedDate, id });

        return acc;
    }, {}));
    // const people = [
    //     { id: 1, name: 'Jane Smith', messages: [
    //         { name: 'Jane Smith', message: 'Hello, my name is Jane Smith, and I am a software engineer.', icon: null, date: '18.Dec.2022 12:50' },
    //         { name: 'John Doe', message: 'Hello, my name is John Doe, and I am a software engineer.', icon: null, date: '18.Dec.2022 12:53' },
    //         { name: 'Jane Smith', message: 'Hello, my name is Jane Smith, and I am a software engineer.', icon: null, date: '18.Dec.2022 12:55' },
    //         { name: 'John Doe', message: 'Hello, my name is John Doe, and I am a software engineer.', icon: null, date: '18.Dec.2022 13:50' },
    //         { name: 'John Doe', message: 'Hello, my name is John Doe, and I am a software engineer.', icon: null, date: '18.Dec.2022 14:50' },
    //         { name: 'Jane Smith', message: 'Hello, my name is Jane Smith, and I am a software engineer.', icon: null, date: '19.Dec.2022 12:50' },
    //         { name: 'John Doe', message: 'Hello, my name is John Doe, and I am a software engineer.', icon: null, date: '19.Dec.2022 12:53' },
    //         { name: 'Jane Smith', message: 'Hello, my name is Jane Smith, and I am a software engineer.', icon: null, date: '19.Dec.2022 12:55' },
    //         { name: 'John Doe', message: 'Hello, my name is John Doe, and I am a software engineer.', icon: null, date: '20.Dec.2022 13:50' },
    //         { name: 'John Doe', message: 'Hello, my name is John Doe, and I am a software engineer.', icon: null, date: '20.Dec.2022 14:50' },
    //     ], age: 30 },
    //     { id: 2, name: 'John Doe', messages: [{ name: 'Jane Smith', message: 'Hello, my name is Jane Smith.', icon: null, date: '18.Dec.2022 12:50' }], age: 25 },
    //     { id: 3, name: 'John Doe', messages: [{ name: 'Jane Smith', message: 'Hello, my name is Jane Smith.', icon: null, date: '18.Dec.2022 12:50' }], age: 25 },
    //     { id: 4, name: 'John Doe', messages: [{ name: 'Jane Smith', message: 'Hello, my name is Jane Smith.', icon: null, date: '18.Dec.2022 12:50' }], age: 25 },
    //     { id: 5, name: 'John Doe', messages: [{ name: 'Jane Smith', message: 'Hello, my name is Jane Smith.', icon: null, date: '18.Dec.2022 12:50' }], age: 25 },
    //     { id: 6, name: 'John Doe', messages: [{ name: 'Jane Smith', message: 'Hello, my name is Jane Smith.', icon: null, date: '18.Dec.2022 12:50' }], age: 25 },
    //     { id: 7, name: 'John Doe', messages: [{ name: 'Jane Smith', message: 'Hello, my name is Jane Smith.', icon: null, date: '18.Dec.2022 12:50' }], age: 25 },
    //     // Add more people as needed
    // ];
    const conversations = useRef(null);
    const [selectedPerson, setSelectedPerson] = useState(people[0]);
    const [isSwitched, setIsSwitched] = useState(false);
    const offer = useSelector((state) => state.offer.offers[0]) || {};
    const isRtl = useSelector((state) => state.locale.language) === 'ar';

    const handlePersonClick = (person) => {
        setSelectedPerson(person);
    };

    useEffect(() => {
        if (conversations?.current && conversations.current.scrollHeight > 500) {
            conversations.current.className = 'Section People People-Scrollable';
        }
    }, [conversations]);

    useEffect(() => {
        dispatch(getMessages());

        if (!offer?.id) {
            dispatch(getOffers());
        }
    }, []);

    if (isMobile) {
        return (
            <div className="FirstPage Messages">
                <h1 style={ { marginBlockEnd: 5 } }>{ t("My messages") }</h1>
                <h2 style={ { textAlign: 'center', marginBlockEnd: 20, fontSize: '1.25rem' } }>{ t("Host Messages") } <Switch checked={ isSwitched } onClick={ () => setIsSwitched(!isSwitched) } style={ { backgroundColor: isSwitched ? 'var(--baytup-orange)' : 'black' } } /> { t("Client Messages") }</h2>
                <MessagesMobile />
            </div>
        );
    }

    return (
        <div className="FirstPage Messages">
            <h1 style={ { marginBlockEnd: 5 } }>{ t("My messages") }</h1>
            <h2 style={ { textAlign: 'center', marginBlockEnd: 20, ...(isMobile ? { fontSize: '1.25rem' } : {}) } }>{ t("Host Messages") } <Switch checked={ isSwitched } onClick={ () => setIsSwitched(!isSwitched) } style={ { backgroundColor: isSwitched ? 'var(--baytup-orange)' : 'black' } } /> { t("Client Messages") }</h2>
            <div className="MessagingPage">
                <div ref={ conversations } className="Section People">
                    { people?.length > 0 ?
                        <ul>
                            { people.map((person) => (
                                <li key={ person.id } onClick={ () => handlePersonClick(person) }>
                                    <img src={ HotelImage1 } alt={ person.name } />
                                    <div className='Section-Details'>
                                        <div className='Section-Details-Offer'>
                                            <p>{ t("Offer") }: </p><span>{ offer.title }</span>
                                        </div>
                                        <div className='Section-Details-Name'>
                                            <p>{ t("Client") }: </p><span>{ person.name }</span>
                                        </div>
                                    </div>
                                </li>
                            )) }
                        </ul>
                        :
                        <p>{ t("You have no conversations") }.</p>
                    }
                </div>
                <div className="Section Messages">
                    <div className='Reminder'>
                        <span>{ t("Reminder: Hassan B accepted you request") }.</span>
                        <span>{ t("Please proceed to the payment page after confirming the dates") }.</span>
                        <Button className='Navbar-ListingButton' onClick={ () => navigate('/payment') }>
                            { t("Proceed to payment") }
                        </Button>
                    </div>
                    { selectedPerson?.messages?.length > 0 ? <MessagesList id={ selectedPerson.id } data={ selectedPerson.messages } /> : <h2>{ t("Please select a person first") }</h2> }
                </div>
                <div className="Section Details">
                    { selectedPerson ? (
                        <>
                            <div className='Details-Rectangle'>
                                <div className="SelectedDetails">
                                    <div className='Avatar'>{ `${(selectedPerson.name.split(' ')[0] || '.').charAt(0)}${(selectedPerson.name.split(' ')[1] || '.').charAt(0)}` }</div>
                                    <div className='Details-Content'>
                                        <div className='Name'>{ selectedPerson.name }<EllipsisOutlined style={ { transform: 'rotate(90deg)' } } /></div>
                                        <div className='Stars'>
                                            <StarFilled style={ { width: 10, color: 'var(--color-of-button)' } } />
                                            <StarFilled style={ { width: 10, color: 'var(--color-of-button)' } } />
                                            <StarFilled style={ { width: 10, color: 'var(--color-of-button)' } } />
                                            <StarFilled style={ { width: 10, color: 'var(--color-of-button)' } } />
                                            <StarFilled style={ { width: 10, color: 'var(--color-of-button)' } } />
                                            1 { t("review") }
                                        </div>
                                    </div>
                                </div>
                                <div className='Details-Note'><BulbOutlined /> { t("Usually responds in 30 minutes") }</div>
                            </div>
                            <div className='Details-Rectangle'>
                                <div className="SelectedDetails">
                                    <img src={ HotelImage1 } alt={ selectedPerson.name } />
                                    <div className='Details-Content'>
                                        <div className='Details-Content-Offer'>{ offer.title }</div>
                                    </div>
                                </div>
                            </div>
                            <div className='Details-Rectangle'>
                                <div className="SelectedDescription">
                                    <h3>{ t("Description") }</h3>
                                    <div className='Description-Text'>
                                        { offer.description }
                                    </div>
                                    <a href='#'>{ t("See more") }</a>
                                </div>
                            </div>
                            { !isMobile && (
                                <div className='Details-Rectangle'>
                                    <div className="SelectedCriteria">
                                        <h3>{ t("Criteria") }</h3>
                                        <div className='Criteria'>
                                            <LikeOutlined />
                                            <div className='Criteria-Data'>
                                                <p>{ t("Place") }</p>
                                                <p>{ offer.address?.address }</p>
                                            </div>
                                        </div>
                                        <div className='Criteria'>
                                            <HomeOutlined />
                                            <div className='Criteria-Data'>
                                                <p>{ t("Type") }</p>
                                                <p>{ isRtl ? offer.type?.name_ar : offer.type?.name_en }</p>
                                            </div>
                                        </div>
                                        <div className='Criteria'>
                                            <DatabaseOutlined />
                                            <div className='Criteria-Data'>
                                                <p>{ t("Number of chambers") }</p>
                                                <p>{ offer.rooms_number }</p>
                                            </div>
                                        </div>
                                        <a href='#'>{ t("See more") }</a>
                                    </div>
                                </div>
                            ) }
                        </>
                    ) : (
                        <p>{ t("No person selected") }</p>
                    ) }
                </div>
            </div>
        </div>
    );
}