/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Houses from "../../icons/Houses";
import { Translator } from "../../i18n";
import CarIcon from "../../icons/CarIcon";

export default function Tabs({ setTab = () => {}, isSearchArea = false, isCarArea = false }) {
    const [currentTab, setCurrentTab] = useState(isCarArea ? 'Cars' : 'Houses');

    useEffect(() => {
        setTab(currentTab);
    }, [currentTab]);

    return (
        <div className="Search-Tabs">
            { !isCarArea && <div className="Search-Tab" data-is-selected={ currentTab === 'Houses' } onClick={ () => setCurrentTab('Houses') }>
                <Houses isSelected={ currentTab === 'Houses' } />
                <p><Translator text='Houses' /></p>
            </div> }
            { /* { (!isSearchArea || isCarArea) && (
                <div className="Search-Tab" data-is-selected={ currentTab === 'Cars' } onClick={ () => setCurrentTab('Cars') }>
                    <CarIcon isSelected={ currentTab === 'Cars' } />
                    <p><Translator text='Cars' /></p>
                </div>
            ) } */ }
        </div>
    );
}