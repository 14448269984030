import React, { useEffect } from 'react';
import TESTAPP from '../components/Drag&Drop/SortableImagesDrag';
import { useDispatch, useSelector } from 'react-redux';
import { getOffer, setLocalOfferImages } from '../state/offer/offerSlice';

export default function Test() {
    const dispatch = useDispatch();
    const images = useSelector((state) => state.offer.offer?.images) || [];
    const setItems = (list) => {
        dispatch(setLocalOfferImages(list));
    };

    useEffect(() => {
        dispatch(getOffer({ id: 19 }));
    }, []);

    return (
        <TESTAPP images={ images } setImages={ setItems } />
    );
}