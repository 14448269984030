import React from 'react';

export default function ArrowUp() {
    const style = {
        stroke: 'none',
        strokeWidth: 1,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        strokeMiterlimit: 10,
        fill: 'black',
        fillRule: 'nonzero',
        opacity: 1,
    };

    return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
          width="20"
          height="20"
          viewBox="0 0 256 256"
          xmlSpace="preserve"
        >
            <g
              style={ {
                    stroke: 'none',
                    strokeWidth: 0,
                    strokeDasharray: 'none',
                    strokeLinecap: 'butt',
                    strokeLinejoin: 'miter',
                    strokeMiterlimit: 10,
                    fill: 'none',
                    fillRule: 'nonzero',
                    opacity: 1,
                } }
              transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
            >
                <circle
                  style={ { ...style, fill: 'grey' } }
                  cx="45"
                  cy="45"
                  r="45"
                  transform="  matrix(1 0 0 1 0 0) "
                />
                <path
                  style={ style }
                  d="M 45.108 12.262 c 3.59 0 6.5 2.91 6.5 6.5 l 0 52.475 c 0 3.59 -2.91 6.5 -6.5 6.5 s -6.5 -2.91 -6.5 -6.5 l 0 -52.475 C 38.608 15.172 41.518 12.262 45.108 12.262 z"
                  transform=" matrix(1 0 0 1 0 0) "
                  strokeLinecap="round"
                />
                <path
                  style={ style }
                  d="M 45 12.262 c 1.664 0 3.327 0.635 4.596 1.903 l 19.2 19.199 c 2.539 2.539 2.539 6.654 0 9.192 c -2.538 2.539 -6.654 2.538 -9.192 0.001 L 45 27.955 L 30.397 42.558 c -2.539 2.537 -6.654 2.537 -9.193 0 c -2.538 -2.539 -2.538 -6.654 0 -9.193 l 19.2 -19.199 C 41.672 12.897 43.337 12.262 45 12.262 z"
                  transform=" matrix(1 0 0 1 0 0) "
                  strokeLinecap="round"
                />
            </g>
        </svg>
    );
}
