import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../api/axios";
import { CAR_CREATION_REQUEST } from "../../config";
import removeNullProperties from "../../util/removeNullProperties";

const initialInputsErrors = {
    type: null,
    model: null,
    year: null,
    km: null,
    fuel: null,
    seat_number: null,
    date: null,
    address: null,
    livraison: null,
    is_well_maintained: null,
    images: null,
};

const initialInputs = {
    price: null,
    type: null,
    model: null,
    production_year: null,
    mileage: null,
    energy_source: null,
    gear_box_type: null,
    seat_number: null,
    options: [],
    address: null,
    wilaya: null,
    daira: null,
    commune: null,
    address_latitude: null,
    address_longitude: null,
    offers_car_delivery: 0,
    is_well_maintained: null,
    discount_days: null,
    discount_value: null,
    images: null,
};

const initialState = {
    car: {},
    userCars: [],
    carSelectionData: {
        carsTypes: [],
        carSeats: [],
        carsModels: [],
        carsManufact: [],
        carsEnergy: []
    },
    inputs: { ...initialInputs },
    phone: null,
    identificationImage: null,
    getCarStatus: null,
    requestStatus: null,
    requestPendingStatus: null,
    requestPendingError: null,
    uploadStatus: null,
    switchStatus: null,
    ownerCarsStatus: null,
    deleteCarStatus: null,
    updateCarStatus: null,
    isLoading: false,
    error: null,
    inputsErrors: { ...initialInputsErrors },
    isValidated: false
};

const CarSlice = createSlice({
    name: 'Car',
    initialState,
    reducers: {
        setDetails: (state, action) => {
            state.inputs.type = action.payload.brand;
            state.inputs.model = action.payload.model;
            state.inputs.production_year = action.payload.year;
            state.inputs.mileage = action.payload.km;
            state.inputsErrors.type = null;
            state.inputsErrors.model = null;
            state.inputsErrors.production_year = null;
            state.inputsErrors.mileage = null;
        },
        setInfo: (state, action) => {
            state.inputs.energy_source = action.payload.fuel;
            state.inputs.gear_box_type = action.payload.gearbox;
            state.inputsErrors.energy_source = null;
            state.inputsErrors.gear_box_type = null;
        },
        setMoreInfo: (state, action) => {
            state.inputs.seat_number = action.payload.seat_number;
            state.inputs.offers_car_delivery = action.payload.delivery;
            state.inputs.is_well_maintained = action.payload.maintained;
            state.inputsErrors.seat_number = null;
            state.inputsErrors.offers_car_delivery = null;
            state.inputsErrors.is_well_maintained = null;
        },
        setOptions: (state, action) => {
            state.inputs.options = action.payload;
            state.inputsErrors.options = null;
        },
        setDate: (state, action) => { state.date = action.payload; },
        setAddress: (state, action) => {
            state.inputs.address = action.payload.address;
            state.inputs.wilaya = action.payload.wilaya;
            state.inputs.daira = action.payload.daira;
            state.inputs.commune = action.payload.commune;
            state.inputs.address_latitude = `${action.payload.lat}`;
            state.inputs.address_longitude = `${action.payload.lng}`;
            state.inputsErrors.address = null;
            state.inputsErrors.wilaya = null;
            state.inputsErrors.daira = null;
            state.inputsErrors.commune = null;
            state.inputsErrors.address_latitude = null;
            state.inputsErrors.address_longitude = null;
        },
        setCarPrice: (state, action) => {
            state.inputs.price = action.payload.price;
            state.inputs.discount_days = action.payload.discount_days;
            state.inputs.discount_value = action.payload.discount_value;
            state.inputsErrors.price = null;
            state.inputsErrors.discount_days = null;
            state.inputsErrors.discount_value = null;
        },
        setImages: (state, action) => {
            state.inputs.images = action.payload;
            state.inputsErrors.images = null;
        },
        setLocalCarImages: (state, action) => {
            state.car.images = [...action.payload];
        },
        setCarSelectionData: (state, action) => {
            state.carSelectionData = action.payload;
        },
        setCarEdit: (state, action) => {
            state.inputs.id = action.payload.id;
            state.inputs.status = action.payload.status?.id;
            state.inputs.is_visible = action.payload.is_visible;
            state.inputs.type = action.payload.type?.id;
            state.inputs.model = action.payload.model?.id;
            state.inputs.production_year = action.payload.production_year;
            state.inputs.mileage = action.payload.mileage;
            state.inputs.energy_source = action.payload.energy_source?.id;
            state.inputs.gear_box_type = action.payload.gear_box?.id;
            state.inputs.is_well_maintained = action.payload.is_well_maintained;
            state.inputs.offers_car_delivery = action.payload.offers_car_delivery;
            state.inputs.seat_number = action.payload.seats_number?.id;
            state.inputs.price = action.payload.price;
            state.inputs.discount_days = action.payload.discount_days;
            state.inputs.discount_value = action.payload.discount_value;
            state.inputs.images = action.payload.images;
            state.inputs.options = action.payload.options;
            state.inputs.address = action.payload.address?.meeting_address;
            state.inputs.address_latitude = action.payload.address.address_latitude;
            state.inputs.address_longitude = action.payload.address.address_longitude;
        },
        validateInputs: (state) => {
            // mimicking the backend error to follow the same structure
            const errorMsg = ['Please fill all the fields.'];
            const inputsErrors = {};
            inputsErrors.address = state.inputs.address || state.inputs.address_latitude || state.inputs.address_longitude ? null : errorMsg;
            inputsErrors.type = state.inputs.type && state.inputs.model && state.inputs.mileage && state.inputs.production_year ? null : errorMsg;
            inputsErrors.energy_source = state.inputs.energy_source || state.inputs.gear_box_type ? null : errorMsg;
            inputsErrors.is_well_maintained = state.inputs.is_well_maintained || state.inputs.offers_car_delivery || state.inputs.seat_number ? null : errorMsg;
            inputsErrors.price = state.inputs.price || state.inputs.discount_days || state.inputs.discount_value ? null : errorMsg;
            inputsErrors.options = state.inputs.options ? null : ['Please choose at least 1 option.'];
            inputsErrors.images = state.inputs.images ? null : ['Please choose at least 3 images, and no more than 14 images.'];
            state.requestStatus = null;
            state.error = null;

            if (Object.values(inputsErrors).filter((value) => value !== null).length === 0) {
                state.inputsErrors = { ...initialInputsErrors };
                state.isValidated = true;
            } else {
                state.inputsErrors = { ...inputsErrors };
            }
        },
        resetStatus: (state) => {
            state.updateOfferStatus = null;
        },
        resetCarRequest: (state) => {
            state.isLoading = false;
            state.requestStatus = null;
            state.requestPendingStatus = null;
            state.uploadStatus = null;
            state.isValidated = false;
            state.requestPendingError = null;
            state.error = null;
            state.inputs = { ...initialInputs };
            state.inputsErrors = { ...initialInputsErrors };
        }
    },
    extraReducers: (builder) => {
        builder
        // ******************************* START BUSINESS REQUESTS *******************************
        // theses requests happen after the user enters data in the form, should only happen after components are fully loaded
        // requestCarCreation cases
        .addCase(requestCarCreation.pending, (state) => {
            state.requestStatus = 'pending';
            state.error = null;
            state.isValidated = false;
        })
        .addCase(requestCarCreation.fulfilled, (state, action) => {
            state.isValidated = false;
            state.isLoading = false;
            state.error = null;
            state.requestStatus = 'success';

            if (!state.car?.id) {
                state.car = action.payload.data.data;
            }
        })
        .addCase(requestCarCreation.rejected, (state, action) => {
            state.requestStatus = 'error';
            state.error = action.payload?.message || 'Error, please try again later';
            state.inputsErrors = action.payload?.errors || { ...initialInputsErrors };
        })
        // requestCarPending cases
        .addCase(requestCarPending.pending, (state) => {
            state.requestPendingStatus = 'pending';
            state.requestPendingError = null;
        })
        .addCase(requestCarPending.fulfilled, (state) => {
            state.requestPendingStatus = 'success';
            state.inputsErrors = { ...initialInputsErrors };
        })
        .addCase(requestCarPending.rejected, (state, action) => {
            state.requestPendingStatus = 'error';
            state.requestPendingError = action.payload?.data?.errors ? null : "Something went wrong. Please try again later.";
            state.inputsErrors = action.payload?.data?.errors || { ...initialInputsErrors };
        })
        // uploadImages cases
        .addCase(uploadImages.pending, (state) => {
            state.uploadStatus = 'pending';
            state.error = null;
        })
        .addCase(uploadImages.fulfilled, (state, action) => {
            state.uploadStatus = 'success';
            state.car.images = state.car.images?.length > 0 ? [...state.car.images, ...action.payload.data.data] : action.payload.data.data;
            state.inputsErrors.images = null;
        })
        .addCase(uploadImages.rejected, (state) => {
            state.uploadStatus = 'error';
            // using an array to simulate the errors we receive from backend
            state.inputsErrors.images = ['Images are required, make sure to provide minimum of 3 and maximum of 14 images'];
            state.error = "Something went wrong. Please try again later.";
        })
        // removeUploadedImage cases
        .addCase(removeUploadedImage.pending, (state) => {
            state.uploadStatus = 'pending';
            state.error = null;
        })
        .addCase(removeUploadedImage.fulfilled, (state) => {
            state.uploadStatus = 'success';
        })
        .addCase(removeUploadedImage.rejected, (state, action) => {
            state.uploadStatus = 'error';
            state.error = action.error;
        })
        // ******************************* END BUSINESS REQUESTS *******************************
        // ******************************* START FETCH REQUESTS *******************************
        // theses requests can be used anywhere and whenever, they fetch data to display
        // getCar cases
        .addCase(getCar.pending, (state) => {
            state.getCarStatus = 'pending';
            state.error = null;
        })
        .addCase(getCar.fulfilled, (state, action) => {
            state.getCarStatus = 'success';
            state.car = action.payload.data.data;
        })
        .addCase(getCar.rejected, (state, action) => {
            state.getCarStatus = 'error';
            state.error = action.error;
        })
        //getCarsCases
        .addCase(getOwnerCars.pending, (state) => {
            state.ownerCarsStatus = 'pending';
        })
        .addCase(getOwnerCars.fulfilled, (state, action) => {
            state.userCars = [...action.payload.data.data];
            state.ownerCarsStatus = 'success';
            state.error = null;
        })
        .addCase(getOwnerCars.rejected, (state) => {
            state.userCars = [];
            state.ownerCarsStatus = 'error';
            state.error = "Something went wrong. Please try again later.";
        })
        // updateStatus
        .addCase(updateStatus.pending, (state) => {
            state.updateCarStatus = 'pending';
        })
        .addCase(updateStatus.fulfilled, (state) => {
            state.updateCarStatus = 'success';
        })
        .addCase(updateStatus.rejected, (state) => {
            state.updateCarStatus = 'error';
        })
        // deleteCar
        .addCase(deleteCar.pending, (state) => {
            state.deleteCarStatus = 'pending';
        })
        .addCase(deleteCar.fulfilled, (state) => {
            state.deleteCarStatus = 'success';
        })
        .addCase(deleteCar.rejected, (state) => {
            state.deleteCarStatus = 'error';  
        });
        // ******************************* START FETCH REQUESTS *******************************
    }
});

export const deleteCar = createAsyncThunk(
    'deleteCar',
    async ({ id }) => {
        const response = await axios.delete(`${CAR_CREATION_REQUEST}/${id}`, {
            withCredentials: true,
        });

        return response;
    }
);

export const updateStatus = createAsyncThunk(
    'updateStatus',
    async ({ id }) => {
        const response = await axios.put(
            `/api/v1/owner/cars/${id}/toggle_visibility`,
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true,
            }
        );

        return response;
    }
);

export const requestCarPending = createAsyncThunk(
    'requestCarPending',
    async ({ id }, { rejectWithValue }) => {
        const response = await axios.put(
            `${CAR_CREATION_REQUEST}/${id}/mark_as_pending`,
            { withCredentials: true }
        ).catch((err) => {
            if (!err?.response) {
                return rejectWithValue('No Server Response');
            } else if (err?.response?.data) {
                return rejectWithValue(err.response.data);
            }
        });

        return response;
    }
);

export const requestCarCreation = createAsyncThunk(
    'requestCarCreation',
    async ({ withPending = false }, { getState, rejectWithValue, dispatch }) => {
        // eslint-disable-next-line no-unused-vars
        const { images, ...rest } = getState().car.inputs;
        const id = getState().car.car?.id;
        const props = removeNullProperties(rest);

        const response = getState().car.car?.id ? await axios.put(
            CAR_CREATION_REQUEST + `/${id}`,
            {
                ...props
            },
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true,
            }
        ).then((res) => {
            if(withPending) {
                dispatch(requestCarPending({ id }));
            }

            return res;
        }).catch((err) => {
            if (!err?.response) {
                return rejectWithValue('No Server Response');
            } else if (err?.response?.data) {
                return rejectWithValue(err.response.data);
            }
        })
        : await axios.post(
            `${CAR_CREATION_REQUEST}`,
            {
                ...props
            },
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true,
            }
        ).then((res) => {
            if(withPending) {
                dispatch(requestCarPending({ id: res.data.data.id }));
            }

            return res;
        }).catch((err) => {
            if (!err?.response) {
                return rejectWithValue('No Server Response');
            } else if (err?.response?.data) {
                return rejectWithValue(err.response.data);
            }
        });

        return response;
    }
);

export const getOwnerCars = createAsyncThunk(
    'getOwnerCars',
    async () => {
        const url = `${CAR_CREATION_REQUEST}?include=address.commune,address.wilaya,images`;

        const response = await axios.get(url, {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
        });

        return response;
    }
);

export const uploadImages = createAsyncThunk(
    'uploadImages',
    async ({ selectedImages, id }, { getState, rejectWithValue }) => {
        const carId = id >= 0 ? id : getState().car.car.id;
        const images = selectedImages ?? getState().car.inputs.images;
        const response = await axios.post(
            `${CAR_CREATION_REQUEST}/${carId}/images`,
            {
                images
            },
            {
                headers: { 'Content-Type': 'multipart/form-data' },
                withCredentials: true,
            }
        ).catch((err) => {
            if (!err?.response) {
                return rejectWithValue('No Server Response');
            }

            return rejectWithValue(err.response?.data?.message);
        });

        return response;
    }
);

export const orderImages = createAsyncThunk(
    'orderImages',
    async (_, { getState }) => {
        const images = getState().car.car?.images.map((image, index) => {
            return {
                id: image.id,
                order: index + 1
            };
        });

        const response = await axios.put(
            `api/v1/owner/cars/reorder_images`,
            {
                images
            },
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true,
            }
        );

        return response;
    }
);

export const uploadIdentificationImage = createAsyncThunk(
    'uploadIdentificationImage',
    async (images, { rejectWithValue }) => {
        const response = await axios.post(
            `owner/properties/identity_documents`,
            {
                images
            },
            {
                headers: { 'Content-Type': 'multipart/form-data' },
                withCredentials: true,
            }
        ).catch((err) => {
            if (!err?.response) {
                return rejectWithValue('No Server Response');
            }

            return rejectWithValue(err.response);
        });

        return response;
    }
);

export const removeUploadedImage = createAsyncThunk(
    'removeUploadedImage',
    async (id) => {
        await axios.delete(
            `${CAR_CREATION_REQUEST}/images/${id}`,
            {
                headers: { 'Content-Type': 'multipart/form-data' },
                withCredentials: true,
            }
        );
    }
);

export const getCar = createAsyncThunk(
    'getCar',
    async ({ id, withEdit = false }, { dispatch }) => {
        const response = await axios.get(
            `${CAR_CREATION_REQUEST}/${id}`,
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true,
            }
        ).then((res) => {
            if (withEdit) {
                dispatch(setCarEdit(res.data.data));
            }

            return res;
        });

        return response;
    }
);

export const {
    setDetails,
    setInfo,
    setMoreInfo,
    setOptions,
    setImages,
    setDate,
    setAddress,
    setCarPrice,
    validateInputs,
    resetCarRequest,
    setCarSelectionData,
    setLocalCarImages,
    setCarEdit,
    resetStatus
} = CarSlice.actions;

export default CarSlice.reducer;
  