/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Translator } from "../../i18n";
import Input from "../Inputs/Input";
import ImageCapture from "./ImageCapture";
import { Button, Modal } from "antd";
import { requestCode, uploadIdentificationFiles, verifyPhone } from "../../state/auth/authSlice";
import { useTranslation } from "react-i18next";

export default function InfoValidation() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        verificationStatus,
        uploadStatus,
        codeStatus,
        error,
        user: {
            phone_number_verified_at,
            identity_verified_at,
            has_identity_documents
        }
    } = useSelector((state) => state.auth);

    // 0: not open, 1: phone, 2: documents
    const [open, showModal] = useState(0);
    const [phone, setPhone] = useState('');
    const [code, setCode] = useState(null);
    const [files, setFiles] = useState({});
    const stateMap = [setPhone, setCode];

    const handleCodeRequest = (e) => {
        e.preventDefault();

        if (phone) {
            dispatch(requestCode({ phone }));
        }
    };

    const handleVerification = (e) => {
        e.preventDefault();

        if (code) {
            dispatch(verifyPhone({ code }));
        }
    };

    const handleImageUpload = (e) => {
        e.preventDefault();

        if (files?.document && files?.image) {
            dispatch(uploadIdentificationFiles({ files }));
        }
    };

    const handleChange = (e, field) => {
        e.preventDefault();
        const { value } = e.target;

        if (value) {
            stateMap[field](value);
        }
    };

    useEffect(() => {
        if (uploadStatus === 'success' || verificationStatus === 'success') {
            open !== 0 && showModal(0);
        }
    }, [uploadStatus, codeStatus]);

    if (identity_verified_at && phone_number_verified_at) {
        return (
            <></>
        );
    }

    return (
        <div style={ { display: 'flex', flexWrap: 'wrap', gap: 20 } }>
            <div className="ValidationCard">
                <h3><Translator text="Phone number verification." /></h3>
                { phone_number_verified_at || verificationStatus === 'success'
                    ? <p style={ { color: 'green' } }><Translator text="Verified" /></p> 
                    : <p style={ { color: 'red' } }><Translator text="Required to publish offers." /></p>
                }
                { !phone_number_verified_at && verificationStatus !== 'success' && <Button style={ { width: 'fit-content' } } onClick={ () => showModal(1) }><Translator text="Verify Information" /></Button> }
            </div>
            <div className="ValidationCard">
                <h3><Translator text="Documents verification." /></h3>
                { identity_verified_at ? <p style={ { color: 'green' } }><Translator text="Verified" /></p> : 
                    (has_identity_documents
                        ? <p style={ { color: '#F5BA1D' } }><Translator text="Pending verification." /></p> 
                        : <p style={ { color: 'red' } }><Translator text="Required to publish offers." /></p>
                    )
                }
                { !identity_verified_at && <Button style={ { width: 'fit-content' } } onClick={ () => showModal(2) }><Translator text={ has_identity_documents ? "Replace documents" : "Verify Information" } /></Button> }
            </div>
            <Modal open={ open > 0 } onCancel={ () => showModal(0) } footer={ null }>
                { open === 1 && (
                    <div style={ { marginBlockStart: 20 } }>
                        { codeStatus === 'pending' || verificationStatus === 'pending' && <Translator text='loading...' /> }
                        <Input
                          type="text"
                          name="phone"
                          defaultStyle="standardInput"
                          placeholder={ t("Enter your Phone number") }
                          onChange={ (e) => handleChange(e, 0) }
                          required
                        />
                        { codeStatus === 'success' && (
                            <Input
                              type="text"
                              name="verification"
                              defaultStyle="standardInput"
                              placeholder={ t("Enter the verification code") }
                              onChange={ (e) => handleChange(e, 1) }
                              required
                            />
                        ) }
                        <Button
                          htmlType="button"
                          onClick={ code ? handleVerification : handleCodeRequest }
                          loading={ verificationStatus === 'pending' || codeStatus === 'pending' }
                        >
                            <Translator text={ code ? t("Verify your number") : t("Send Verification Code") } />
                        </Button>
                    </div>
                ) }
                { open === 2 && (
                    <>
                        <div style={ { display: 'flex', flexDirection: 'column', gap: 1 } }>
                            <ImageCapture provideImage={ (files) => setFiles(files) } />
                            { error?.message && <div>{ error.message }</div> }
                            { uploadStatus === 'pending' && <div style={ { textAlign: 'center' } }>...loading</div> }
                            <Button
                              type='primary'
                              onClick={ handleImageUpload }
                              style={ { width: 'fit-content', alignSelf: 'end' } }
                              disabled={ uploadStatus === 'pending' }
                            >
                                <Translator text="Save" />
                            </Button>
                        </div>
                        <div style={ { textAlign: 'center' } }>
                            <Translator text="the information is not disclosed and is used for security reasons only." />
                        </div>
                    </>
                ) }
            </Modal>
        </div>
    );
}