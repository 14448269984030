/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, Modal, Upload, message } from 'antd';
import Webcam from 'react-webcam';
import { CameraOutlined, UploadOutlined } from '@ant-design/icons';
import { Translator } from '../../i18n';
import fireCustomEvent from '../../util/fireCustomEvent';

export default function ImageCapture({ provideImage }) {
    const webcamRef = useRef(null);
    const [visible, setVisible] = useState(false);
    const [image, setImage] = useState(null);
    const [cameraPermission, setCameraPermission] = useState(false);

    const handleCapture = () => {
        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc) {
            setVisible(false);
            const blob = dataURItoBlob(imageSrc);
            const file = new File([blob], 'image.jpg');

            const imageObject = {
                name: file.name,
                type: file.type,
                size: file.size,
                file: file
            };

            setImage(imageObject.file);
        } else {
            fireCustomEvent('showNotification', 'error', 'No image captured');
        }
    };

    function dataURItoBlob(dataURI) {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    }

    const handleUpload = (uploadedImage) => {
        setImage(uploadedImage);
    };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
          onSuccess("ok");
        }, 0);
    };

    useEffect(() => { 
        provideImage({ document: image });
    }, [image]);
  
    useEffect(() => {
        const permissionStatusChangeHandler = (permissionStatus) => {
            setCameraPermission(permissionStatus.state === 'granted');
        };
        const checkCameraPermission = async () => {
            try {
                navigator.permissions.query({ name: 'camera' }).then((permissionStatus) => {
                    permissionStatusChangeHandler(permissionStatus);
                });
                const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            } catch (_) { /* empty */ }
        };

        checkCameraPermission();
        navigator.permissions.query({ name: 'camera' }).then((permissionStatus) => {
            permissionStatus.addEventListener('change', () => permissionStatusChangeHandler(permissionStatus));
        });
    }, []);

    return (
        <div className='ImageCapture'>
            <div style={ { display: 'flex', flexDirection: 'column', gap: 5 } }>
                <span style={ { fontWeight: 'bold' } }><Translator text="Please provide a picture of an identification document, make sure the face is clearly visible, and the information is readable" />:</span>
                { image && <img width={ 200 } height={ 200 } src={ URL.createObjectURL(image) } alt="User's chosen image" /> }
                <div style={ { display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: 5, marginBlockStart: 10 } }>
                    { cameraPermission && <><Button onClick={ () => setVisible(true) } icon={ <UploadOutlined /> } style={ { width: 'fit-content' } }> <Translator text="Capture Image" /></Button> <Translator text="OR" /></> }
                    <Upload customRequest={ dummyRequest } beforeUpload={ handleUpload } showUploadList={ false }>
                        <Button icon={ <UploadOutlined /> }> <Translator text="Choose from gallery" /></Button>
                    </Upload>
                </div>
                <Modal
                  open={ visible }
                  onCancel={ () => setVisible(false) }
                  onOk={ () => setVisible(false) }
                  okButtonProps={ { type: 'default' } }
                >
                    <Webcam
                      audio={ false }
                      ref={ webcamRef }
                      screenshotFormat="image/jpeg"
                      style={ { width: '100%', height: 'auto' } }
                    />
                    <div style={ { display: 'flex', justifyContent: 'space-between' } }>
                        <Button onClick={ handleCapture }>
                            <Translator text="Take Image" />
                        </Button>
                    </div>
                </Modal>
            </div>
        </div>
    );
};