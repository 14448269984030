import React, { useState } from 'react';
import { MenuOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { OWNER_DASHBOARD_URL } from '../App';
import { useLocation, useNavigate } from 'react-router-dom';
import FirstPage from '../components/Dashboard/Owner/FirstPage';
import CalendarsPage from '../components/Dashboard/Owner/CalendarsPage';
import OffersPage from '../components/Dashboard/Owner/OffersPage';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MessagesPage from '../components/Dashboard/Owner/Messages/MessagesPage';
import MobileMenu from '../components/Dashboard/Owner/MobileMenu';
import CarsPage from '../components/Dashboard/Owner/CarsPage';
import ProfilePage from '../components/Dashboard/Owner/ProfilePage';
import { RiUserStarLine } from "react-icons/ri";
import { MdOutlineWavingHand, MdOutlineMessage, MdOutlineAdminPanelSettings, MdHelpOutline } from "react-icons/md";
import { FaRegUserCircle, FaRegCalendarAlt, FaCar, FaSuitcase, FaRegStar } from "react-icons/fa";
import { FiGift } from "react-icons/fi";


function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

const Menuu = ({ menuItem }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isMobile = useSelector((state) => state.locale.isMobile);
    const [collapsed, setCollapsed] = useState(true);

    const items = [
        getItem(t('Owner'), 'sub1', <RiUserStarLine />, [
            getItem(t('Welcome'), 'welcome', <MdOutlineWavingHand />),
            getItem(t('Profile'), 'profile', <FaRegUserCircle />),
            getItem(t('Calendar'), 'calendar', <FaRegCalendarAlt />),
            getItem(t('Offers'), 'offers', <FiGift />),
            getItem(t('Cars'), 'cars', <FaCar />),
            getItem(t('Messages'), 'messages', <MdOutlineMessage />),
        ]),
        getItem(t('User'), 'sub2', <MdOutlineAdminPanelSettings />, [
            getItem(t('My trips'), 'welcome1', <FaSuitcase />),
            getItem(t('Favourite'), 'welcome2', <FaRegStar />),
            getItem(t('Help Center'), 'welcome3', <MdHelpOutline />),
            getItem(t('Messages'), 'welcome4', <MdOutlineMessage />),
        ]),
    ];

    const toggleCollapsed = (value) => {
        return () => setCollapsed(value);
    };

    const handleMenuClick = (e) => {
        if (e.key !== 'sub1' && e.key !== 'sub2') {
            navigate(OWNER_DASHBOARD_URL + "/" + e.key);
        }
    };

    if (isMobile) {
        return (
            <div className='MobileMenu'>
                <div className='Item' data-is-chosen={ menuItem === 'welcome' } onClick={ () => navigate(OWNER_DASHBOARD_URL + '/welcome') }>
                    <MdOutlineWavingHand />
                    <span>{ t('Welcome') }</span>
                </div>
                <div className='Item' data-is-chosen={ menuItem === 'calendar' } onClick={ () => navigate(OWNER_DASHBOARD_URL + '/calendar') }>
                    <FaRegCalendarAlt/>
                    <span>{ t('Calendar') }</span>
                </div>
                <div className='Item' data-is-chosen={ menuItem === 'offers' } onClick={ () => navigate(OWNER_DASHBOARD_URL + '/offers') }>
                    <FiGift />
                    <span>{ t('Offers') }</span>
                </div>
                <div className='Item' data-is-chosen={ menuItem === 'cars' } onClick={ () => navigate(OWNER_DASHBOARD_URL + '/cars') }>
                    <FaCar />
                    <span>{ t('Cars') }</span>
                </div>
                <div className='Item' data-is-chosen={ menuItem === 'messages' } onClick={ () => navigate(OWNER_DASHBOARD_URL + '/messages') }>
                    <MdOutlineMessage />
                    <span>{ t('Messages') }</span>
                </div>
                <div className='Item' data-is-chosen={ menuItem === 'menu' } onClick={ () => navigate(OWNER_DASHBOARD_URL + '/menu') }>
                    <MenuOutlined />
                    <span>{ t('Menu') }</span>
                </div>
            </div>
        );
    }

    return (
        <div className={ `Menu${ !collapsed || !isMobile ? ' Collapsed' : '' }` } onMouseEnter={ toggleCollapsed(false) } onMouseLeave={ toggleCollapsed(true) }>
            <Menu
              onClick={ handleMenuClick }
              defaultSelectedKeys={ [menuItem] }
              defaultOpenKeys={ ['sub1', 'sub2'] }
              mode="inline"
              inlineCollapsed={ collapsed && isMobile }
              items={ items }
            />
        </div>
    );
};

export default function OwnerDahboard() {
    const navigate = useLocation();
    const pathname = navigate.pathname.replace(OWNER_DASHBOARD_URL, '').replace('/', '');
    const menuItem = pathname !== '' ? pathname : 'welcome';
    const pages = {
        'welcome': <FirstPage />,
        'welcome1': <FirstPage />,
        'welcome2': <FirstPage />,
        'welcome3': <FirstPage />,
        'welcome4': <FirstPage />,
        'profile': <ProfilePage />,
        'calendar': <CalendarsPage />,
        'offers': <OffersPage />,
        'cars': <CarsPage />,
        'messages': <MessagesPage />,
        'menu': <MobileMenu />,
    };

    return (
        <div className='OwnerDashboard'>
            <Menuu menuItem={ menuItem } />
            { pages[menuItem] }
        </div>
    );
}
