import React from 'react';
import Logo from '../../assets/HomepageLogo.webp';
import LogoAR from '../../assets/logo-baytup-AR.webp';
import Facebook from '../../icons/Facebook';
import Instagram from '../../icons/Instagram';
import LinkedIn from '../../icons/LinkedIn';
import Apple from '../../assets/apple.png';
import Google from '../../assets/google.png';
import ArrowUp from '../../icons/ArrowUp';
import { Translator } from '../../i18n';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export default function Footer() {
    const language = useSelector((state) => state.locale.language);
    const onClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <div className="Footer">
            <div className="Footer-Content">
                <div className="Footer-Socials">
                    <div className="Logo">
                        <img src={ language === 'ar' ? LogoAR : Logo } />
                    </div>
                    <div className="Footer-Socials-Links">
                        <a href="#">
                            <LinkedIn />
                        </a>
                        <a href="#">
                            <Instagram />
                        </a>
                        <a href="#">
                            <Facebook />
                        </a>
                    </div>
                </div>
                <div className="Footer-Links">
                    <div className="Footer-Pages">
                        <a href="#"><Translator text='ABOUT US' /></a>
                        <div className="Footer-Divider" />
                        <a href="#"><Translator text='CAREERS' /></a>
                        <div className="Footer-Divider" />
                        <Link to="/contact-us"><Translator text='CONTACT US' /></Link>
                        <div className="Footer-Divider" />
                        <a href="#"><Translator text='TERMS & PRIVACY POLICY' /></a>
                    </div>
                    <div className="Footer-Downloads">
                        <a href="#">
                            <img src={ Apple } />
                        </a>
                        <a href="#">
                            <img src={ Google } />
                        </a>
                    </div>
                </div>
                <div className="Footer-Copyright">
                    <span>
                        <Translator text='All Rights and Policies are reserved, Baytup' /> { (new Date()).getFullYear() }
                    </span>
                    <button type="button" onClick={ onClick }>
                        <span><Translator text='TOP' /></span>
                        <ArrowUp />
                    </button>
                </div>
            </div>
        </div>
    );
}
