import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    popupData: null,
    isPopupVisible: false,
    dataIndex: 0
};

const PopupSlice = createSlice({
    name: 'Popup',
    initialState,
    reducers: {
        setPopupData: (state, action) => {
            state.popupData = action.payload.data;
            state.dataIndex = action.payload.index;
            state.isPopupVisible = true;
        },
        hidePopup: (state) => { state.isPopupVisible = false },
        showPopup: (state) => { state.isPopupVisible = true }
    }
});

export const {
    setPopupData,
    hidePopup,
    showPopup
} = PopupSlice.actions;

export default PopupSlice.reducer;
  