import { Button, Calendar, Form, Input, Modal, Select } from 'antd';
import Avatar from '../../../assets/Avatar.jpg';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import TextArea from 'antd/es/input/TextArea';
import fireCustomEvent from '../../../util/fireCustomEvent';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import arLocale from 'antd/es/date-picker/locale/ar_EG';
import enLocale from 'antd/es/date-picker/locale/en_US';
import 'dayjs/locale/ar';

// Index and setIndex should be passed to highlight the days of the reservation on hover
const dateCellRender = (offers, setPreviewOffer, setModeMonth, language) => {
    const { t } = useTranslation();
    const isMobile = useSelector((state) => state.locale.isMobile);
    const isRtl = language === 'ar';
    const handleDate = (date, info) => {
        const date1 = new Date(date.format('YYYY-MM-DD'));
        const exist = offers.findIndex((offer) => {
            const date2 = new Date(offer[0]);
            const date3 = new Date(offer[1]);

            if (info.type === 'month') {
                return (date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear())
                    || (date1.getMonth() === date3.getMonth() && date1.getFullYear() === date3.getFullYear());
            }

            return date1 >= date2 && date1 <= date3;
        });
        const offersCount = offers.filter((offer) =>
            {
                const date2 = new Date(offer[0]);
                const date3 = new Date(offer[1]);
                return (date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear())
                    || (date1.getMonth() === date3.getMonth() && date1.getFullYear() === date3.getFullYear());
            }).length;
        const colorGreen = exist >= 0 ? '#04A49A' : '';
        // const colorGreen = exist >= 0 && offers[exist][2] === 'Offer 3' ? '#82E0AA' : null;
        // const colorRed = exist >= 0 && offers[exist][2] !== 'Offer 3' ? '#C0392B' : '';
        // and later use this condition: colorGreen ?? colorRed
        const isFirst = exist >= 0 && (new Date(offers[exist][0])).toDateString() === date1.toDateString();
        const isLast = exist >= 0 && (new Date(offers[exist][1])).toDateString() === date1.toDateString();
        const styleExtra = (isFirst && !isRtl) || (isLast && isRtl)
            ? { left: 5, borderTopLeftRadius: 15, borderBottomLeftRadius: 15, width: 'calc(100% - 4px)' }
            : (isLast && !isRtl) || (isFirst && isRtl)
                ? { right: 15, borderTopRightRadius: 15, borderBottomRightRadius: 15, width: 'calc(100% - 14px)' }
                : { left: -1 };

        return (
            <div
              className='ant-picker-cell-inner ant-picker-calendar-date'
              style={ { position: 'relative', height: !isMobile ? 115 : 70, width: '100%', borderInline: '1px solid #e5e7eb' } }
              onClick={ () => info.type !== 'month' ? exist >= 0 && setPreviewOffer(offers[exist]) : setModeMonth(true) }
              // these two are used to highlight the days of the reservation on hover
              // onMouseEnter={ () => exist >= 0 && setIndex((prev) => ({ ...prev, [exist]: true })) }
              // onMouseLeave={ () => exist >= 0 && setIndex((prev) => ({ ...prev, [exist]: false })) }
            >
                <div>
                    { info.type === 'month' ? dayjs(date).locale(language).format('MMMM') : date.date() }
                </div>
                { /* used to highlight the days of the reservation on hover */ }
                { /* { exist >= 0 && index[exist] && ( */ }
                { info.type !== 'month'
                    ? exist >= 0 && colorGreen && (
                        <div style={ { position: 'absolute', paddingInlineStart: 5, top: !isMobile ? 45 : 30, width: 'calc(100% + 2px)', height: '25px', fontSize: 16, textAlign: 'left', color: 'white', backgroundColor: colorGreen, ...styleExtra } }>
                            { isFirst
                                ? (
                                    <div style={ { display: 'flex', alignItems: 'center', height: '100%', gap: 5 } }>
                                        <img className='Avatar' src={ Avatar } alt='' />
                                        { !isMobile ? offers[exist][2] : '' }
                                    </div>
                                ) : null
                            }
                        </div>
                    ) : (
                        offersCount > 0 && (
                            <div style={ { position: 'absolute', paddingInlineStart: 5, top: 50, width: '80%', height: '25px', fontSize: 16, textAlign: 'left', color: 'white', borderRadius: 15, backgroundColor: '#04A49A' } }>
                                <div style={ { display: 'flex', alignItems: 'center', height: '100%', gap: 5 } }>
                                    { t("this month has") } { offersCount } { t(`reservation${offersCount !== 1 ? 's' : ''}`) }
                                </div>
                            </div>
                        )
                    ) }
            </div>
        );
    }

    return handleDate;
};

export default function CalendarsPage() {
    const { t } = useTranslation();
    const [previewOffer, setPreviewOffer] = useState(null);
    const [modeMonth, setModeMonth] = useState(true);
    const isMobile = useSelector((state) => state.locale.isMobile);
    const language = useSelector((state) => state.locale.language);
    const stateOffers = [
        { startDate: '2022-01-05', endDate: '2022-01-15', title: 'Offer 1', price: 100 },
        { startDate: '2022-02-05', endDate: '2022-02-09', title: 'Offer 2', price: 200 },
        { startDate: '2022-02-11', endDate: '2022-02-15', title: 'Offer 3', price: 300 },
        { startDate: '2022-02-16', endDate: '2022-02-20', title: 'Offer 4', price: 400 },
        { startDate: '2022-02-21', endDate: '2022-03-05', title: 'Offer 5', price: 500 },
    ].map(({ startDate, endDate, title, price }) => [startDate, endDate, title, price]);

    return (
        <div className='FirstPage' style={ { marginBlockEnd: 20 } }>
            <h1>{ t("Calendars") }</h1>
            <div className='SelectOffer'>
                { stateOffers.length ?
                    <Select allowClear placeholder={ t('Select an offer') } style={ { width: 200, alignSelf: 'flex-end' } }>
                        { stateOffers.map((offer, index) => <Select.Option key={ index } value={ index }>{ offer.title }</Select.Option>) }
                    </Select>
                    :
                    <h2>{ t("Please Create offers first") }</h2>
                }
            </div>
            <div className='Calendars'>
                <Calendar
                  locale={ language === 'ar' ? arLocale : enLocale }
                  defaultValue={ dayjs('2022-02-15') }
                  mode={ modeMonth ? 'month' : 'year' }
                  onPanelChange={ (_, mode) => setModeMonth(mode === 'month') }
                  fullCellRender={ dateCellRender(stateOffers, setPreviewOffer, setModeMonth, language) }
                />
            </div>
            <Modal open={ !!previewOffer } title={ t("Reservation Details") } footer={ null } onCancel={ () => setPreviewOffer(null) } centered styles={ { body: { minHeight: 400 } } } wrapClassName='Modal'>
                { previewOffer && (
                    <div className='Preview'>
                        <div className='PreviewInfos'>
                            <Form.Item label={ t("Reservation Name:") } labelCol={ { style: isMobile ? { width: '100%' } : (language === 'ar' ? { width: 80 } : { width: 134 }), className: 'PreviewLabel' } }>
                                <Input className='PreviewTitle' value={ previewOffer[2] } disabled />
                            </Form.Item>
                            <Form.Item label={ t("Reservation Date:") } labelCol={ { style: isMobile ? { width: '100%' } : (language === 'ar' ? { width: 80 } : { width: 134 }), className: 'PreviewLabel' } }>
                                <Input className='PreviewDate' value={ `${previewOffer[0]} - ${previewOffer[1]}` } disabled />
                            </Form.Item>
                            <Form.Item label={ t("Reservation Price:") } labelCol={ { style: isMobile ? { width: '100%' } : (language === 'ar' ? { width: 80 } : { width: 134 }), className: 'PreviewLabel' } }>
                                <Input className='PreviewPrice' value={ previewOffer[3] } disabled />
                            </Form.Item>
                        </div>
                        <Form className='PreviewMessage' onFinish={ () => fireCustomEvent('showNotification', 'success', 'Message sent successfully (sent nothing, just a funcitonality test)') }>
                            <TextArea placeholder={ t('Write a message to your client') } style={ { height: 150, resize: 'none' } } required />
                            <Button
                              style={ { width: '50%', alignSelf: 'center' } }
                              htmlType='submit'
                              type='primary'
                            >
                                { t("Send") }
                            </Button>
                        </Form>
                    </div>
                ) }
            </Modal>
        </div>
    );
}