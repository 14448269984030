import React, { createContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CAR_FETCH_REQUEST } from '../config';
import axios from '../api/axios';

export const CarFiltersContext = createContext();

export function CarFiltersProvider({ children: elements }) {
    const locations = useSelector((state) => state.locale.locations);
    const [isLoading, setIsLoading] = useState(true);

    const [queryParameters] = useSearchParams();
    const location = Number(queryParameters.get('location') ?? -1);
    const type = queryParameters.get('type');
    const model = queryParameters.get('model');
    const seats = queryParameters.get('seats');
    const min_price = queryParameters.get('min_price');
    const max_price = queryParameters.get('max_price');
    const options = queryParameters.get('options');
    const range = queryParameters.get('range');

    const [searchData, setSearchData] = useState({
        cars: [],
        filters: {
            sort: '-newest',
            type: type ? parseInt(type) : null,
            options: JSON.parse(options || '[]'),
            min_price: min_price ? parseInt(min_price) : null,
            max_price: max_price ? parseInt(max_price) : null,
            range: range ? parseInt(range) : 50,
            seats: seats ? parseInt(seats) : null,
            model: model ? parseInt(model) : null,
            address: location >= 0 ? { ...locations[location], index: location } : null,
        },
        carsPagination: {
            per_page: null,
            page: null,
        },
        updateSearch: false,
    });

    const getCars = async () => {
        setIsLoading(true);
        const { min_price, max_price, type, address, range, sort, seats, options } = searchData.filters;
        const { per_page, page } = searchData.carsPagination || {};
        const perPageParam = per_page ? `per_page=${per_page}` : '';
        const currentPageParam = page ? `page=${page}` : '';
        const sortBy = sort ? `&sort=${sort}` : '';
        const minPrice = min_price ? `&filter[min_price]=${min_price}` : '';
        const maxPrice = max_price ? `&filter[max_price]=${max_price}` : '';
        const typeFilter = type ? `&filter[type]=${type}` : '';
        const lat = address?.lat ? `&filter[lat]=${address.lat}` : '';
        const lon = address?.lng ? `&filter[lon]=${address.lng}` : '';
        const rangeFilter = range >= 0 ? `&filter[range]=${range}` : '';
        const seatsCount = seats ? `&filter[seats]=${seats}` : '';
        const optionsArray = options && options.length ? `&filter[options]=${options}` : '';

        let queryParams = [perPageParam, currentPageParam].filter(Boolean).join('&');

        queryParams = queryParams ? `${queryParams}&` : '';

        const url = `${CAR_FETCH_REQUEST}?${queryParams}include=address.commune,address.wilaya,images${minPrice}${maxPrice}${typeFilter}${lat}${lon}${rangeFilter}${sortBy}${seatsCount}${optionsArray}`;

        const response = await axios.get(url, {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
        }).then((response) => {
            setSearchData((prev) => ({
                ...prev,
                cars: response.data.data,
                carsPagination: { ...response.data.meta },
            }));

            return response;
        }).catch(() => {});
        setIsLoading(false);

        return response;
    };

    const handleInputChange = (name, value) => {
        setSearchData((prev) => ({ ...prev, filters: { ...prev.filters, [name]: value || value >= 0 ? value : null } }));

        if(name === 'sort') {
            searchCars();
        }
    };

    const handleSelect = (value) => {
        setSearchData((prev) => ({ ...prev, filters: { ...prev.filters, address: { ...locations[value], index: value } } }));
    };

    const searchCars = () => {
        const path = Object.entries(searchData.filters).reduce((acc, [key, value]) => {
            if (value) {
                if (key === 'address' && value.index >= 0) {
                    acc += `${acc === '?' ? '' : '&'}location=${value.index}`;
                } else if (key === 'options' && value?.length > 0) {
                    acc += `${acc === '?' ? '' : '&'}options=${JSON.stringify(value)}`
                } else if (key !== 'address') {
                    acc += `${acc === '?' ? '' : '&'}${key}=${value}`;
                }
            }

            return acc;
        }, '?');

        window.history.replaceState(null, "", "/search/car" + path);
        setSearchData((prev) => ({ ...prev, updateSearch: !prev.updateSearch }));
    };

    const clearFilters = () => {
        const filters = {
            sort: '-newest',
            type: null,
            options: null,
            min_price: null,
            max_price: null,
            range: null,
            seats: null,
            model: null,
            address: null,
        };

        setSearchData((prev) => ({ ...prev, filters, carsPagination: { per_page: null, page: null }, updateSearch: !prev.updateSearch }));
        window.history.replaceState(null, "", "/search/car?sort=-newest");
    };

    const contextValue = {
        cars: searchData.cars,
        filters: searchData.filters,
        carsPagination: searchData.carsPagination,
        isLoading,
        setSearchData,
        clearFilters,
        handleInputChange,
        handleSelect,
        searchCars,
    };

    useEffect(() => {
        getCars();
    }, [searchData.updateSearch]);

    return (
        <CarFiltersContext.Provider value={ contextValue }>
            { elements }
        </CarFiltersContext.Provider>
    );
};