import React from 'react';
import { useSelector } from 'react-redux';
import Logo from '../assets/HomepageLogo.webp';
import LogoAR from '../assets/logo-baytup-AR_1.webp';
import Container from "./Container/Container";

export default function Loading() {
    const language = useSelector((state) => state.locale.language);

    return (
        <Container className='loading-page'>
            <div className='loading-logo'>
                <img src={ language === 'ar' ? LogoAR : Logo } />
                <div className='loading-opacity' />
            </div>
        </Container>
    );
}
