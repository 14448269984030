import { BarChartOutlined, EditOutlined, FileDoneOutlined, GiftOutlined, HomeOutlined, ProfileOutlined, QuestionCircleOutlined, RightOutlined, SecurityScanOutlined, SettingOutlined, ShareAltOutlined, TeamOutlined, UserSwitchOutlined } from '@ant-design/icons';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import 'swiper/scss';
import { logout } from '../../../state/auth/authSlice';
import { useDispatch } from 'react-redux';

export default function MobileMenuPage() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const componentRef = useRef(null);

    return (
        <div className='FirstPage MobileMenuPage' ref={ componentRef }>
            <h1>{ t("Menu") }</h1>
            <div className='MenuSection'>
                <h2>{ t("Visitors Homepage") }</h2>
                <Link to='/' className='Link'><span><BarChartOutlined />{ t("Information") }</span><span><RightOutlined /></span></Link>
                <Link to='/' className='Link'><span><FileDoneOutlined />{ t("Guides") }</span><span><RightOutlined /></span></Link>
                <Link to='/' className='Link'><span><HomeOutlined />{ t("Create new offer") }</span><span><RightOutlined /></span></Link>
            </div>
            <div className='MenuSection'>
                <h2>{ t("My Account") }</h2>
                <Link to='/' className='Link'><span><ProfileOutlined />{ t("Profile") }</span><span><RightOutlined /></span></Link>
                <Link to='/' className='Link'><span><SettingOutlined />{ t("Settings") }</span><span><RightOutlined /></span></Link>
                <Link to='/' className='Link'><span><QuestionCircleOutlined />{ t("Help") }</span><span><RightOutlined /></span></Link>
                <Link to='/' className='Link'><span><SecurityScanOutlined />{ t("Security") }</span><span><RightOutlined /></span></Link>
                <Link to='/' className='Link'><span><UserSwitchOutlined />{ t("Discover related offers") }</span><span><RightOutlined /></span></Link>
                <Link to='/' className='Link'><span><TeamOutlined />{ t("Exchange with other hosts") }</span><span><RightOutlined /></span></Link>
                <Link to='/' className='Link'><span><EditOutlined />{ t("Feedback") }</span><span><RightOutlined /></span></Link>
                <Link to='/' className='Link'><span><GiftOutlined />{ t("Gift") }</span><span><RightOutlined /></span></Link>
                <Link to='/' className='Link'><span><ShareAltOutlined />{ t("Connect with a host") }</span><span><RightOutlined /></span></Link>
            </div>
            <div className='Buttons'>
                <Button type='primary' className='Button'>{ t("Switch to visitor mode") }</Button>
                <Button className='Button' onClick={ () => dispatch(logout()) }>{ t("Logout") }</Button>
            </div>
        </div>
    );
}