/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import "../../../styles/Offers.css"
import { Button, Input, InputNumber, Modal, Select, Space, Table, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { offerOptionsEN } from '../../OfferForm/OfferExtras';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Card from '../../PLP/Card';
import { deleteOffer, resetStatus, setOfferEdit, updateStatus } from '../../../state/offer/offerSlice';
import { Translator } from '../../../i18n';
import { useNavigate } from 'react-router-dom';
import { FaRegEdit, FaRegTrashAlt, FaRegCheckCircle, FaUserAltSlash, FaUserClock } from "react-icons/fa";
import { LuFileEdit, LuXCircle  } from "react-icons/lu";
import { MdPendingActions } from "react-icons/md";
import { FcCancel } from "react-icons/fc";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";





const iconsMap = {
    draft:<LuFileEdit />,
    pending:<MdPendingActions/>,
    accepted:<FaRegCheckCircle/>,
    rejected:<LuXCircle />,
    cancelled:<FcCancel/>,
    suspended:<FaUserAltSlash/>,
    waiting_for_modification:<FaUserClock/>
};

export default function OffersTable({ offers, setSearchFilters }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isRtl = useSelector((state) => state.locale.language) === 'ar';
    const isMobile = useSelector((state) => state.locale.isMobile);
    const stateOffers = useSelector((state) => state.offer.userOffers);
    const ownerOffersStatus = useSelector((state) => state.offer.ownerOffersStatus);
    const deleteOfferStatus = useSelector((state) => state.offer.deleteOfferStatus);
    const updateOfferStatus = useSelector((state) => state.offer.updateOfferStatus);
    const [previewOfferIndex, setPreviewOfferIndex] = useState(null);
    const [isDelete, setIsDelete] = useState(false);
    const [status, setStatus] = useState(null);
    const previewOffer = stateOffers[previewOfferIndex];

    const columns = [
        {
            title: t('Announcement'),
            dataIndex: 'offer',
            key: 'offer',
            render: (offer) => <div className='Name' onClick={ () => offer.name && setPreviewOfferIndex(offer.index) }>{ offer.image && <img src={ offer.image } alt="Offer" style={ { width: 60, height: 45 } } /> }<a style={ { display: 'flex', flexDirection: 'column' } }>{ offer.name }<span style={ { color: '#ccc' } }>{ isRtl ? offer.type?.name_ar : offer.type?.name_en }</span></a></div>,
            sorter: (a, b) => a.offer.name.localeCompare(b.offer.name),
            sortDirections: ['ascend', 'descend'],
            ellipsis: true,
        },
      
        {
            title: t('Status'),
            dataIndex: 'status',
            key: 'status',
            render: ({ name }) => <div style={ { display: 'flex', alignItems: 'center', gap: '5px', textTransform: 'capitalize' } }>{ iconsMap[name] } { t(name === 'waiting_for_modification' ? 'waiting for modification' : name) }</div>,
            sorter: (a, b) => a.offer.name.localeCompare(b.offer.name),
            sortDirections: ['ascend', 'descend'],
            ellipsis: true,
        },
        {
            title: t('Rooms'),
            dataIndex: 'rooms',
            key: 'rooms',
            sorter: (a, b) => a.rooms < b.rooms,
            sortDirections: ['ascend', 'descend'],
            ellipsis: true,
            width: '13%',
        },
        {
            title: t('Beds'),
            dataIndex: 'beds',
            key: 'beds',
            sorter: (a, b) => a.beds < b.beds,
            sortDirections: ['ascend', 'descend'],
            ellipsis: true,
            width: '9%',
        },
        {
            title: t('Bathrooms'),
            dataIndex: 'bathrooms',
            key: 'bathrooms',
            sorter: (a, b) => a.bathrooms < b.bathrooms,
            sortDirections: ['ascend', 'descend'],
            ellipsis: true,
            width: '17%',
        },
        {
            title: t('Address'),
            dataIndex: 'address',
            key: 'address',
            render: (address) => isRtl ? address.name_ar : address.name_en,
            sorter: (a, b) => a.address.localeCompare(b.address),
            sortDirections: ['ascend', 'descend'],
            ellipsis: true,
        },
        {
            title: t('Updated at'),
            dataIndex: 'updated_at',
            key: 'updated_at',
            sorter: (a, b) => a.address.localeCompare(b.address),
            sortDirections: ['ascend', 'descend'],
            ellipsis: true,
        },
        {
            title: t('Action'),
            dataIndex: 'status',
            key: 'action',
            render: (data) => (
                <Space size="middle">
                    <Tooltip title={ t("Modify") }>
                        <div className='Accept flex justify-center items-center cursor-pointer' onClick={ () => {navigate('/create/offer?id='+data.id);} }>
                            <FaRegEdit className='text-lg text-orange-500'/>
                        </div>
                    </Tooltip>
                    <Tooltip title={ t("Delete") }>
                        <div disabled={ deleteOfferStatus === 'pending' } className='Delete flex justify-center items-center cursor-pointer' onClick={ () => handleDelete(data.index) }><FaRegTrashAlt className='text-lg text-orange-500'/></div>
                    </Tooltip>
                </Space>
            ),
        },
    ];

    const handleSave = (id) => {
        return () => dispatch(updateStatus({ id }));
    };

    const handleDelete = (index) => {
        setIsDelete(true);
        setPreviewOfferIndex(index);
    };
    const toggleStatus = () => {
        const newStatus = status === 1 ? 0 : 1;
        setStatus(newStatus);
        // Here you should call the update function to change the status in your backend
        handleSave(previewOffer.id, newStatus);
    };

    useEffect(() => {
        if (previewOffer) {
            setStatus(previewOffer.is_visible);
        }
    }, [previewOffer])

    useEffect(() => {
        if (deleteOfferStatus === 'success') {
            setPreviewOfferIndex(null);
        }
    }, [deleteOfferStatus]);

    return (
        <div className='Table'>
            <Input
              placeholder={ t("Search") }
              onChange={ (e) => setSearchFilters((prev) => ({ ...prev, text: e.target.value || '' })) }
              allowClear
              style={ {
                marginBottom: 8,
                marginInlineEnd: 8,
                maxWidth: 200
              } }
              prefix={ <SearchOutlined /> }
            />
            <InputNumber
              placeholder={ t("Choose Rooms") }
              type="number"
              min={ 1 }
              onChange={ (value) => setSearchFilters((prev) => ({ ...prev, rooms: value })) }
              style={ { width: 120, marginInlineEnd: 8 } }
            />
            <Select
              mode='multiple'
              placeholder={ t("Equipments") }
              maxTagCount={ 0 }
              maxTagPlaceholder={ () => 'Selected' }
              style={ {
                marginBottom: 8,
                marginInlineEnd: 8,
                minWidth: 150
              } }
              onChange={ (value) => setSearchFilters((prev) => ({ ...prev, extras: value })) }
            >
                { offerOptionsEN.map((item) => (
                    <Select.Option key={ item } value={ item }>
                        <Translator text={ item } />
                    </Select.Option>
                )) }
            </Select>
            <Select
              mode='multiple'
              placeholder={ t("Status") }
              style={ {
                
              } }
              dropdownStyle={ {
                textTransform: 'capitalize'
              } }
              onChange={ (value) => setSearchFilters((prev) => ({ ...prev, status: value })) }
            >
                <Select.Option value="draft">{ t("draft") }</Select.Option>
                <Select.Option value="pending">{ t("pending") }</Select.Option>
                <Select.Option value="accepted">{ t("accepted") }</Select.Option>
                <Select.Option value="rejected">{ t("rejected") }</Select.Option>
                <Select.Option value="cancelled">{ t("cancelled") }</Select.Option>
                <Select.Option value="suspended">{ t("suspended") }</Select.Option>
                <Select.Option value="waiting_for_modification">{ t("waiting for modification") }</Select.Option>
            </Select>
            <Table
              className={ isRtl ? 'ShadowFix' : '' }
              columns={ columns }
              dataSource={ offers }
              pagination={ { pageSize: 6 } }
              scroll={ { x: 'max-content' } }
              loading={ ownerOffersStatus === 'pending' }
            />
            <Modal
              open={ !!previewOffer }
              title={ t("Reservation Details") }
              footer={ null }
              onCancel={ () => {setPreviewOfferIndex(null);setIsDelete(false);} }
              afterOpenChange={ (open) => {setStatus(previewOffer ? previewOffer.is_visible : null); !open && dispatch(resetStatus());} }
              centered
              styles={ { content: { minWidth: isDelete ? 200 : (isMobile ? '100%' : 600) }, body: { minHeight: isDelete ? 50 : 400 } } }
              wrapClassName='Modal'
            >
                { !!previewOffer && (
                    <div className='Preview'>
                        <div className='PreviewInfos' style={ isDelete ? { alignItems: 'center' } : {} }>
                            { isDelete ? (
                                <>
                                    <label>{ t("Are you sure you want to delete this offer?") }</label>
                                    <div style={ { display: 'flex', gap: 50 } }>
                                        <Button type="primary" danger onClick={ () => { dispatch(deleteOffer({ id: previewOffer.id } ));setPreviewOfferIndex(null);setIsDelete(false);} }>{ t("Delete") }</Button>
                                        <Button onClick={ () => {setPreviewOfferIndex(null);setIsDelete(false);} }>{ t("Cancel") }</Button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    
                                    <div className='flex items-center'>
                                        <label>{ t("Change the status of this offer") }:</label>
                                        <button className='text-orange-500' onClick= { toggleStatus } disabled= { deleteOfferStatus === 'pending' || updateOfferStatus === 'pending' }
                                        >{ status === 1 ? <IoIosEye className='text-xl' /> : <IoIosEyeOff className='text-xl'/> }
                                        </button>
                                       
                                    </div>
                                    <Card key={ previewOffer.id + isRtl } { ...previewOffer } isMap />
                                    <div className='flex justify-end'>
                                        <Button className='w-32 flex items-center justify-center' disabled={ deleteOfferStatus === 'pending' || updateOfferStatus === 'pending' || status === previewOffer.is_visible || (updateOfferStatus === 'pending' && status !== previewOffer.is_visible) } onClick={ handleSave(previewOffer.id) }>{ t("Save") }</Button>
                                    </div>
                                    
                                </>
                            ) }
                        </div>
                    </div>
                ) }
            </Modal>
        </div>
    );
}
