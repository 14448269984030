import React from 'react';
import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next, useTranslation } from 'react-i18next';

i18next
    .use(Backend)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: false,
        interpolation: {
            escapeValue: false,
        },
    });

i18next.loadLanguages('ar');

export function Translator({ text }) {
    const { t } = useTranslation();

    return (
        <>
            { i18next.language === 'en' ? text : t(text) }
        </>
    );
}

export default i18next;
