import React from 'react';
import { Translator } from '../../i18n';
import { useNavigate } from 'react-router-dom';

export default function HouseCard({ price, location, beds, baths, rooms, image, id = 0, setHoveredId = () => {} }) {
    const navigate = useNavigate();
    const props = id !== 0 ? {
        style: {
            cursor: 'pointer'
        },
        onClick: () => {navigate('/offer?id='+id)}
    } : {};

    return (
        <div className="Hotel" { ...props } onMouseEnter={ () => setHoveredId(id) } onMouseLeave={ () => setHoveredId(0) }>
            <img src={ image } />
            <div className="Hotel-Info ">
                <div className="Hotel-Price">
                    <div className='Number'>
                        { price }
                    </div>
                    <Translator className='Text' text='DZD/Night' /></div>
                <div className="Hotel-Details relative">
                    { /* TODO: remove Translator as it's only used with mock data, text should be translated from BE */ }
                    <div className="Hotel-Location"><Translator text={ location } /></div>
                    <div className="Hotel-Benefits absolute bottom-48 right-4">
                        <div className="Hotel-Beds">
                            <div>{ beds }</div>
                            <div><Translator text={ beds > 1 ? 'Beds' : 'Bed' } /></div>
                        </div>
                        { baths && (
                            <div className="Hotel-Baths">
                                <div>{ baths }</div>
                                <div><Translator text={ baths > 1 ? 'Baths' : 'Bath' } /></div>
                            </div>
                        ) }
                        <div className="Hotel-Rooms">
                            <div>{ rooms }</div>
                            <div><Translator text={ rooms > 1 ? 'Rooms' : 'Room' } /></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
