export default function fireCustomEvent(eventName, status, message, type = null) {
    const customEvent = new CustomEvent(eventName, {
        detail: {
            status,
            message,
            type
        },
    });

    document.dispatchEvent(customEvent);
};