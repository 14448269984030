import React, { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PasswordEye from "../../icons/PasswordEye";
import { Translator } from "../../i18n";
import { useSelector } from "react-redux";
import { Checkbox } from "antd";

const defaultStyles = {
    standardInput: "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500",
    StandardInput: "text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
};

function Input({
  type = 'text',
  name = '',
  label = '',
  error = '',
  placeholder = (label ? `Enter your ${label}` : ''),
  options = [],
  defaultStyle = '',
  ...props
}, ref = null) {
    const { t } = useTranslation();
    const language = useSelector((state) => state.locale.language);
    const [ passwordVisibility, setPasswordVisibility ] = useState(false);
    const [ inputValue, setInputValue ] = useState('');
    const { className: defaultClassName, onChange, maxLength, ...restProps } = props;
    const className = defaultStyle !== '' ? `${defaultStyles[defaultStyle]} ${defaultClassName}` : defaultClassName;

    const handleChange = (e) => {
        if (typeof onChange === 'function') {
            onChange(e);
        }
        const newValue = e.target.value;

        if (type === 'checked') {
            setInputValue((prevState) => !prevState);
        } else if (newValue.length <= maxLength) {
            setInputValue(newValue);
        }
    };

    const togglePasswordVisibility = () => {
        setPasswordVisibility((prevState) => !prevState);
    };

    if (type === 'text') {
        return (
            <div className='InputWrapper'>
                <div className="InputWrapper-Error">{ error }</div>
                { label && <label htmlFor={ name }><Translator text={ label } /></label> }
                <input
                  type="text"
                  id={ name }
                  name={ name }
                  placeholder={ t(placeholder) }
                  className={ className }
                  onChange={ handleChange }
                  { ...restProps }
                />
                { maxLength && (<p>{ inputValue.length } / { maxLength }</p>) }
            </div>
        );
    } else if (type === 'password' || type === 'password_confirmation') {
        return (
            <div className="InputWrapper">
                <div className="InputWrapper-Error">
                    { error }
                </div>
                <label htmlFor={ type }><Translator text={ type === 'password' ? 'Password' : 'Confirm Password' } /></label>
                <input
                  type={
                        passwordVisibility
                            ? 'text'
                            : 'password'
                    }
                  id={ type }
                  name={ type }
                  placeholder={ t(type === 'password' ? 'Enter password' : 'Confirm your password') }
                  className={ className }
                  { ...props }
                />
                <PasswordEye
                  onClick={ togglePasswordVisibility }
                  shown={ passwordVisibility }
                />
            </div>
        );
    } else if (type === 'email') {
        return (
            <div className="InputWrapper">
                <div className="InputWrapper-Error">
                    { error }
                </div>
                <label htmlFor="email"><Translator text='Email' /></label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder={ t('Enter your email address') }
                  ref={ ref }
                  className={ className }
                  { ...props }
                />
            </div>
        );
    } else if (type === 'select') {
        return (
            <div className="InputWrapper">
                <div className="InputWrapper-Error">
                    { error }
                </div>
                { label && <label htmlFor={ name }><Translator text={ label } /></label> }
                <select
                  type={ type }
                  id={ name }
                  name={ name }
                  placeholder={ placeholder }
                  className={ `custom-select Select ${className}` }
                  ref={ ref }
                  data-is-reversed={ language === 'ar' } 
                  { ...props }
                >
                    { options.map(({ name: localName, id, hidden }, index) => (
                        <option key={ index } value={ id } hidden={ hidden }>
                            <Translator text={ localName } />
                        </option>
                    )) }
                </select>
            </div>
        );
    } else if (type === 'textarea') {
        return (
            <div className="InputWrapper">
                <div className="InputWrapper-Error">
                    { error }
                </div>
                { label && <label htmlFor={ name }><Translator text={ label } /></label> }
                <textarea
                  id={ name }
                  name={ name }
                  placeholder={ placeholder }
                  ref={ ref }
                  onChange={ handleChange }
                  className={ className }
                  { ...restProps }
                />
                { maxLength && (<p>{ inputValue.length } / { maxLength }</p>) }
            </div>
        );
    } else if (type === 'checkbox') {
        return (
            <div className="InputWrapper">
                <div className="InputWrapper-Error">
                    { error }
                </div>
                <Checkbox
                  name={ name }
                  className='Antd-Checkbox'
                  { ...props }
                >
                    <Translator text={ label } />
                </Checkbox>
            </div>
        );
    }

    return (
        <div className="InputWrapper">
            <div className="InputWrapper-Error">
                { error }
            </div>
            { label && <label htmlFor={ name }><Translator text={ label } /></label> }
            <input
              type={ type }
              id={ name }
              name={ name }
              placeholder={ placeholder }
              ref={ ref }
              className={ className }
              { ...props }
            />
        </div>
    );
}

export default forwardRef(Input);