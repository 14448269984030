import React from 'react';

export default function PasswordEye({ onClick = () => {}, shown = false }) {
    if (shown) {
        return (
            <svg
              onClick={ onClick }
              fill="#ABABAB"
              height="20px"
              width="20px"
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 487.55 487.55"
              xmlSpace="preserve"
            >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                    <g>
                        <g id="XMLID_1992_">
                            <path
                              id="XMLID_2003_"
                              d="M244.625,171.415c-40,0-72.4,32.4-72.4,72.4s32.4,72.4,72.4,72.4s72.4-32.4,72.4-72.4 C316.925,203.815,284.525,171.415,244.625,171.415z M244.625,220.215c-13,0-23.6,10.6-23.6,23.6c0,6-4.8,10.8-10.8,10.8 s-10.8-4.8-10.8-10.8c0-24.9,20.3-45.2,45.2-45.2c6,0,10.8,4.8,10.8,10.8C255.425,215.415,250.525,220.215,244.625,220.215z"
                            ></path>
                            <path
                              id="XMLID_2012_"
                              d="M481.325,227.515c-224.8-258.6-428-53.9-476.4,2.8c-6.5,7.6-6.6,18.8-0.1,26.4 c221.9,259,423.4,64.6,476.5,3.7C489.625,251.015,489.625,237.015,481.325,227.515z M244.625,346.615 c-56.8,0-102.8-46-102.8-102.8s46-102.8,102.8-102.8s102.8,46,102.8,102.8S301.325,346.615,244.625,346.615z"
                            ></path>
                        </g>
                    </g>
                </g>
            </svg>
        );
    }

    return (
        <svg
          onClick={ onClick }
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 16 13"
          fill="none"
        >
            <path
              d="M8.34428 4.27061L10.5033 6.28565L10.5135 6.1801C10.5135 5.1214 9.5917 4.26102 8.45737 4.26102L8.34428 4.27061Z"
              fill="#ABABAB"
            />
            <path
              d="M8.45724 2.98164C10.3489 2.98164 11.8842 4.41457 11.8842 6.18013C11.8842 6.59273 11.7951 6.98614 11.6409 7.34756L13.6456 9.21867C14.6806 8.41265 15.4962 7.36996 16 6.18013C14.8108 3.37188 11.8876 1.38242 8.45727 1.38242C7.49772 1.38242 6.57932 1.54233 5.72601 1.83019L7.20645 3.20872C7.59365 3.068 8.01517 2.98164 8.45724 2.98164Z"
              fill="#ABABAB"
            />
            <path
              d="M1.60341 1.23852L3.16609 2.69703L3.47795 2.9881C2.34706 3.81331 1.45263 4.91039 0.91803 6.18017C2.10376 8.98842 5.03035 10.9779 8.4573 10.9779C9.51966 10.9779 10.534 10.786 11.4627 10.4373L11.754 10.7092L13.752 12.5771L14.6258 11.7647L2.47728 0.422913L1.60341 1.23852ZM5.39362 4.77285L6.45254 5.76118C6.4217 5.89872 6.40114 6.03624 6.40114 6.18017C6.40114 7.23887 7.32298 8.09925 8.4573 8.09925C8.61151 8.09925 8.75888 8.08006 8.90281 8.05127L9.96174 9.0396C9.50594 9.2507 8.99878 9.37865 8.4573 9.37865C6.56563 9.37865 5.03035 7.94573 5.03035 6.18017C5.03035 5.67482 5.16744 5.20143 5.39362 4.77285Z"
              fill="#ABABAB"
            />
        </svg>
    );
}
