import React, { useEffect, useRef, useState } from 'react';
import MessagesList from './MessagesList';
import HotelImage1 from '../../../../assets/hotel1.jpg';
import { Drawer } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getOffers } from '../../../../state/offer/offerSlice';
import { useTranslation } from 'react-i18next';
import { ArrowLeftOutlined } from '@ant-design/icons';

export default function MessagesMobile() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const people = [
        { id: 1, name: 'Jane Smith', messages: [
            { name: 'Jane Smith', message: 'Hello, my name is Jane Smith, and I am a software engineer.', icon: null, date: '18.Dec.2022 12:50' },
            { name: 'John Doe', message: 'Hello, my name is John Doe, and I am a software engineer.', icon: null, date: '18.Dec.2022 12:53' },
            { name: 'Jane Smith', message: 'Hello, my name is Jane Smith, and I am a software engineer.', icon: null, date: '18.Dec.2022 12:55' },
            { name: 'John Doe', message: 'Hello, my name is John Doe, and I am a software engineer.', icon: null, date: '18.Dec.2022 13:50' },
            { name: 'John Doe', message: 'Hello, my name is John Doe, and I am a software engineer.', icon: null, date: '18.Dec.2022 14:50' },
            { name: 'Jane Smith', message: 'Hello, my name is Jane Smith, and I am a software engineer.', icon: null, date: '19.Dec.2022 12:50' },
            { name: 'John Doe', message: 'Hello, my name is John Doe, and I am a software engineer.', icon: null, date: '19.Dec.2022 12:53' },
            { name: 'Jane Smith', message: 'Hello, my name is Jane Smith, and I am a software engineer.', icon: null, date: '19.Dec.2022 12:55' },
            { name: 'John Doe', message: 'Hello, my name is John Doe, and I am a software engineer.', icon: null, date: '20.Dec.2022 13:50' },
            { name: 'John Doe', message: 'Hello, my name is John Doe, and I am a software engineer.', icon: null, date: '20.Dec.2022 14:50' },
        ], age: 30 },
        { id: 2, name: 'John Doe', messages: [{ name: 'Jane Smith', message: 'Hello, my name is Jane Smith.', icon: null, date: '18.Dec.2022 12:50' }], age: 25 },
        { id: 3, name: 'John Doe', messages: [{ name: 'Jane Smith', message: 'Hello, my name is Jane Smith.', icon: null, date: '18.Dec.2022 12:50' }], age: 25 },
        { id: 4, name: 'John Doe', messages: [{ name: 'Jane Smith', message: 'Hello, my name is Jane Smith.', icon: null, date: '18.Dec.2022 12:50' }], age: 25 },
        { id: 5, name: 'John Doe', messages: [{ name: 'Jane Smith', message: 'Hello, my name is Jane Smith.', icon: null, date: '18.Dec.2022 12:50' }], age: 25 },
        { id: 6, name: 'John Doe', messages: [{ name: 'Jane Smith', message: 'Hello, my name is Jane Smith.', icon: null, date: '18.Dec.2022 12:50' }], age: 25 },
        { id: 7, name: 'John Doe', messages: [{ name: 'Jane Smith', message: 'Hello, my name is Jane Smith.', icon: null, date: '18.Dec.2022 12:50' }], age: 25 },
    ];
    const conversations = useRef(null);
    const [selectedPerson, setSelectedPerson] = useState(null);
    const offer = useSelector((state) => state.offer.offers[0]) || {};
    const isRtl = useSelector((state) => state.locale.language) === 'ar';

    const handlePersonClick = (person) => {
        setSelectedPerson(person);
    };

    useEffect(() => {
        if (conversations?.current && conversations.current.scrollHeight > 500) {
            conversations.current.className = 'Section People People-Scrollable';
        }
    }, [conversations]);

    useEffect(() => {
        if (!offer?.id) {
            dispatch(getOffers());
        }
    }, []);

    return (
        <div className="FirstPage Messages">
            <div className="MessagingPage">
                <div ref={ conversations } className="Section People">
                    { people?.length > 0 ?
                        <ul>
                            { people.map((person) => (
                                <li key={ person.id } onClick={ () => handlePersonClick(person) }>
                                    <img src={ HotelImage1 } alt={ person.name } />
                                    <div className='Section-Details'>
                                        <div className='Section-Details-Name'>
                                            <span>{ person.name }</span><span>{ person.messages[person.messages.length - 1].date }</span>
                                        </div>
                                        <div className='Section-Details-Message'>
                                            <span>{ person.messages[person.messages.length - 1].message }</span>
                                        </div>
                                        <div className='Section-Details-Reservation'>
                                            <span>{ offer.title }</span>
                                        </div>
                                    </div>
                                </li>
                            )) }
                        </ul>
                        :
                        <p>{ t("You have no conversations") }.</p>
                    }
                </div>
                <Drawer
                  title={ selectedPerson?.name }
                  width={ 500 }
                  onClose={ () => setSelectedPerson(null) }
                  open={ !!selectedPerson }
                  placement={ isRtl ? 'left' : 'right' }
                  style={ { height: 'calc(100% - 70px)' } }
                  styles={ { body: { overflow: 'hidden' } } }
                  destroyOnClose={ true }
                  closeIcon={ <ArrowLeftOutlined /> }
                >
                    { selectedPerson?.messages?.length > 0 ? <MessagesList id={ selectedPerson.id } data={ selectedPerson.messages } /> : <h2>{ t("Please select a person first") }</h2> }
                </Drawer>
            </div>
        </div>
    );
}