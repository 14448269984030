import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Translator } from '../../i18n';
import { Button, Menu, Popover } from 'antd';
import { BellOutlined, SettingOutlined, StarOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setPopupData } from '../../state/popup/popupSlice';

function getItem(label, key, icon, children, type) {
    return {
        label,
        key,
        icon,
        children,
        type,
    };
}

const items = [
    getItem('General Pages', 'sub1', <SettingOutlined />, [
        getItem('Destinations', '/create/offer'),
        getItem('Cars', '/create/car'),
        getItem('Messages', '/dashboard/messages'),
    ])
];

const Content = ({ notifications }) => {
    const [rows, setRows] = useState([...notifications]);

    const onClick = (index) => {
        setRows((rows) => [ ...rows].filter((_, row) => row !== index));
    };

    const content = rows.length !== 0 ? (
        <div>
            { rows.map((notif, index) => (
                <p onClick={ () => onClick(index) } key={ index } style={ { cursor: 'pointer' } }>{ notif }</p>
            )) }
        </div>
    ) : (
        <span><Translator text="No new notifications" />.</span>
    );

    return (
        <Popover content={ content } className='Bell' title={ rows.length !== 0 && <Translator text="Notifications" /> } trigger="hover">
            <BellOutlined />
            { rows.length !== 0 && (
                <svg xmlns="http://www.w3.org/2000/svg" className='BellCircle' width="5" height="5">
                    <circle cx="5" cy="5" r="5" fill="orange" />
                </svg>
            ) }
        </Popover>
    );
};

const ContentFav = () => {
    const favorites = useSelector((state) => state.auth.favorites);
    const link = useRef(null);

    const content = favorites.length !== 0 ? (
        <>
            <Link ref={ link } to="/saved" style={ { fontSize: '14px', fontWeight: 600 } }><Translator text="View all" /></Link>
            <ul style={ { maxHeight: 150, overflowY: 'auto' } }>
                { favorites.map((item, index) => (
                    <li key={ index }><Link to={ `/offer?id=${ item.id }` } style={ { cursor: 'pointer' } }>{ item.title }</Link></li>
                )) }
            </ul>
        </>
    ) : (
        <span><Translator text="You don't have any favourites" />.</span>
    );

    return (
        <Popover content={ content } className='Bell' trigger={ ["hover", "click"] }>
            <StarOutlined tabIndex={ 0 } />
        </Popover>
    );
};

export default function NavbarLinks({ isMobile, SlideMenuClose = () => {} }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const isRtl = useSelector((state) => state.locale.language) === 'ar';

    const notifications = isRtl ? [
        'إشعار 1',
        'إشعار 2',
        'إشعار 3',
    ] : [
        'Notification 1',
        'Notification 2',
        'Notification 3',
    ];
    
    const onClick = (e) => {
        SlideMenuClose();
        navigate(e.key);
    };

    const handleRedirect = () => {
        if (isAuthenticated) {
            navigate(window.location.pathname === '/create/offer' ? '/dashboard/offers' : '/create/offer');
        } else if (!window.location.pathname.includes('login')) {
            dispatch(setPopupData({ index: 2 }));
        }
    };

    if (isMobile) {
        return (
            <Menu
              onClick={ onClick }
              style={ { width: 256 } }
              defaultSelectedKeys={ ['1'] }
              defaultOpenKeys={ ['sub1'] }
              mode="inline"
              items={ items }
            />
        );
    }

    return (
        <>
            <Button type='default' tabIndex={ 0 } onClick={ handleRedirect } className='Navbar-ListingButton'>
                { window.location.pathname === '/create/offer'
                    ? <svg fill="#ffffff" viewBox="0 0 1000 1000" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M850 125H150q-10 0-17.5 7.5T125 150v137q0 11 7.5 18t17.5 7h700q10 0 17.5-7t7.5-18V150q0-10-7.5-17.5T850 125zM281 256q0 10-7.5 17.5T256 281h-74q-11 0-18.5-7.5T156 256v-74q0-11 7.5-18.5T182 156h74q10 0 17.5 7.5T281 182v74zm569 150H150q-10 0-17.5 7.5T125 432v136q0 11 7.5 18.5T150 594h700q10 0 17.5-7.5T875 568V432q0-11-7.5-18.5T850 406zM281 537q0 11-7.5 18t-17.5 8h-74q-11-1-18.5-8t-7.5-18v-74q0-11 7.5-18.5T182 437h74q10 0 17.5 7.5T281 463v74zm569 150H150q-10 1-17.5 8t-7.5 18v137q0 10 7.5 17.5T150 875h700q10 0 17.5-7.5T875 850V713q0-11-7.5-18.5T850 687zM281 818q0 11-7.5 18.5T256 844h-74q-11 0-18.5-7.5T156 818v-74q0-10 7.5-17.5T182 719h74q10 0 17.5 7.5T281 744v74z"></path></g></svg>
                    : <svg fill="#FFFFFF" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M0 26.016q0 2.496 1.76 4.224t4.256 1.76h20q2.464 0 4.224-1.76t1.76-4.224v-20q0-2.496-1.76-4.256t-4.224-1.76h-20q-2.496 0-4.256 1.76t-1.76 4.256v20zM4 26.016v-20q0-0.832 0.576-1.408t1.44-0.608h20q0.8 0 1.408 0.608t0.576 1.408v20q0 0.832-0.576 1.408t-1.408 0.576h-20q-0.832 0-1.44-0.576t-0.576-1.408zM8 16q0 0.832 0.576 1.44t1.44 0.576h4v4q0 0.832 0.576 1.408t1.408 0.576 1.408-0.576 0.608-1.408v-4h4q0.8 0 1.408-0.576t0.576-1.44-0.576-1.408-1.408-0.576h-4v-4q0-0.832-0.608-1.408t-1.408-0.608-1.408 0.608-0.576 1.408v4h-4q-0.832 0-1.44 0.576t-0.576 1.408z" /></g></svg>
                }
                <p><Translator text={ window.location.pathname === '/create/offer' ? 'View listings' : 'Add listing' } /></p>
            </Button>
            { isAuthenticated && (
                <>
                    <ContentFav />
                    { /* <Link to="/favorites">
                        <ShoppingCartOutlined />
                    </Link> */ }
                    <Content notifications={ notifications } />
                </>
            ) }
        </>
    );
}
