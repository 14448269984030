/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
import React, { useState, useCallback, useEffect } from 'react';
import { arrayMove, SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import Grid from './Grid';
import SortableItem from './SortableItem';
import Item from './Item';
import { useDispatch, useSelector } from 'react-redux';
import { Upload } from 'antd';
import { uploadImages } from '../../state/offer/offerSlice';
import { uploadImages as uploadCarImages } from '../../state/car/carSlice';
import { PlusOutlined } from '@ant-design/icons';
import { Translator } from '../../i18n';
import fireCustomEvent from '../../util/fireCustomEvent';

export default function SortableImagesDrag({ isCar = false }) {
    const dispatch = useDispatch();
    const items = useSelector((state) => isCar ? state.car.car?.images : state.offer.offer?.images) || [];
    const car = useSelector((state) => state.car.car);
    const uploadStatus = useSelector((state) => isCar ? state.car.uploadStatus : state.offer.uploadStatus);
    const requestStatus = useSelector((state) => isCar ? state.car.requestStatus : state.offer.requestStatus);
    const switchStatus = useSelector((state) => isCar ? state.car.switchStatus : state.offer.switchStatus);

    const handleChange = () => {
        return async (file) => {
            if (items.length >= 14) {
                fireCustomEvent('showNotification', 'warning', 'in order to proceed, you have to provide minimum of 3 images,\nand maximum of 14 images.');
                return false;
            }

            dispatch(isCar ? uploadCarImages({ selectedImages: [file] }) : uploadImages({ selectedImages: [file] }));
            return false;
        };
    };

    const dummyRequest = ({ onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    return (
        <SortableContext items={ items } strategy={ rectSortingStrategy }>
            <Grid columns={ 5 } style={ uploadStatus === 'pending' || requestStatus === 'pending' || switchStatus === 'pending' ? { pointerEvents: 'none', opacity: '0.5' } : {} }>
                { items.map(({ id, path }) => (
                    <SortableItem key={ id } id={ id } path={ path } />
                )) }
                { items?.length !== 14 && (
                    <Item withChildren={ true }>
                        <Upload
                          customRequest={ dummyRequest }
                          listType="picture-card"
                          beforeUpload={ handleChange() }
                          rootClassName="UploadCarImages"
                          showUploadList={ false }
                          multiple={ true }
                          style={ { height: '100%', width: '100%' } }
                        >
                            <div>
                                <PlusOutlined />
                                <div
                                  style={ {
                                    marginTop: 8,
                                } }
                                >
                                    <Translator text="Upload n." /> { items.length + 1 }
                                </div>
                            </div>
                        </Upload>
                    </Item>
                ) }
            </Grid>
        </SortableContext>
    );
}
