/* eslint-disable no-unused-vars */
import React, { useContext, useState } from 'react';
import SearchArea from '../components/PLP/SearchArea';
import { useSelector } from 'react-redux';
import Loading from '../components/Loading';
import { Translator } from '../i18n';
import { Button, Select } from 'antd';
import { AppstoreAddOutlined, DatabaseOutlined, EnvironmentOutlined, OrderedListOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { CarFiltersContext, CarFiltersProvider } from '../context/CarFiltersProvider';
import CarsResultList from '../components/PLP/CarsResultList';

const Filters = ({ grid, map, onHandleFiltersChange }) => {
    const { t } = useTranslation();
    const isMobile = useSelector((state) => state.locale.isMobile);
    const {
        filters,
        handleInputChange,
        searchCars
    } = useContext(CarFiltersContext);
    
    const orderByFilters = [
        { label: t('Newest'), value: '-newest' },
        { label: t('Oldest'), value: 'newest' },
        { label: t('High price first'), value: '-price' },
        { label: t('Low price first'), value: 'price' },
        { label: t('Closest'), value: 'closest' },
        { label: t('Farthest'), value: '-closest' },
    ];

    if (!isMobile) {
        return <></>;
    }

    return (
        <div
          className="Filters"
          style={ {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontSize: 22,
            fontHeight: 700,
            marginBlock: 10
          } }
        >
            <Translator text="Available Cars" />
            <div className="DisplayFitlers">
                <Select
                  size="large"
                  defaultValue={ filters.sort ?? "-newest" }
                  style={ { width: 145 } }
                  allowClear
                  onChange={ (value) => { handleInputChange('sort', value); searchCars(); } }
                  options={ orderByFilters }
                />
                <Button
                  type={ grid ? 'primary' : 'default' }
                  icon={ <DatabaseOutlined /> }
                  size="large"
                  onClick={ () => onHandleFiltersChange('grid', false) }
                />
                <Button
                  type={ !grid ? 'primary' : 'default' }
                  icon={ <AppstoreAddOutlined /> }
                  size="large"
                  onClick={ () => onHandleFiltersChange('grid', true) }
                />
                <Button
                  type={ map ? 'primary' : 'default' }
                  icon={ <OrderedListOutlined /> }
                  size="large"
                  onClick={ () => onHandleFiltersChange('map', false) }
                />
                <Button
                  type={ !map ? 'primary' : 'default' }
                  icon={ <EnvironmentOutlined /> }
                  size="large"
                  onClick={ () => onHandleFiltersChange('map', true) }
                />
            </div>
        </div>
    );
}

export default function CarsProductListPage() {
    const isLocationsLoading = useSelector((state) => state.locale.isLocationsLoading);
    const [filters, setFilters] = useState({
        grid: false,
        map: false,
        hoveredId: 0,
    });

    const handleFilters = (filter, value) => {
        setFilters((prevState) => ({ ...prevState, [filter]: value }));
    };

    if (isLocationsLoading) {
        return <Loading />;
    }

    return (
        <CarFiltersProvider>
            <SearchArea filters={ filters } onHandleFiltersChange={ handleFilters } isCar />
            <Filters grid={ filters.grid } map={ filters.map } onHandleFiltersChange={ handleFilters } />
            <div className='SearchContent' data-is-map={ !!filters.map }>
                <CarsResultList grid={ filters.grid } isMap={ filters.map } onHandleFiltersChange={ handleFilters } setHoveredId={ (filters) => setFilters((prevState) => ({ ...prevState, hoveredId: filters }) ) } />
                { /* { filters.map && <MapArea hoveredId={ filters.hoveredId } /> } */ }
            </div>
        </CarFiltersProvider>
    );
}
