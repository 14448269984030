import React, { useEffect, useMemo, useState } from 'react';
import CarsTable from './CarsTable';
import { useDispatch, useSelector } from 'react-redux';
import { getOwnerCars } from '../../../state/car/carSlice';
import { Translator } from '../../../i18n';
import dayjs from 'dayjs';

export default function CarsPage() {
    const dispatch = useDispatch();
    const [searchFilters, setSearchFilters] = useState({
        text: '',
        status: [],
        seats: [],
    });
    const deleteCarStatus = useSelector((state) => state.car.deleteCarStatus);
    const updateCarStatus = useSelector((state) => state.car.updateCarStatus);
    const stateCars = useSelector((state) => state.car.userCars).map((car, index) => {
        return {
            key: car.id,
            car: { index, image: car.images.length ? car.images[0].path : null, name: car.type?.name + car.model?.name + ' ' + car.production_year },
            status: { name: car.status?.name, id: car.id, index },
            visibility: car.is_visible,
            address: car.address?.meeting_address,
            type: car.type?.name,
            model: car.model?.name,
            energy: car.energy_source?.name,
            seats: car.seats_number?.number,
            updated_at: dayjs(car.updated_at).format('DD-MM-YYYY'),
        };
    });
    const cars = useMemo(() => 
        stateCars
        ? stateCars.filter((item) => 
            (searchFilters.text === '' || item.car.name?.toLowerCase().includes(searchFilters.text?.toLowerCase())) &&
            (searchFilters.status.length === 0 || searchFilters.status?.some(element => item.status.name === element)) &&
            (!searchFilters.seats || Number(item.seats) >= Number(searchFilters.seats)))
        : stateCars
    ,[stateCars, searchFilters]);

    useEffect(() => {
        deleteCarStatus !== 'pending' && updateCarStatus !== 'pending' && dispatch(getOwnerCars());
    }, [deleteCarStatus, updateCarStatus]);

    return (
        <div className='FirstPage'>
            <h1><Translator text="  " /></h1>
            <h2>{ cars.length } <Translator text="cars" /></h2>
            <CarsTable cars={ cars } setSearchFilters={ setSearchFilters } />
        </div>
    );
}