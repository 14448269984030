/* eslint-disable no-unreachable */
import React from "react";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Translator } from "../../i18n";
import { EnvironmentTwoTone } from "@ant-design/icons";
import { useSelector } from "react-redux";

const ImagesCarousel = ({ items }) => {
    return (
        <Swiper
          modules={ [Navigation] }
          slidesPerView={ 1 }
          navigation={ true }
        >
            { Array.isArray(items) && items.map((value, index) => (
                <SwiperSlide key={ index } className="Item">
                    <img src={ value.path } />
                </SwiperSlide>
            )) }
        </Swiper>
    )
};

export default function CarCard({
    images,
    type,
    address,
    model,
    production_year,
    price,
    seats_number,
    energy_source,
    gear_box,
    isMap = false,
}) {
    // key: car.id,
    // car: { index, image: car.images.length ? car.images[0].path : null, name: car.type?.name + car.model?.name + ' ' + car.production_year },
    // status: { name: car.status?.name, id: car.id },
    // visibility: car.is_visible,
    // address: car.address,
    // type: car.type?.name,
    // model: car.model?.name,
    // energy: car.energy_source?.name,
    // seats: car.seats_number?.number,
    const isMobile = useSelector((state) => state.locale.isMobile);

    return (
        <div className="Card" data-is-map={ isMap }>
            <div className="Image" style={ !isMobile && isMap ? { width: '40%', maxHeight: 228 } : {} }>
                <ImagesCarousel items={ images }/>
            </div>
            <div className="Details" style={ isMobile ? { width: '100%' } : {} }>
                <div className="General">
                    <div className="Info" style={ isMap ? { gap: 8 } : {} }>
                        <div className="Name">{ type?.name + model?.name + ' ' + production_year }</div>
                        <div className="Location">
                            <EnvironmentTwoTone twoToneColor="#B88923" className="LocationIcon"/>
                            { address?.meeting_address }
                        </div>
                        { isMap && <div className="MapPrice">{ price } <Translator text="DZD/Night"/></div> }
                        { !isMap && isMobile && <div className="Price">{ price } <Translator text="DZD/Night"/></div> }
                        { isMap && (
                            <div className="Hotel-Benefits">
                                <div className="Hotel-Beds">
                                    <div>{ seats_number?.number }</div>
                                    <div><Translator text="Seats" /></div>
                                </div>
                                { energy_source && (
                                    <div className="Hotel-Baths">
                                        <div>{ energy_source?.name }</div>
                                        <div><Translator text="Energy" /></div>
                                    </div>
                                ) }
                                <div className="Hotel-Rooms">
                                    <div>{ gear_box?.name }</div>
                                    <div><Translator text="Gear" /></div>
                                </div>
                            </div>
                        ) }
                    </div>
                    { !isMap && !isMobile && <div className="Price">{ price } <Translator text="DZD/Night"/></div> }
                </div>
            </div>
        </div>
    );
}