// src/components/GoogleSignInButton.js
import React from 'react';
import { Translator } from '../i18n';
import { useSelector } from 'react-redux';

export default function GoogleSignInButton ({ href = '#' }) {
    const language = useSelector((state) => state.locale.language);

    const handleGoogleSignIn = () => {
        window.location.href = href;
    };

    return (
        <button
          onClick={ handleGoogleSignIn }
          data-is-reverse={ language === 'ar' }
          className='SignInGoogle'
        >
            <img
              src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
              alt="Google Icon"
              width={ 25 }
              height={ 25 }
            />
            <div><Translator text='Sign in with Google' /></div>
        </button>
    );
};