/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { DeleteOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { Modal, Upload } from 'antd';
import { Translator } from "../i18n";
import { useDispatch, useSelector } from "react-redux";
import { setImages, uploadImages } from "../state/car/carSlice";
import { setImages as setOfferImage, switchImages, uploadImages as uploadOfferImages } from "../state/offer/offerSlice";
import fireCustomEvent from "../util/fireCustomEvent";
import { Button } from "antd";

export default function ImagesUpload({ finish = () => {}, isOffer = false }) {
    const dispatch = useDispatch();
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const offer = useSelector((state) => isOffer ? state.offer.offer : state.car.car);
    const uploadedImages = useSelector((state) => isOffer ? state.offer.offer.images : state.car.car.images);
    const imagesError = (useSelector((state) => isOffer ? state.offer.inputsErrors?.images : state.car.inputsErrors?.images) || [])[0];
    const switchStatus = useSelector((state) => isOffer ? state.offer.switchStatus : state.car.switchStatus);
    const uploadStatus = useSelector((state) => isOffer ? state.offer.uploadStatus : state.car.uploadStatus);
    const [draggedImage, setDraggedImage] = useState(null);
    const [draggedImagePosition, setDraggedImagePosition] = useState({ x: 0, y: 0 });

    const handleMouseDown = (id) => {
        if (id >= 0) {
            setDraggedImage(id);
        }
    };

    const handleMouseMove = (e) => {
        if (draggedImage) {
            setDraggedImagePosition({ x: e.pageX, y: e.pageY });
        }
    };

    const handleMouseUp = (id) => {
        if (id >= 0 && draggedImage && id !== draggedImage) {
            dispatch(switchImages({ ids: [draggedImage, id] }));
        }

        setDraggedImage(null);
    };

    const handlePreview = async (url) => {
        setPreviewImage(url);
        setPreviewVisible(true);
    };

    const handleCancel = () => {
        setPreviewVisible(false);
    };
    
    const handleChange = () => {
        return async (file) => {
            dispatch(isOffer ? uploadOfferImages({ selectedImages: [file] }) : uploadImages({ selectedImages: [file] }));
            return false;
        };
    };

    const handleSave = (e) => {
        e.preventDefault();

        if (uploadedImages.length >= 3 && uploadedImages.length < 15) {
            dispatch(isOffer ? setOfferImage([...uploadedImages].filter(Boolean)) : setImages([...uploadedImages].filter(Boolean)));
            finish();
        } else {
            fireCustomEvent('showNotification', 'warning', 'in order to proceed, you have to provide minimum of 3 images,\nand maximum of 14 images.');
        }
    };

    const dummyRequest = ({ onSuccess }) => {
        setTimeout(() => {
          onSuccess("ok");
        }, 0);
    };

    return (
        <>
            <div
              className="UploadCarImagesContainer"
              onMouseMove={ handleMouseMove }
              onMouseLeave={ () => setDraggedImage(null) }
              onMouseEnter={ () => setDraggedImage(null) }
            >
                { Array.from({ length: 14 }, (_, index) => (
                    <Upload
                      customRequest={ dummyRequest }
                      listType="picture-card"
                      beforeUpload={ handleChange() }
                      onPreview={ handlePreview }
                      rootClassName="UploadCarImages"
                      showUploadList={ false }
                      key={ index }
                      disabled={ switchStatus === 'pending' || uploadStatus === 'pending' }
                    >
                        { uploadedImages?.length > 0 && uploadedImages[index]
                            ? (
                                <div
                                  className="custom-upload"
                                  onMouseDown={ () => handleMouseDown(uploadedImages[index]?.id) }
                                  onMouseUp={ () => handleMouseUp(uploadedImages[index]?.id) }
                                >
                                    <div className="custom-upload-overlay" onClick={ (e) => { e.preventDefault();e.stopPropagation();} } />
                                    <div className="custom-upload-preview" onClick={ (e) => { e.preventDefault();e.stopPropagation();handlePreview(uploadedImages[index]) } }>
                                        <EyeOutlined />
                                    </div>
                                    <div className="custom-upload-delete" onClick={ (e) => { e.preventDefault();e.stopPropagation(); } }>
                                        <DeleteOutlined />
                                    </div>
                                    <img
                                      src={ uploadedImages[index].path }
                                      alt="images"
                                      draggable={ false }
                                    />
                                </div>
                            )
                            : (
                                <div>
                                    <PlusOutlined />
                                    <div
                                      style={ {
                                            marginTop: 8,
                                        } }
                                    >
                                        <Translator text="Upload n." /> { index + 1 }
                                    </div>
                                </div>
                            )
                        }
                    </Upload>
                )) }
            </div>
            <Modal open={ previewVisible } title="Preview" footer={ null } onCancel={ handleCancel }>
                <img
                  alt="image-preview"
                  style={ {
                      width: '100%',
                  } }
                  src={ previewImage }
                />
            </Modal>
            <Button
              htmlType="button"
              onClick={ handleSave }
              className="SaveButton"
            >
                <Translator text="Next" />
            </Button>
            { (imagesError) &&
            <div className="SaveButton Error">
                <span style={ { maxWidth: '210px' } }><Translator text={ imagesError } /></span>
            </div> }
        </>
    );
};