import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hidePopup } from '../state/popup/popupSlice';
import OfferConfirmation from './OfferForm/OfferConfirmation';
import CarConfirmation from './CarForm/CarConfirmation';
import LoginPopup from './Authentication/LoginPopup';
import { CloseOutlined } from '@ant-design/icons';

export default function Popup() {
    const dispatch = useDispatch();
    const { isPopupVisible, dataIndex } = useSelector(state => state.popup);
    const ref = useRef(null);
    const dataMap = {
        0: <OfferConfirmation />,
        1: <CarConfirmation />,
        2: <LoginPopup />,
        3: <LoginPopup isRedirect={ false } />,
    };

    const closePopup = (e) => {
        if (e.target.getAttribute('name') === 'container') {
            dispatch(hidePopup());
        }
    }

    const handleEvent = (e) => { e.preventDefault(); e.stopPropagation(); }

    useEffect(() => {
        if (isPopupVisible && ref) {
            document.body.addEventListener('scroll', handleEvent, { passive: false });
            document.body.addEventListener('mousewheel', handleEvent, { passive: false });
            document.body.addEventListener('touchmove', handleEvent, { passive: false });

            return () => {
                document.body.removeEventListener('scroll', handleEvent, { passive: false });
                document.body.removeEventListener('mousewheel', handleEvent, { passive: false });
                document.body.removeEventListener('touchmove', handleEvent, { passive: false });
            }
        }
    }, [isPopupVisible, ref]);

    if (!isPopupVisible) {
        return null;
    }

    return (
        <div ref={ ref } name="container" className="Blurred" onClick={ closePopup }>
            <div className='Popup' style={ dataIndex >= 2 ? { padding: 0, borderRadius: 30, border: 'none', backgroundColor: 'transparent' } : {} }>
                { dataIndex < 2 && <button onClick={ () => dispatch(hidePopup()) }><CloseOutlined /></button> }
                { dataMap[dataIndex] }
            </div>
        </div>
    );
};