import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Translator } from "../../i18n";
import { hidePopup } from "../../state/popup/popupSlice";
import { requestOfferCreation, validateInputs } from "../../state/offer/offerSlice";
import fireCustomEvent from "../../util/fireCustomEvent";
import { Button } from "antd";

export default function OfferConfirmation() {
    const dispatch = useDispatch();
    const requestStatus = useSelector((state) => state.offer.requestStatus);
    const requestPendingStatus = useSelector((state) => state.offer.requestPendingStatus);
    const inputsErrors = useSelector((state) => state.offer.inputsErrors);
    const isValidated = useSelector((state) => state.offer.isValidated);
    const uploadStatus = useSelector((state) => state.offer.uploadStatus);
    const offerCreationError = useSelector((state) => state.offer.error?.message);

    const handleCreate = (e) => {
        e.preventDefault();
        dispatch(validateInputs());
    };

    const handleClose = (e) => {
        e.preventDefault();
        dispatch(hidePopup());
    };

    useEffect(() => {
        if (isValidated && !requestPendingStatus && Object.values(inputsErrors || {}).filter((value) => value !== null).length === 0) {
            dispatch(requestOfferCreation({ withPending: true }));
        } else if (Object.values(inputsErrors || {}).filter((value) => value !== null).length > 0 && !isValidated) {
            fireCustomEvent('showNotification', 'error', 'Please fix all the errors.');
        } else if (requestStatus === 'error') {
            fireCustomEvent('showNotification', 'error', offerCreationError);
        }
    }, [requestStatus, requestPendingStatus, uploadStatus, isValidated, inputsErrors]);

    return (
        <div className="CarouselStepWrapper">
            <div className="CarouselStep" style={ { display: 'flex', flexWrap: 'wrap' } }>
                <div className="StepHeader" style={ { width: '100%' } }>
                    <Translator text="Do you want to proceed with creating the offer ?" />
                </div>
                <Button
                  htmlType="button"
                  onClick={ handleCreate }
                  loading={ requestStatus === 'pending' || uploadStatus === 'pending' || requestPendingStatus === 'pending' }
                >
                    <Translator text="Yes, i want to Proceed." />
                </Button>
                <Button
                  htmlType="button"
                  onClick={ handleClose }
                  style={ { marginInlineStart: 'auto' } }
                  loading={ requestStatus === 'pending' || uploadStatus === 'pending' || requestPendingStatus === 'pending' }
                >
                    <Translator text="No, i'm not done yet." />
                </Button>
            </div>
        </div>
    );
}