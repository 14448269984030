import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.scss';
import 'leaflet/dist/leaflet.css';
import 'leaflet-geosearch/dist/geosearch.css';
import App from './App';
import { Provider } from "react-redux";
import store from './state/store';
import { DevSupport } from "@react-buddy/ide-toolbox";
import { ComponentPreviews, useInitial } from "./dev";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={ store }>
        <DevSupport
          ComponentPreviews={ ComponentPreviews }
          useInitialHook={ useInitial }
        >
            <App/>
        </DevSupport>
    </Provider>
);
