import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import authReducer, { getCSRF } from "./auth/authSlice";
import localeReducer from "./locale/localeSlice";
import offerReducer from "./offer/offerSlice";
import carReducer from "./car/carSlice";
import { updateIsMobile } from "../thunks/isMobileThunks";
import popupReducer from "./popup/popupSlice";

const store = configureStore({
    reducer: {
        auth: authReducer,
        locale: localeReducer,
        offer: offerReducer,
        car: carReducer,
        popup: popupReducer
    },
    middleware: [
        ...getDefaultMiddleware({
            serializableCheck: false
        }),
    ],
});

store.dispatch(updateIsMobile());
store.dispatch(getCSRF());

export default store;
