/* eslint-disable react/no-unknown-property */
import { FileDoneOutlined, ReadOutlined, TeamOutlined } from '@ant-design/icons';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import HouseCard from '../../Exclusives/HouseCard';
import HotelImage1 from '../../../assets/hotel1.jpg';
import HotelImage2 from '../../../assets/hotel2origin.jpg';
import HotelImage3 from '../../../assets/hotel3.jpg';
import HotelImage4 from '../../../assets/hotel4.jpg';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Select } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/scss';
import InfoValidation from '../../OfferForm/InfoValidation';

const hotels = [
    {
        price: '29 000',
        location: 'Cherchel - Tipasa',
        beds: 4,
        rooms: 4,
        image: HotelImage1,
    },
    {
        price: '7 000',
        location: 'Larbaatache - Boumerdes',
        beds: 3,
        rooms: 2,
        image: HotelImage2,
    },
    {
        price: '5 500',
        location: 'Borj El Bahri - Alger',
        beds: 2,
        rooms: 3,
        image: HotelImage3,
    },
    {
        price: '8 000',
        location: 'Ouled Moussa - Boumerdes',
        beds: 6,
        rooms: 3,
        image: HotelImage4,
    }
];

export default function FirstPage() {
    const { t } = useTranslation();
    const isMobile = useSelector((state) => state.locale.isMobile);
    const firstname = useSelector((state) => state.auth.user?.first_name) + ', ';
    const [slideView, setSlideView] = useState((window.innerWidth - 40) / 280 < 1 ? 1 : (window.innerWidth - 40) / 280);
    const componentRef = useRef(null);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            if (isMobile) {
                setSlideView((window.innerWidth - 40) / 280 < 1 ? 1 : (window.innerWidth - 40) / 280);
            }
        });

        const componentElement = componentRef.current;
        resizeObserver.observe(componentElement);

        return () => {
            resizeObserver.unobserve(componentElement);
        };
    }, []);

    return (
        <div className='FirstPage' ref={ componentRef }>
            <h1 style={ { textTransform: 'capitalize' } }>{ firstname + t("we are delighted to see you again") } !</h1>
            <InfoValidation />
            <div className='Reservation'>
                <h2>{ t("Your reservations") }</h2>
                <Link to='/' style={ { textDecoration: 'underline', fontWeight: 600 } }>{ t("All reservations") } (0)</Link>
            </div>
            <div className='Tags'>
                { isMobile ? (
                    <Select
                      defaultValue={ 0 }
                      options={ [
                        { value: 0, label: t('All') + " (0)" },
                        { value: 1, label: t('Next departures') + " (0)" },
                        { value: 2, label: t('Current stays') + " (0)" },
                        { value: 3, label: t('Upcoming arrivals') + " (0)" },
                        { value: 4, label: t('To come') + " (0)" },
                        { value: 5, label: t('Waiting for comment') + " (0)" },
                        ] }
                    />
                ) : (<>
                    <div className='Tag'>{ t('Next departures') } (0)</div>
                    <div className='Tag'>{ t('Current stays') } (0)</div>
                    <div className='Tag'>{ t('Upcoming arrivals') } (0)</div>
                    <div className='Tag'>{ t('To come') } (0)</div>
                    <div className='Tag'>{ t('Waiting for comment') } (0)</div>
                </>) }
            </div>
        
            <div className='GreyArea'>
                <FileDoneOutlined />
                <p>{ t("No Reservations for the moment") }</p>
            </div>
        
            <h2>{ t("We are here to help you") }</h2>
            <div className='Cards'>
                <div className='Card'>
                    <TeamOutlined />
                    <div className='Text'>
                        <h3>{ t("Join my local host club") }</h3>
                        <p>{ t("Exchange and collaborate with hosts and others near you.") }</p>
                    </div>
                </div>
                <div className='Card'>
                    <ReadOutlined />
                    <div className='Text'>
                        <h3>{ t("Visit the Help Center") }</h3>
                        <p>{ t("Get answers to your questions about your listing, payments, feedback and more.") }</p>
                    </div>
                </div>
            </div>
            <div className='Conseils'>
                <h2>{ t("Resources and Tips") }</h2>
                <div className="Cards">
                    { isMobile ? (
                        <Swiper
                          modules={ [ Pagination ] }
                          spaceBetween={ 10 }
                          slidesPerView={ slideView }
                        >
                            { hotels.map((hotel, index) => (
                                <SwiperSlide key={ index }>
                                    <HouseCard { ...hotel } />
                                </SwiperSlide>
                            )) }
                        </Swiper>
                    ) : (
                        hotels.map((hotel, index) => <HouseCard key={ index } { ...hotel } />)
                    ) }
                </div>
            </div>
        </div>
    );
}