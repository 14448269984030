import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import React, { forwardRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { removeUploadedImage } from '../../state/offer/offerSlice';
import { Modal } from 'antd';

const Item = ({ path, withChildren, children, withOpacity, isDragging, style, ...props }, ref) => {
    const dispatch = useDispatch();
    const [isHovered, setIsHovered] = useState(false);
    const [previewVisible, setPreviewVisible] = useState(false);
    const inlineStyles = {
        opacity: withOpacity ? '0.5' : '1',
        transformOrigin: '-100% -100%',
        height: '140px',
        width: '140px',
        borderRadius: '10px',
        cursor: isDragging ? 'grabbing' : 'grab',
        backgroundColor: '#ffffff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: isDragging  ? 'rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px' : 'rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px',
        transform: isDragging ? 'scale(1.05)' : 'scale(1)',
        position: 'relative',
        ...style,
    };

    const handleDelete = () => {
        dispatch(removeUploadedImage({ id: props.id }));
    };

    const handlePreview = (val) => {
        setPreviewVisible(val);
    };

    return (
        <div ref={ ref } style={ inlineStyles } { ...(!isHovered && !previewVisible ? props : {}) }>
            { withChildren ? children : (
                <>
                    <img src={ path } alt="Item" />
                    <div className="custom-upload-preview" onClick={ (e) => { e.preventDefault();e.stopPropagation();handlePreview(true); } } onMouseEnter={ () => setIsHovered(true) } onMouseLeave={ () => setIsHovered(false) }>
                        <EyeOutlined />
                    </div>
                    <div className="custom-upload-delete" onClick={ (e) => { e.preventDefault();e.stopPropagation();handleDelete(); } } onMouseEnter={ () => setIsHovered(true) } onMouseLeave={ () => setIsHovered(false) }>
                        <DeleteOutlined />
                    </div>
                </>
            ) }
            <Modal open={ previewVisible } title="Preview" footer={ null } onCancel={ () => handlePreview(false) }>
                <img
                  alt="image-preview"
                  style={ {
                      width: '100%',
                  } }
                  src={ path }
                />
            </Modal>
        </div>
    );
};

export default forwardRef(Item);
